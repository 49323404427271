import { TournamentModel } from '@models/tournament'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type TournamentsState = {
  isLoading: boolean
  error: Error | null
  tournament: TournamentModel | null
}
const initialState: TournamentsState = {
  isLoading: false,
  error: null,
  tournament: null,
}

const tournamentsState = createSlice({
  name: 'ladders',
  initialState,
  reducers: {
    joinTournament: (state, _action: PayloadAction<{ tournamentId: string; teamId: string }>) => {
      state.isLoading = true
    },
    joinTournamentSuccess: (state) => {
      state.isLoading = false
    },
    joinTournamentFailed: (state, action) => {
      state.isLoading = false
      state.error = action.payload
    },
  },
})

export const { joinTournament, joinTournamentSuccess, joinTournamentFailed } = tournamentsState.actions

export default tournamentsState.reducer

export const tournamentsActions = tournamentsState.actions
