// import authRoles from 'configs/authRoles'
import { lazy } from 'react'
import { Navigate } from 'react-router-dom'

import { LadderRankings } from './LadderRankings/LadderRankings'

const LaddersPageLayout = lazy(() => import('./LaddersPageLayout'))

// FALLBACK TO DOCUMENTATION PAGES ON WEBAPP
const LaddersConfig = {
  settings: {
    layout: {
      config: {},
    },
  },

  routes: [
    {
      path: 'ladders',
      auth: null,
      element: <div>All ladders</div>,
    },
    {
      path: 'ladders',
      auth: null,
      children: [
        {
          path: ':gameId',
          auth: null,
          index: true,
          element: <div>All Game ladders</div>,
        },
      ],
    },
    {
      path: 'ladder',
      auth: null,
      element: <LaddersPageLayout />,
      children: [
        {
          path: ':ladderId',
          auth: null,
          children: [
            {
              index: true,
              element: <LadderRankings />,
            },
            {
              path: 'rankings',
              auth: null,
              element: <LadderRankings />,
            },
            {
              path: 'matches',
              auth: null,
              element: <div>Matches</div>,
            },
            {
              path: 'hibernated',
              auth: null,
              element: <div>teams hibernated</div>,
            },
            {
              path: 'rules',
              auth: null,
              element: <div>Rules</div>,
            },
            {
              path: '*',
              auth: null,
              element: <Navigate to="" relative="route" />,
            },
          ],
        },
      ],
    },
  ],
}

export default LaddersConfig
