import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useTimeout } from '@reddal/hooks'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { useState } from 'react'

function ReddalLoading(props: { delay: unknown }) {
  const [showLoading, setShowLoading] = useState(!props.delay)

  useTimeout(() => {
    setShowLoading(true)
  }, props.delay)

  return (
    <div className={clsx('flex w-full flex-1 flex-col items-center justify-center p-24', !showLoading && 'hidden')}>
      <Typography className="-mb-16 text-13 font-medium sm:text-20" color="text.secondary">
        Loading
      </Typography>
      <Box
        id="spinner"
        sx={{
          '& > div': {
            backgroundColor: 'palette.secondary.main',
          },
        }}
      >
        {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
        <div className="bounce1" />
        {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
        <div className="bounce2" />
        {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
        <div className="bounce3" />
      </Box>
    </div>
  )
}

ReddalLoading.propTypes = {
  delay: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
}

ReddalLoading.defaultProps = {
  delay: false,
}

export default ReddalLoading
