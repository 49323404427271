import { useUser } from '@hooks/useUser'
import { Navigate, useParams } from 'react-router-dom'

export const ProfilePermission = ({ children }: PropsWithChildren) => {
  const { playerId } = useParams()
  const { user } = useUser()
  if (user?.refId === playerId) {
    return children
  }
  return <Navigate to=".." />
}
