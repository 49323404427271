// eslint-disable-next-line max-classes-per-file,@typescript-eslint/ban-ts-comment
// @ts-nocheck
/* tslint:disable */
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { apiClient } from 'services/api'

export const getChats = createAsyncThunk('chatPanel/chats/getChats', async (params) => {
  const response = await apiClient.get('/api/chat/chats', { params })
  const data = await response.data

  return data
})

const chatsAdapter = createEntityAdapter({})

export const { selectAll: selectChats, selectById: selectChatById } = chatsAdapter.getSelectors(
  (state) => state.chatPanel.chats,
)

const chatsSlice = createSlice({
  name: 'chatPanel/chats',
  initialState: chatsAdapter.getInitialState(),
  extraReducers: {
    [getChats.fulfilled]: chatsAdapter.setAll,
  },
})

export default chatsSlice.reducer
