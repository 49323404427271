import { history } from '@store'
import { ReactNode, useLayoutEffect, useState } from 'react'
import { Router } from 'react-router-dom'

interface BrowserRouterProps {
  basename?: string
  children: ReactNode
}
function BrowserRouter({ basename, children }: BrowserRouterProps) {
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  })

  useLayoutEffect(() => history.listen(setState), [])

  return (
    <Router basename={basename} location={state.location} navigationType={state.action} navigator={history}>
      {children}
    </Router>
  )
}

export default BrowserRouter
