import { SvgIcon, SvgIconProps } from '@mui/material'
import { styled, SxProps, Theme } from '@mui/material/styles'
import clsx from 'clsx'
import { forwardRef, MouseEventHandler, useMemo } from 'react'

import IconList, { IconName } from './iconList'

interface IconProps {
  name: IconName
  sx?: SxProps<Theme>
  className?: string
  size?: number
  color?: 'inherit' | 'disabled' | 'primary' | 'secondary' | 'action' | 'error' | 'info' | 'success' | 'warning'
  onClick?: (() => void) | MouseEventHandler
}
const ReddalIcon = forwardRef(({ size, className, sx, color = 'inherit', name, onClick }: IconProps, ref) => {
  const Root = useMemo(() => {
    const IconComp = IconList[name as IconName]
    if (!IconComp) return styled('div')<FixMe>({})
    const MySvgIcon = (props: SvgIconProps) => {
      return (
        <SvgIcon inheritViewBox {...props}>
          <IconComp />
        </SvgIcon>
      )
    }
    return styled(MySvgIcon)<SvgIconProps>(({ theme }) => ({
      // width: size,
      // height: size,
      // minWidth: size,
      // minHeight: size,
      fontSize: size,
      // lineHeight: size,
      // sx: { ...sx, width: size, height: size, minWidth: size, minHeight: size, fontSize: size, lineHeight: size },
      color: {
        primary: theme.palette.primary.main,
        secondary: theme.palette.secondary.main,
        info: theme.palette.info.main,
        success: theme.palette.success.main,
        warning: theme.palette.warning.main,
        action: theme.palette.action.active,
        error: theme.palette.error.main,
        disabled: theme.palette.action.disabled,
        inherit: undefined,
      }[color],
    }))
  }, [ref, size, sx, color, name])

  return <Root className={clsx('', className)} sx={sx} color={color} onClick={onClick} />
})

export default ReddalIcon
