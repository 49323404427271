export const UploadEntity = {
  cups: 'cups',
  games: 'games',
  ladders: 'ladders',
  news: 'news',
  teams: 'teams',
  users: 'users',
}

export type UploadEntityType = keyof typeof UploadEntity

export const UploadType = {
  avatar: 'avatar',
  banner: 'banner',
  cover: 'cover',
  logo: 'logo',
}
export type UploadTypeTYpe = keyof typeof UploadType
