import { lazy } from 'react'
import { Navigate } from 'react-router-dom'

const GamesList = lazy(() => import('./games-list/GamesList'))
const AddGame = lazy(() => import('./add-game/AddGame'))
const GameEdit = lazy(() => import('./GameEdit'))

const GamesRoutes = [
  {
    path: 'games',
    children: [
      {
        index: true,
        element: <GamesList />,
      },
      {
        path: 'add-game',
        element: <AddGame />,
      },
      {
        path: 'edit/:id',
        element: <GameEdit />,
      },
      {
        path: '*',
        element: <Navigate to="" />,
      },
    ],
  },
]

export default GamesRoutes
