// material-ui
import { MainCard, RankingTable } from '@components'
import { useLadder } from '@hooks/useLadder'
import { useParams } from 'react-router-dom'

export const LadderRankings = () => {
  const { ladderId } = useParams()
  const { ladder } = useLadder(ladderId)

  if (!ladder) return <div>No Ladder Content</div>
  return (
    <MainCard content={false} sx={{ width: '100%' }}>
      <RankingTable ladder={ladder} />
    </MainCard>
  )
}
