// eslint-disable-next-line max-classes-per-file,@typescript-eslint/ban-ts-comment
// @ts-nocheck
/* tslint:disable */
import { styled } from '@mui/material/styles'
import ReddalScrollbars from '@reddal/core/ReddalScrollbars'
import clsx from 'clsx'
import { memo } from 'react'

import Logo from '../../shared-components/Logo'
import Navigation from '../../shared-components/Navigation'

const Root = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.primary,
}))

function NavbarLayout2(props) {
  return (
    <Root className={clsx('h-64 max-h-64 min-h-64 w-full shadow-md', props.className)}>
      <div className="container z-20 flex h-full w-full flex-auto items-center justify-between p-0 lg:px-24">
        <div className="flex shrink-0 items-center px-8">
          <Logo />
        </div>

        <ReddalScrollbars className="flex h-full items-center">
          <Navigation className="w-full" layout="horizontal" />
        </ReddalScrollbars>
      </div>
    </Root>
  )
}

export default memo(NavbarLayout2)
