import { GameModel } from '@models/game'
import { apiClient } from 'services/api'

export async function getAllGames(): Promise<GameModel[]> {
  return apiClient
    .get('/games')
    .then((response) => {
      if (response) {
        return response.data
      }
      throw new Error('Error while fetching games')
    })
    .catch((err) => {
      throw err
    })
}
export async function addGame(game: Partial<GameModel>) {
  return apiClient
    .post('/game', game)
    .then((response) => {
      if (response) {
        return response.data
      }
      throw new Error('Error while adding game')
    })
    .catch((err) => {
      throw err
    })
}
export async function deleteGame(gameId: string) {
  return apiClient
    .delete(`/game/${gameId}`)
    .then((response) => {
      if (response) {
        return response.data
      }
      throw new Error('No response data')
    })
    .catch((err) => {
      throw err
    })
}
