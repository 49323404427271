export enum NotificationType {
  CHALLENGE_COMPLETED = 'CHALLENGE-COMPLETED',
  CHALLENGE_UPDATED = 'CHALLENGE-UPDATED',
  GENERIC = 'GENERIC',
  MATCH_CHALLENGE = 'MATCH-CHALLENGE',
  MATCH_DONE = 'MATCH-DONE',
  MATCH_UPDATED = 'MATCH-UPDATED',
  MATCH_RESET = 'MATCH-RESET',
  NEW_MESSAGE = 'NEW-MESSAGE',
  TEAM_INVITE = 'TEAM-INVITE',
  TEAM_INVITE_ACCEPT = 'TEAM-INVITE-ACCEPT',
  TEAM_INVITE_DECLINE = 'TEAM-INVITE-DECLINE',
}

export enum NotificationStatus {
  NEW = 'NEW',
  READ = 'READ',
}

export interface NotificationModel {
  _id: string
  refId: string
  user: string
  from?: string
  message: string
  status: NotificationStatus
  createdAt: FixMe
  updatedAt?: FixMe
  type: NotificationType
  socketType: string
  relatedId: string
  parentId?: string
}

export enum NotificationAction {
  ACCEPT = 'ACCEPT',
  DECLINE = 'DECLINE',
  DELETE = 'DELETE',
  READ = 'READ',
}

export function notificationTitleByType(type: NotificationType) {
  switch (type) {
    case NotificationType.CHALLENGE_COMPLETED:
      return 'Challenge completed'
    case NotificationType.CHALLENGE_UPDATED:
      return 'Challenge updated'
    case NotificationType.MATCH_CHALLENGE:
      return 'Match challenge'
    case NotificationType.MATCH_DONE:
      return 'Match done'
    case NotificationType.MATCH_UPDATED:
      return 'Match updated'
    case NotificationType.MATCH_RESET:
      return 'Match reset'
    case NotificationType.NEW_MESSAGE:
      return 'New message'
    case NotificationType.TEAM_INVITE:
      return 'Team invite'
    case NotificationType.TEAM_INVITE_ACCEPT:
      return 'Team invite accepted'
    case NotificationType.TEAM_INVITE_DECLINE:
      return 'Team invite declined'
    default:
      return 'Notification'
  }
}

export function notificationShouldHaveButtons(type: NotificationType) {
  switch (type) {
    case NotificationType.MATCH_CHALLENGE:
    case NotificationType.TEAM_INVITE:
      return true
    default:
      return false
  }
}

export function notificationColorByType(type: NotificationType) {
  switch (type) {
    case NotificationType.CHALLENGE_COMPLETED:
    case NotificationType.MATCH_DONE:
    case NotificationType.MATCH_UPDATED:
    case NotificationType.MATCH_RESET:
    case NotificationType.TEAM_INVITE_ACCEPT:
      return 'green-400'

    case NotificationType.MATCH_CHALLENGE:
    case NotificationType.TEAM_INVITE:
      return 'orange-600'

    case NotificationType.TEAM_INVITE_DECLINE:
      return 'red'

    case NotificationType.NEW_MESSAGE:
    default:
      return 'white'
  }
}
