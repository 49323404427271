import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit'
import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { persistReducer, persistStore } from 'redux-persist'
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist/es/constants'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import storage from 'redux-persist/lib/storage'
import createSagaMiddleware from 'redux-saga'

import loggerMiddleware from './middlewares'
import createRootReducer from './reducers'
import sagas from './sagas'

let rehydrationComplete: FixMe
// let rehydrationFailed

const rehydrationPromise = new Promise((resolve) => {
  rehydrationComplete = resolve
  // rehydrationFailed = reject
})
export function rehydration() {
  return rehydrationPromise
}
const history = createBrowserHistory()
const sagaMiddleware = createSagaMiddleware()
const routeMiddleware = routerMiddleware(history)
const loggers = import.meta.env.MODE !== 'production' ? [loggerMiddleware] : []
// const loggers = import.meta.env.MODE !== 'production' ? [loggerMiddleware, logger] : []
// const loggers = [loggerMiddleware, logger]
const middlewares = [sagaMiddleware, routeMiddleware, ...loggers]

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['reddal', 'challenges'],
  stateReconciler: autoMergeLevel2, // see "Merge Process" section for details.
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const resetEnhancer = (rootReducer) => (state, action) => {
  if (action.type !== 'LOGOUT_SUCCESS') return rootReducer(state, action)

  const newState = rootReducer(undefined, {})
  newState.router = state.router
  // newState.menu = state.menu
  // newState.settings = state.settings
  // newState.teams = state.teams
  // newState.player = state.player
  return newState
}

const persistedReducer = persistReducer(persistConfig, resetEnhancer(createRootReducer(history)))

// const preloadedState = {
//   todos: [
//     {
//       text: 'Eat food',
//       completed: true,
//     },
//     {
//       text: 'Exercise',
//       completed: false,
//     },
//   ],
//   visibilityFilter: 'SHOW_COMPLETED',
// }
const preloadedState = {}
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        ignoredActionPaths: ['payload.banner', 'payload.logo'],
      },
    }).concat(middlewares),
  devTools: import.meta.env.VITE_APP_REDUX_DEVTOOLS,
  preloadedState,
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>

// then run the saga
sagaMiddleware.run(sagas)
const persistor = persistStore(store, null, () => {
  // this will be invoked after rehydration is complete
  rehydrationComplete()
})
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
store.asyncReducers = {}

export const injectReducer = (key: string | number, reducer: FixMe) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (store.asyncReducers[key]) {
    return false
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  store.asyncReducers[key] = reducer
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  store.replaceReducer(persistReducer(persistConfig, createRootReducer(history, store.asyncReducers)))

  return store
}

export { history, persistor, store }
