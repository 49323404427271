import ReddalLoading from '@reddal/core/ReddalLoading'
import ErrorBoundary from 'layouts/shared-components/ErrorBoundary'
import PropTypes from 'prop-types'
import { Suspense } from 'react'

/**
 * React Suspense defaults
 * For to Avoid Repetition
 */ function ReddalSuspense(props: FixMe) {
  return (
    <ErrorBoundary>
      <Suspense fallback={<ReddalLoading {...props.loadingProps} />}>{props.children}</Suspense>
    </ErrorBoundary>
  )
}

ReddalSuspense.propTypes = {
  loadingProps: PropTypes.object,
}

ReddalSuspense.defaultProps = {
  loadingProps: {
    delay: 0,
  },
}

export default ReddalSuspense
