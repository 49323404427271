import { createSlice } from '@reduxjs/toolkit'

interface MenuState {
  menu: FixMe[]
  isLoading: boolean
  error: FixMe
}
const initialState: MenuState = {
  isLoading: false,
  menu: [],
  error: null,
}

const menuState = createSlice({
  name: 'menuState',
  initialState,
  reducers: {
    fetchMenu: (state) => {
      state.isLoading = true
    },
    fetchMenuSuccess: (state, action) => {
      state.isLoading = false
      state.menu = action.payload
    },
    fetchMenuFailed: (state, action) => {
      state.isLoading = false
      state.menu = []
      state.error = action.payload
    },
  },
})

export const { fetchMenu, fetchMenuSuccess, fetchMenuFailed } = menuState.actions

export default menuState.reducer

export const menuActions = menuState.actions
