// eslint-disable-next-line max-classes-per-file,@typescript-eslint/ban-ts-comment
// @ts-nocheck
/* tslint:disable */
import IconButton from '@mui/material/IconButton'
import ReddalSvgIcon from '@reddal/core/ReddalSvgIcon'
import { useDispatch } from 'react-redux'

import { toggleQuickPanel } from './store/stateSlice'

function QuickPanelToggleButton(props) {
  const dispatch = useDispatch()

  return (
    <IconButton className="h-40 w-40" onClick={(ev) => dispatch(toggleQuickPanel())} size="large">
      {props.children}
    </IconButton>
  )
}

QuickPanelToggleButton.defaultProps = {
  children: <ReddalSvgIcon>heroicons-outline:bookmark</ReddalSvgIcon>,
}

export default QuickPanelToggleButton
