import { TeamModel } from '@models/team'
import { apiClient } from 'services/api'

export async function getTeam(refId: string): Promise<TeamModel> {
  return apiClient
    .get(`/team/${refId}`)
    .then((response) => {
      if (response) {
        return response.data
      }
      throw new Error('No response data')
    })
    .catch((err) => {
      throw err
    })
}

export async function userTeams() {
  return apiClient
    .get(`/userTeams`)
    .then((response) => {
      if (response) {
        return response.data
      }
      throw new Error('No response data')
    })
    .catch((err) => {
      throw err
    })
}

export async function playerTeams(refId: string) {
  return apiClient
    .get(`/playerTeams/${refId}`)
    .then((response) => {
      if (response) {
        return response.data
      }
      throw new Error('No response data')
    })
    .catch((err) => {
      throw err
    })
}

export async function createTeam(team: FixMe): Promise<TeamModel> {
  return apiClient
    .post(`/team`, team)
    .then((response) => {
      if (response) {
        return response.data
      }
      throw new Error('No response data')
    })
    .catch((err) => {
      throw err
    })
}

export async function editTeam(team: FixMe): Promise<TeamModel> {
  return apiClient
    .put(`/team/${team.refId}`, team)
    .then((response) => {
      if (response) {
        return response.data
      }
      throw new Error('No response data')
    })
    .catch((err) => {
      throw err
    })
}

export async function invitePlayers(players: { to: string[]; team: string }) {
  return apiClient
    .post(`/invite`, players)
    .then((response) => {
      if (response) {
        return response.data
      }
      throw new Error('No invite players data')
    })
    .catch((err) => {
      throw err
    })
}

export async function changeRole(change: FixMe) {
  return apiClient
    .put(`/profile/role/${change.profileRefId}`, { role: change.newRole })
    .then((response) => {
      if (response) {
        return response.data
      }
      throw new Error('No change role response data')
    })
    .catch((err) => {
      throw err
    })
}

export async function kickPlayer(refId: string) {
  return apiClient
    .delete(`/profile/remove/${refId}`, {})
    .then((response) => {
      if (response) {
        return response.data
      }
      throw new Error('No change role response data')
    })
    .catch((err) => {
      throw err
    })
}

export async function deleteTeam(refId: string) {
  return apiClient
    .delete(`/team/${refId}`)
    .then((response) => {
      if (response) {
        return response.data
      }
      throw new Error('No response data')
    })
    .catch((err) => {
      throw err
    })
}

export async function searchTeam(term: string) {
  return apiClient
    .get(`/search/team?query=${term}`)
    .then((response) => {
      if (response) {
        return response.data
      }
      throw new Error('No teams found')
    })
    .catch((err) => {
      throw err
    })
}
