import { Avatar } from '@components'
import { useUser } from '@hooks/useUser'
import { ProfileModel } from '@models/profile'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Menu, { MenuProps } from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { alpha, styled } from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'
import { setActiveProfile } from '@store/auth/authState'
import { useAppDispatch } from '@store/hooks'
import * as React from 'react'

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiAvatar-root': {
        width: 22,
        height: 22,
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}))

export const ProfilePicker = () => {
  const { user } = useUser()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const dispatch = useAppDispatch()

  const handleMenuItemClick = (profile: ProfileModel) => {
    dispatch(setActiveProfile(profile))
    setAnchorEl(null)
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  if (!user?.activeProfile) return null

  return (
    <div>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Active Profile">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar sx={{ width: 32, height: 32 }} src={user?.activeProfile?.team.logo} />
          </IconButton>
        </Tooltip>
      </Box>
      <StyledMenu
        id="active-profile-menu"
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem selected>
          <Avatar src={user?.activeProfile?.team.logo} size="badge" variant="rounded" />
          {user?.activeProfile?.team.name}
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />
        {user?.profiles?.map((profile: ProfileModel) => {
          if (profile.refId === user?.activeProfile?.refId) return null
          return (
            <MenuItem
              key={profile.refId}
              // disabled={index === 0}
              onClick={() => handleMenuItemClick(profile)}
            >
              <Avatar src={profile?.team.logo} size="badge" variant="rounded" />
              {profile.team.name}
            </MenuItem>
          )
        })}
      </StyledMenu>
    </div>
  )
}
