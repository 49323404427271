// eslint-disable-next-line max-classes-per-file,@typescript-eslint/ban-ts-comment
// @ts-nocheck
/* tslint:disable */
import { styled } from '@mui/material/styles'
import ReddalDialog from '@reddal/core/ReddalDialog'
import ReddalMessage from '@reddal/core/ReddalMessage'
import ReddalSuspense from '@reddal/core/ReddalSuspense'
import { selectReddalCurrentLayoutConfig } from '@store/reddal/settingsSlice'
import clsx from 'clsx'
import AppContext from 'context/AppContext'
import { memo, useContext } from 'react'
import { useSelector } from 'react-redux'
import { useRoutes } from 'react-router-dom'

import SettingsPanel from '../shared-components/SettingsPanel'
import FooterLayout2 from './components/FooterLayout2'
import LeftSideLayout2 from './components/LeftSideLayout2'
import NavbarWrapperLayout2 from './components/NavbarWrapperLayout2'
import RightSideLayout2 from './components/RightSideLayout2'
import ToolbarLayout2 from './components/ToolbarLayout2'

const Root = styled('div')(({ theme, config }) => ({
  ...(config.mode === 'boxed' && {
    clipPath: 'inset(0)',
    maxWidth: `${config.containerWidth}px`,
    margin: '0 auto',
    boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
  }),
  ...(config.mode === 'container' && {
    '& .container': {
      maxWidth: `${config.containerWidth}px`,
      width: '100%',
      margin: '0 auto',
    },
  }),
}))

function Layout2(props) {
  const config = useSelector(selectReddalCurrentLayoutConfig)
  const appContext = useContext(AppContext)
  const { routes } = appContext

  return (
    <Root id="reddal-layout" className="flex w-full" config={config}>
      {config.leftSidePanel.display && <LeftSideLayout2 />}

      <div className="flex min-w-0 flex-auto flex-col">
        <main id="reddal-main" className="relative flex min-h-full min-w-0 flex-auto flex-col">
          {config.navbar.display && (
            <NavbarWrapperLayout2 className={clsx(config.navbar.style === 'fixed' && 'sticky top-0 z-50')} />
          )}
          {config.toolbar.display && (
            <ToolbarLayout2
              className={clsx(
                config.toolbar.style === 'fixed' && 'sticky top-0',
                config.toolbar.position === 'above' && 'z-40 order-first',
              )}
            />
          )}
          <div className="sticky top-0 z-99">
            <SettingsPanel />
          </div>
          <div className="relative z-10 flex min-h-0 flex-auto flex-col">
            <ReddalDialog />

            <ReddalSuspense>{useRoutes(routes)}</ReddalSuspense>

            {props.children}
          </div>
          {config.footer.display && <FooterLayout2 className={config.footer.style === 'fixed' && 'sticky bottom-0'} />}
        </main>
      </div>

      {config.rightSidePanel.display && <RightSideLayout2 />}
      <ReddalMessage />
    </Root>
  )
}

export default memo(Layout2)
