import { menuActions } from '@store/platform/menu/menuState'
import { all, put, takeLatest } from 'redux-saga/effects'

function* GET_MENU(): FixMe {
  try {
    // const menu: FixMe = yield call(getMenu)
    // yield put(menuActions.fetchMenuSuccess(menu))
  } catch (e) {
    yield put(menuActions.fetchMenuFailed({ message: 'Failed to fetch games' }))
  }
}

export default function* menuSaga() {
  yield all([takeLatest(menuActions.fetchMenu.type, GET_MENU), GET_MENU()])
}
