import { UserModel } from '@models/user'
import { PayloadAction } from '@reduxjs/toolkit'
import { getAllUsers, getUser, updateUser } from '@services/api/administration/users'
import { adminUsersActions, GetUserPayload, UpdateUserPayload } from '@store/administration/users/usersState'
import { call, put, takeLatest } from 'redux-saga/effects'

function* GET_ALL_USERS(): FixMe {
  try {
    const users: UserModel[] = yield call(getAllUsers)
    yield put(adminUsersActions.fetchUsersSuccess(users))
  } catch (e) {
    yield put(adminUsersActions.fetchUsersFailed(new Error('Failed to fetch users')))
  }
}

function* GET_USER(action: PayloadAction<GetUserPayload>): FixMe {
  try {
    const { refId } = action.payload
    const user: UserModel = yield call(getUser, refId)
    yield put(adminUsersActions.fetchUserSuccess(user))
  } catch (e) {
    yield put(adminUsersActions.fetchUserFailed(new Error('Failed to fetch user')))
  }
}

function* UPDATE_USER(action: PayloadAction<UpdateUserPayload>): FixMe {
  try {
    const { refId } = action.payload
    const user: UserModel = yield call(updateUser, refId)
    yield put(adminUsersActions.updateUserSuccess(user))
  } catch (e) {
    yield put(adminUsersActions.updateUserFailed(new Error('Failed to update user')))
  }
}

export default function* usersSaga() {
  yield takeLatest(adminUsersActions.fetchUsers.type, GET_ALL_USERS)
  yield takeLatest(adminUsersActions.fetchUser.type, GET_USER)
  yield takeLatest(adminUsersActions.updateUser.type, UPDATE_USER)
}
