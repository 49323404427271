// eslint-disable-next-line max-classes-per-file,@typescript-eslint/ban-ts-comment
// @ts-nocheck
/* tslint:disable */
import { styled } from '@mui/material/styles'
import ReddalScrollbars from '@reddal/core/ReddalScrollbars'
import clsx from 'clsx'
import { memo } from 'react'

import Navigation from '../../shared-components/Navigation'

const Root = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.primary,
}))

function NavbarLayout3(props: FixMe) {
  return (
    <Root className={clsx('h-64 max-h-64 min-h-64 w-full shadow-md', props.className)}>
      <div className="container flex h-full w-full flex-auto items-center px-16 lg:px-24">
        <ReddalScrollbars className="flex h-full items-center">
          <Navigation className="w-full" layout="horizontal" dense />
        </ReddalScrollbars>
      </div>
    </Root>
  )
}

export default memo(NavbarLayout3)
