// eslint-disable-next-line max-classes-per-file,@typescript-eslint/ban-ts-comment
// @ts-nocheck
/* tslint:disable */
import ReddalNavigation from '@reddal/core/ReddalNavigation'
import useThemeMediaQuery from '@reddal/hooks/useThemeMediaQuery'
import { navbarCloseMobile } from '@store/reddal/navbarSlice'
import { selectNavigation } from '@store/reddal/navigationSlice'
import clsx from 'clsx'
import { memo, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

function Navigation(props) {
  const navigation = useSelector(selectNavigation)
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'))

  const dispatch = useDispatch()

  return useMemo(() => {
    function handleItemClick() {
      if (isMobile) {
        dispatch(navbarCloseMobile())
      }
    }

    return (
      <ReddalNavigation
        className={clsx('navigation', props.className)}
        navigation={navigation}
        layout={props.layout}
        dense={props.dense}
        active={props.active}
        onItemClick={handleItemClick}
      />
    )
  }, [dispatch, isMobile, navigation, props.active, props.className, props.dense, props.layout])
}

Navigation.defaultProps = {
  layout: 'vertical',
}

export default memo(Navigation)
