import { queryClient } from '@services/api'
import { login } from '@services/api/auth'
import { useQuery } from '@tanstack/react-query'
import { isAxiosError } from 'axios'
import firebaseConfig from 'configs/firebase'
import { getAnalytics } from 'firebase/analytics'
import { getApp, getApps, initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { useEffect } from 'react'

const app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApp()
export const analytics = getAnalytics(app)
export const auth = getAuth(app)
export const useUser = () => {
  const {
    data: user,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: ['user'],
    queryFn: login,
    // Only fetch if there is a token
    enabled: !!localStorage.getItem('firebaseToken'),
    retry: (_, error) => !(isAxiosError(error) && error.response?.status === 403),
  })

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        user.getIdToken().then((token) => {
          localStorage.setItem('firebaseToken', token)
        })
      } else {
        localStorage.removeItem('firebaseToken')
        queryClient.removeQueries({ queryKey: ['user'] })
      }
    })
    return () => unsubscribe()
  }, [])

  return {
    user,
    isLoadingUser: isLoading && !isFetching,
  }
}
