import deepEqual from 'lodash/isEqual'
import { useEffect, useRef } from 'react'

/**
 * https://github.com/kentcdodds/use-deep-compare-effect
 */
function checkDeps(deps: FixMe[]) {
  if (!deps || !deps.length) {
    throw new Error('useDeepCompareEffect should not be used with no dependencies. Use React.useEffect instead.')
  }
  if (deps.every(isPrimitive)) {
    throw new Error(
      'useDeepCompareEffect should not be used with dependencies that are all primitive values. Use React.useEffect instead.',
    )
  }
}

function isPrimitive(val: FixMe) {
  return val == null || /^[sbn]/.test(typeof val)
}

function useDeepCompareMemoize(value: undefined) {
  const ref = useRef()

  if (!deepEqual(value, ref.current)) {
    ref.current = value
  }

  return ref.current
}

function useDeepCompareEffect(callback: FixMe, dependencies: FixMe) {
  if (import.meta.env.DEV) {
    checkDeps(dependencies)
  }
  // eslint-disable-next-line
  useEffect(callback, useDeepCompareMemoize(dependencies))
}

export function useDeepCompareEffectNoCheck(callback: FixMe, dependencies: FixMe) {
  // eslint-disable-next-line
  useEffect(callback, useDeepCompareMemoize(dependencies))
}

export default useDeepCompareEffect
