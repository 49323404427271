import { TeamModel } from '@models/team'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AuthState } from '@store/auth/authState'
import { RcFile } from 'antd/lib/upload/interface'

type State = {
  currentTeam: TeamModel | null
  teams: TeamModel[]
  isLoading: boolean
  error: unknown | null
}
const initialState: State = {
  currentTeam: null,
  teams: [],
  isLoading: false,
  error: null,
}

const state = createSlice({
  name: 'teams',
  initialState,
  reducers: {
    getTeam: (state, _action: PayloadAction<string>) => {
      state.isLoading = true
    },
    getTeamSuccess: (state, action: PayloadAction<TeamModel>) => {
      state.isLoading = false
      state.currentTeam = action.payload
    },
    getTeamFailed: (state, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
    createTeam: (
      state,
      _action: PayloadAction<
        Partial<
          Omit<TeamModel, 'banner' | 'logo'> & {
            banner: RcFile | string | undefined
            logo: RcFile | string | undefined
          }
        >
      >,
    ) => {
      state.isLoading = true
      state.currentTeam = null
    },
    createTeamSuccess: (state, action: PayloadAction<FixMe>) => {
      state.isLoading = false
      state.currentTeam = action.payload
    },
    createTeamFailed: (state, action: PayloadAction<FixMe>) => {
      state.isLoading = false
      state.error = action.payload
    },
  },
})

export const { getTeam, createTeam, createTeamSuccess, createTeamFailed } = state.actions

export const selectCurrentTeam = ({ teams }: { teams: State }) => teams.currentTeam
export const isTeamAdministrator = (
  { auth }: { teams: State; auth: AuthState },
  teamId = auth?.user?.activeProfile.team.refId,
) => {
  if (!auth.user) return false
  return (
    teamId === auth.user?.activeProfile?.team.refId &&
    (auth.user!.activeProfile?.role === 'owner' || auth.user!.activeProfile?.role === 'admin')
  )
}
export default state.reducer

export const teamsActions = state.actions
