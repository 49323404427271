import { NotificationModel } from '@models/notification'
import { apiClient } from '@services/api'

export async function getNotifications(): Promise<NotificationModel[]> {
  return apiClient
    .get(`/notifications`)
    .then((response) => {
      if (response) {
        return response.data
      }
      throw new Error('No Response when fetching notifications')
    })
    .catch((err) => {
      throw err
    })
}

export async function removeNotification(refId: string) {
  return apiClient
    .delete(`/notifications/remove/${refId}`)
    .then((response) => {
      if (response) {
        return response.data
      }
      throw new Error('No response data')
    })
    .catch((err) => {
      throw err
    })
}

export async function updateNotification(notification: FixMe) {
  return apiClient
    .put(`/notifications/${notification.refId}`, notification)
    .then((response) => {
      if (response) {
        return response.data
      }
      throw new Error('No response data')
    })
    .catch((err) => {
      throw err
    })
}

export async function readAllNotifications() {
  return apiClient
    .put(`/notifications/read`)
    .then((response) => {
      if (response) {
        return response.data
      }
      throw new Error('No response data')
    })
    .catch((err) => {
      throw err
    })
}

export async function deleteAllNotifications() {
  return apiClient
    .delete(`/notifications`)
    .then((response) => {
      if (response) {
        return response.data
      }
      throw new Error('No response data')
    })
    .catch((err) => {
      throw err
    })
}

export async function deleteNotification(refId: string) {
  return apiClient
    .delete(`/notifications/remove/${refId}`)
    .then((response) => {
      if (response) {
        return response.data
      }
      throw new Error('No response data')
    })
    .catch((err) => {
      throw err
    })
}
