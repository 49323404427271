import { TournamentModel } from '@models/tournament'
import { apiClient } from 'services/api'

export async function joinTournament(tournament: { tournamentId: string; teamId: string }): Promise<TournamentModel> {
  return apiClient
    .post(`/tournaments/signup`, tournament)
    .then((response) => {
      if (response) {
        return response.data
      }
      throw new Error(response)
    })
    .catch((err) => {
      throw err
    })
}
