import { lazy } from 'react'
import { Navigate } from 'react-router-dom'

const LaddersList = lazy(() => import('./LaddersList'))
const AddLadder = lazy(() => import('./AddLadder'))
const LadderEdit = lazy(() => import('./LadderEdit'))

const LaddersRoutes = [
  {
    path: 'ladders',
    children: [
      {
        index: true,
        element: <LaddersList />,
      },
      {
        path: 'add-ladder',
        element: <AddLadder />,
      },
      {
        path: 'edit/:id',
        element: <LadderEdit />,
      },
      {
        path: '*',
        element: <Navigate to="" />,
      },
    ],
  },
]

export default LaddersRoutes
