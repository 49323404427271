import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import ar from './locales/ar.json'
import en from './locales/en.json'
import pt from './locales/pt.json'
import tr from './locales/tr.json'

const parseMissingKeyHandler = (key: string) => key.split('.').pop()

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: { translation: en },
      ar: { translation: ar },
      tr: { translation: tr },
      pt: { translation: pt },
    },
    lng: localStorage.getItem('language') || 'pt',
    fallbackLng: 'en',
    ns: ['translation'],
    defaultNS: 'translation',
    parseMissingKeyHandler,
    interpolation: {
      escapeValue: false, // react already safes from xss
      prefix: '%{',
      suffix: '}',
    },
  })

export default i18n
