import { lazy } from 'react'
import { Navigate } from 'react-router-dom'

import { ProfilePermission } from './ProfilePermission'

const ProfilePage = lazy(() => import('./ProfilePage'))
const ProfileAdministration = lazy(() => import('./administration/ProfileAdministration'))
const ProfilePageLayout = lazy(() => import('./ProfilePageLayout'))

// FALLBACK TO DOCUMENTATION PAGES ON WEBAPP
const ProfileConfig = {
  settings: {
    layout: {
      config: {},
    },
  },

  // routes: [
  //   {
  //     path: 'player',
  //     element: <Navigate to="/news" />,
  //   },
  //   {
  //     path: 'player',
  //     auth: null,
  //     element: <ProfilePageLayout />,
  //     children: [
  //       {
  //         path: ':playerId',
  //         auth: null,
  //         index: true,
  //         element: <ProfilePage />,
  //       },
  //     ],
  //   },
  // ],
  routes: [
    {
      path: 'player',
      auth: null,
      element: <ProfilePageLayout />,
      children: [
        {
          path: ':playerId',
          auth: null,
          children: [
            {
              index: true,
              element: <ProfilePage />,
            },
            {
              path: 'matches',
              auth: null,
              element: <div>TODO: We should add a table with the matches</div>,
            },
            {
              path: 'log',
              auth: null,
              element: <div>TODO: We should add a table with the matches</div>,
            },
            {
              path: 'awards',
              auth: null,
              element: <div>TODO: We should add a table with the awards</div>,
            },
            {
              path: 'challenges',
              auth: null,
              element: <div>TODO: We should add a table with the challenges</div>,
            },
            {
              access: 'isPlayer',
              path: 'administration',
              element: (
                <ProfilePermission>
                  <ProfileAdministration />
                </ProfilePermission>
              ),
            },
            {
              path: '*',
              auth: null,
              element: <Navigate to="" relative="route" />,
            },
          ],
        },
      ],
    },
  ],
}

export default ProfileConfig
