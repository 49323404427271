// eslint-disable-next-line max-classes-per-file,@typescript-eslint/ban-ts-comment
// @ts-nocheck
/* tslint:disable */
import Button from '@mui/material/Button'
import { red } from '@mui/material/colors'
import Dialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'
import Slide from '@mui/material/Slide'
import { styled, useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import ReddalScrollbars from '@reddal/core/ReddalScrollbars'
import ReddalSettings from '@reddal/core/ReddalSettings'
import ReddalSvgIcon from '@reddal/core/ReddalSvgIcon'
import ReddalThemeSchemes from '@reddal/core/ReddalThemeSchemes'
import { changeReddalTheme } from '@store/reddal/settingsSlice'
import themesConfig from 'configs/themesConfig'
import { forwardRef, memo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSwipeable } from 'react-swipeable'

const Root = styled('div')(({ theme }) => ({
  position: 'absolute',
  height: 80,
  right: 0,
  top: 160,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
  padding: 0,
  borderTopLeftRadius: 6,
  borderBottomLeftRadius: 6,
  borderBottomRightRadius: 0,
  borderTopRightRadius: 0,
  zIndex: 999,
  color: theme.palette.getContrastText(red[500]),
  backgroundColor: red[400],
  '&:hover': {
    backgroundColor: red[500],
  },

  '& .settingsButton': {
    '& > span': {
      animation: 'rotating 3s linear infinite',
    },
  },

  '@keyframes rotating': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(360deg)',
    },
  },
}))

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    position: 'fixed',
    width: 380,
    maxWidth: '90vw',
    backgroundColor: theme.palette.background.paper,
    top: 0,
    height: '100%',
    minHeight: '100%',
    bottom: 0,
    right: 0,
    margin: 0,
    zIndex: 1000,
    borderRadius: 0,
  },
}))

const Transition = forwardRef(function Transition(props, ref) {
  const theme = useTheme()
  return <Slide direction={theme.direction === 'ltr' ? 'left' : 'right'} ref={ref} {...props} />
})

function SettingsPanel() {
  const theme = useTheme()
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()

  const handlerOptions = {
    onSwipedLeft: () => {
      return open && theme.direction === 'rtl' && handleClose()
    },
    onSwipedRight: () => {
      return open && theme.direction === 'ltr' && handleClose()
    },
  }

  const settingsHandlers = useSwipeable(handlerOptions)
  const shemesHandlers = useSwipeable(handlerOptions)

  const handleOpen = (panelId) => {
    setOpen(panelId)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Root id="reddal-settings-schemes" className="buttonWrapper">
        <Button
          className="settingsButton m-0 h-40 w-40 min-w-40"
          onClick={() => handleOpen('settings')}
          variant="text"
          color="inherit"
          disableRipple
        >
          <span>
            <ReddalSvgIcon size={20}>heroicons-solid:cog</ReddalSvgIcon>
          </span>
        </Button>

        <Button
          className="m-0 h-40 w-40 min-w-40"
          onClick={() => handleOpen('schemes')}
          variant="text"
          color="inherit"
          disableRipple
        >
          <ReddalSvgIcon size={20}>heroicons-outline:color-swatch</ReddalSvgIcon>
        </Button>
      </Root>
      <StyledDialog
        TransitionComponent={Transition}
        aria-labelledby="settings-panel"
        aria-describedby="settings"
        open={open === 'settings'}
        onClose={handleClose}
        BackdropProps={{ invisible: true }}
        classes={{
          paper: 'shadow-lg',
        }}
        {...settingsHandlers}
      >
        <ReddalScrollbars className="p-16 sm:p-32">
          <IconButton className="fixed top-0 z-10 ltr:right-0 rtl:left-0" onClick={handleClose} size="large">
            <ReddalSvgIcon>heroicons-outline:x</ReddalSvgIcon>
          </IconButton>

          <Typography className="mb-32 font-semibold" variant="h6">
            Theme Settings
          </Typography>

          <ReddalSettings />
        </ReddalScrollbars>
      </StyledDialog>
      <StyledDialog
        TransitionComponent={Transition}
        aria-labelledby="schemes-panel"
        aria-describedby="schemes"
        open={open === 'schemes'}
        onClose={handleClose}
        BackdropProps={{ invisible: true }}
        classes={{
          paper: 'shadow-lg',
        }}
        {...shemesHandlers}
      >
        <ReddalScrollbars className="p-16 sm:p-32">
          <IconButton className="fixed top-0 z-10 ltr:right-0 rtl:left-0" onClick={handleClose} size="large">
            <ReddalSvgIcon>heroicons-outline:x</ReddalSvgIcon>
          </IconButton>

          <Typography className="mb-32" variant="h6">
            Theme Color Schemes
          </Typography>

          <Typography className="mb-24 text-justify text-12 italic" color="text.secondary">
            * Selected color scheme will be applied to all theme layout elements (navbar, toolbar, etc.). You can also
            select a different color scheme for each layout element at theme settings.
          </Typography>

          <ReddalThemeSchemes
            themes={themesConfig}
            onSelect={(_theme) => {
              dispatch(changeReddalTheme(_theme))
            }}
          />
        </ReddalScrollbars>
      </StyledDialog>
    </>
  )
}

export default memo(SettingsPanel)
