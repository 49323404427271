// eslint-disable-next-line max-classes-per-file,@typescript-eslint/ban-ts-comment
// @ts-nocheck
/* tslint:disable */
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import { alpha, styled } from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'
import { Box } from '@mui/system'
import NavLinkAdapter from '@reddal/core/NavLinkAdapter'
import ReddalIcon from '@reddal/core/ReddalIcon'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { useMemo } from 'react'

import ReddalNavBadge from '../../ReddalNavBadge'

const Root = styled(Box)(({ theme }) => ({
  '& > .reddal-list-item': {
    minHeight: 100,
    height: 100,
    width: 100,
    borderRadius: 12,
    margin: '0 0 4px 0',
    color: alpha(theme.palette.text.primary, 0.7),
    cursor: 'pointer',
    textDecoration: 'none!important',
    padding: 0,
    '&.dense': {
      minHeight: 52,
      height: 52,
      width: 52,
    },
    '&.type-divider': {
      padding: 0,
      height: 2,
      minHeight: 2,
      margin: '12px 0',
      backgroundColor:
        theme.palette.mode === 'light' ? 'rgba(0, 0, 0, .05)!important' : 'rgba(255, 255, 255, .1)!important',
      pointerEvents: 'none',
    },
    '&:hover': {
      color: theme.palette.text.primary,
    },
    '&.active': {
      color: theme.palette.text.primary,
      backgroundColor:
        theme.palette.mode === 'light' ? 'rgba(0, 0, 0, .05)!important' : 'rgba(255, 255, 255, .1)!important',
      // pointerEvents: 'none',
      transition: 'border-radius .15s cubic-bezier(0.4,0.0,0.2,1)',
      '& .reddal-list-item-text-primary': {
        color: 'inherit',
      },
      '& .reddal-list-item-icon': {
        color: 'inherit',
      },
    },
    '& .reddal-list-item-icon': {
      color: 'inherit',
    },
    '& .reddal-list-item-text': {},
  },
}))

function ReddalNavVerticalTab(props) {
  const { item, onItemClick, firstLevel, dense, selectedId } = props

  return useMemo(
    () => (
      <Root sx={item.sx}>
        <ListItem
          button
          component={item.url && NavLinkAdapter}
          to={item.url}
          end={item.end}
          className={clsx(
            `type-${item.type}`,
            dense && 'dense',
            selectedId === item.id && 'active',
            'reddal-list-item flex flex-col items-center justify-center p-12',
          )}
          onClick={() => onItemClick && onItemClick(item)}
          role="button"
          disabled={item.disabled}
        >
          {dense ? (
            <Tooltip title={item.title || ''} placement="right">
              <div className="relative flex h-32 min-h-32 w-32 items-center justify-center">
                {item.icon && (
                  <ReddalIcon
                    name={item.icon}
                    className={clsx('reddal-list-item-icon', item.iconClass)}
                    color="action"
                  />
                )}
                {item.logo && (
                  <img className={clsx('reddal-list-item-icon', item.iconClass)} src={item.logo} alt="logo" />
                )}
                {!item.icon && !item.logo && item.title && <div className="text-16 font-bold">{item.title[0]}</div>}
                {item.badge && (
                  <ReddalNavBadge
                    badge={item.badge}
                    className="absolute top-0 h-16 min-w-16 justify-center p-4 ltr:right-0 rtl:left-0"
                  />
                )}
              </div>
            </Tooltip>
          ) : (
            <>
              <div className="relative mb-8 flex h-32 min-h-32 w-32 items-center justify-center">
                {item.icon ? (
                  <ReddalIcon
                    name={item.icon}
                    size={32}
                    className={clsx('reddal-list-item-icon', item.iconClass)}
                    color="action"
                  />
                ) : (
                  item.title && <div className="text-20 font-bold">{item.title[0]}</div>
                )}
                {item.badge && (
                  <ReddalNavBadge
                    badge={item.badge}
                    className="absolute top-0 h-16 min-w-16 justify-center p-4 ltr:right-0 rtl:left-0"
                  />
                )}
              </div>

              <ListItemText
                className="reddal-list-item-text w-full grow-0"
                primary={item.title}
                classes={{
                  primary: 'text-12 font-medium reddal-list-item-text-primary truncate text-center truncate',
                }}
              />
            </>
          )}
        </ListItem>
        {!firstLevel &&
          item.children &&
          item.children.map((_item) => (
            <NavVerticalTab
              key={_item.id}
              type={`vertical-${_item.type}`}
              item={_item}
              nestedLevel={0}
              onItemClick={onItemClick}
              dense={dense}
              selectedId={selectedId}
            />
          ))}
      </Root>
    ),
    [firstLevel, item, onItemClick, dense, selectedId],
  )
}

ReddalNavVerticalTab.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    icon: PropTypes.string,
    url: PropTypes.string,
  }),
}

ReddalNavVerticalTab.defaultProps = {}

const NavVerticalTab = ReddalNavVerticalTab

export default NavVerticalTab
