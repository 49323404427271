import { LadderModel } from '@models/ladder'
import { MatchModel } from '@models/match'
import { queryClient } from '@services/api'
import {
  getLadder,
  getLadderLatestMatches,
  getLadderUpcomingMatches,
  joinLadder,
  leaveLadder,
  updateLadder,
} from '@services/api/ladders'
import { history } from '@store'
import { laddersActions, selectLadder } from '@store/ladders/laddersState'
import { showMessage } from '@store/reddal/messageSlice'
import { notification } from 'antd'
import { t } from 'i18next'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'

function* GET_LADDER(action: FixMe): FixMe {
  try {
    const ladder = yield call(getLadder, action.payload)
    console.log('ladder::::', ladder)
    yield put(laddersActions.getLadderSuccess(ladder))
  } catch (e) {
    yield put(
      showMessage({
        variant: 'error',
        message: t('errors.ladders.notFound'),
        autoHideDuration: 2000,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      }),
    )
    yield put(laddersActions.getLadderFailed(t('errors.ladders.notFound')))
  }
}

function* JOIN_LADDER(action: ReturnType<typeof laddersActions.joinLadder>) {
  const ladderInfo = action.payload
  try {
    yield call(joinLadder, ladderInfo)
    yield call(() => queryClient.invalidateQueries({ queryKey: ['ladder', ladderInfo.ladderId] }))
    yield put(laddersActions.joinLadderSuccess())
    history.go(0)
  } catch (error) {
    console.log('error::::', error)
    notification.warning({
      message: `Failed to join ladder`,
    })
    yield put(laddersActions.joinLadderFailed(error))
  }
}

function* LEAVE_LADDER(action: ReturnType<typeof laddersActions.leaveLadder>) {
  try {
    const teamLadderRefId = action.payload
    yield call(leaveLadder, teamLadderRefId)
    yield put(laddersActions.leaveLadderSuccess())
    history.go(0)
  } catch (error) {
    console.log('error::::', error)
    notification.warning({
      message: 'Failed to leave ladder',
    })
    yield put(laddersActions.leaveLadderFailed('Failed to leave ladder'))
  }
}

function* GET_UPCOMING_LATEST_LADDER_MATCHES(action: ReturnType<typeof laddersActions.getUpcomingLatestLadderMatches>) {
  const refId = action.payload
  try {
    const [upcoming, latest]: MatchModel[][] = yield all([
      call(getLadderUpcomingMatches, refId),
      call(getLadderLatestMatches, refId),
    ])
    yield put(laddersActions.getUpcomingLatestLadderMatchesSuccess({ upcoming, latest }))
  } catch (error) {
    notification.warning({
      message: 'Failed to get ladder matches',
    })
    yield put(laddersActions.getUpcomingLatestLadderMatchesFailed('Failed to get ladder matches'))
  }
}

function* UPDATE_LADDER(action: ReturnType<typeof laddersActions.updateLadder>) {
  const data = action.payload
  const { currentLadder } = yield select(selectLadder)
  try {
    const updatedLadder: LadderModel = yield call(updateLadder, data)
    yield put(laddersActions.updateLadderSuccess({ ...currentLadder, rules: updatedLadder.rules }))
    notification.success({
      message: 'Ladder rules updated successfully',
    })
  } catch (error) {
    notification.warning({
      message: 'Failed to update ladder rules',
    })
    yield put(laddersActions.updateLadderFailed('Failed to update ladder rules'))
  }
}

export default function* laddersSaga() {
  // yield takeLatest(laddersActions.getLadder.type, GET_LADDER)
  yield takeLatest(laddersActions.joinLadder.type, JOIN_LADDER)
  yield takeLatest(laddersActions.leaveLadder.type, LEAVE_LADDER)
  yield takeLatest(laddersActions.updateLadder.type, UPDATE_LADDER)
  // yield takeLatest(laddersActions.getUpcomingLatestLadderMatches.type, GET_UPCOMING_LATEST_LADDER_MATCHES)
}
