import { lightBlue, red } from '@mui/material/colors'
import { createTheme, ThemeOptions } from '@mui/material/styles'
import { reddalDark } from '@reddal/colors'
import qs from 'qs'

import theme from '../../tailwind-theme'

const defaultTheme = {
  palette: {
    mode: 'light',
    text: {
      primary: 'rgb(17, 24, 39)',
      secondary: 'rgb(107, 114, 128)',
      disabled: 'rgb(149, 156, 169)',
    },
    common: {
      black: 'rgb(17, 24, 39)',
      white: 'rgb(255, 255, 255)',
    },
    primary: {
      light: '#bec1c5',
      main: '#FB6D40',
      dark: '#FB6D40',
      contrastDefaultColor: 'light',
    },
    secondary: {
      light: '#bdf2fa',
      main: '#22d3ee',
      dark: '#0cb7e2',
    },
    background: {
      paper: '#FFFFFF',
      default: '#f6f7f9',
    },
    error: {
      light: 'rgba("#D20000", 0.4)',
      main: '#D20000',
      dark: '#640000',
    },
  },
}

/**
 * SETTINGS DEFAULTS
 */
export const defaultSettings = {
  customScrollbars: true,
  direction: 'ltr',
  theme: {
    main: defaultTheme,
    navbar: defaultTheme,
    toolbar: defaultTheme,
    footer: defaultTheme,
  },
}

export function getParsedQuerySettings() {
  const parsedQueryString = qs.parse(window.location.search, { ignoreQueryPrefix: true })

  if (parsedQueryString && parsedQueryString.defaultSettings) {
    return JSON.parse(<string>parsedQueryString.defaultSettings)
  }
  return {}

  // Generating route params from settings
  /* const settings = qs.stringify({
        defaultSettings: JSON.stringify(defaultSettings, {strictNullHandling: true})
    });
    console.info(settings); */
}

/**
 * THEME DEFAULTS
 */
interface FontSize {
  [key: string]: string | string[]
}

// Type for the new format object
interface NewFontSizeFormat {
  [key: string]: {
    fontSize: string
  }
}

// Function to create the new format object
function createNewFormatObject(fontSize: FontSize): NewFontSizeFormat {
  const newFormatObject: NewFontSizeFormat = {}
  for (const key in fontSize) {
    const value = fontSize[key]
    if (typeof value === 'string') {
      newFormatObject[key] = {
        fontSize: value,
      }
    } else if (Array.isArray(value)) {
      newFormatObject[key] = {
        fontSize: value[0], // Here, we assume that the array contains a single string.
      }
    }
  }
  return newFormatObject
}
export const defaultThemeOptions = {
  typography: {
    fontFamily: ['Euclid Circular B', 'Inter var', 'Roboto', '"Helvetica"', 'Arial', 'sans-serif'].join(','),
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    ...(theme?.fontSize && createNewFormatObject(theme.fontSize)),
  },
  // We use the space spacing as Tailwind https://tailwindcss.com/docs/customizing-spacing#default-spacing-scale
  // theme.spacing(2); // = 0.25 * 2rem = 0.5rem = 8px
  spacing: (factor: number) => `${0.25 * factor}rem`,
  components: {
    MuiDateTimePicker: {
      defaultProps: {
        PopperProps: { className: 'z-9999' },
      },
    },
    MuiAppBar: {
      defaultProps: {
        enableColorOnDark: true,
      },
      styleOverrides: {
        root: {
          backgroundImage: 'none',
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: false,
      },
    },
    // I need to change the color of the renderOption
    MuiAutocomplete: {
      styleOverrides: {
        listbox: ({ theme }: Inexpressible) => ({
          '& .MuiAutocomplete-option[aria-selected="true"].Mui-focused': {
            // works
            backgroundColor: theme.palette.secondary.main,
          },
        }),
        root: () => ({
          '& .MuiAutocomplete-tag': {
            margin: '0 6px 0 0',
          },
        }),
        // listbox: {
        //   // '& .MuiAutocomplete-option[aria-selected="true"]': {
        //   //   // works
        //   //   backgroundColor: 'lightBlue',
        //   // },
        //   '& .MuiAutocomplete-option[aria-selected="true"].Mui-focused': {
        //     // works
        //     backgroundColor: 'primary',
        //   },
        // },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'text',
        color: 'inherit',
      },
      styleOverrides: {
        root: ({ ownerState, theme }: Inexpressible) => ({
          textTransform: 'none',
          ...(ownerState.variant === 'contained' &&
            ownerState.color === 'primary' && {
              backgroundColor: theme.palette.primary.main,
              color: '#fff',
            }),
        }),
        sizeMedium: {
          borderRadius: 4,
          height: 40,
          minHeight: 40,
          maxHeight: 40,
        },
        sizeSmall: {
          borderRadius: '2px',
        },
        sizeLarge: {
          borderRadius: '4px',
        },
        contained: {
          boxShadow: 'none',
          '&:hover, &:focus': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiButtonGroup: {
      defaultProps: {
        color: 'secondary',
      },
      styleOverrides: {
        contained: {
          borderRadius: 8,
        },
      },
    },
    MuiToggleButton: {
      defaultProps: {
        variant: 'text',
        color: 'inherit',
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
          // lineHeight: 1,
        },
        sizeMedium: {
          borderRadius: 4,
          height: 40,
          minHeight: 40,
          maxHeight: 40,
        },
        sizeSmall: {
          borderRadius: '2px',
        },
        sizeLarge: {
          borderRadius: '4px',
        },
        contained: {
          boxShadow: 'none',
          '&:hover, &:focus': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiToggleButtonGroup: {
      defaultProps: {
        color: 'secondary',
      },
      styleOverrides: {
        contained: {
          borderRadius: 8,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    // MuiTable: {
    //   styleOverrides: {
    //     root: {
    //       height: '40px',
    //     },
    //   },
    // },
    MuiTableHead: {
      styleOverrides: {
        root: () => ({
          height: 40,
        }),
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: () => ({
          height: 40,
        }),
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 8,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none',
        },
        rounded: {
          borderRadius: 8,
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderRadius: 8,
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiInputLabel: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiMenuItem: {
      defaultProps: {
        color: 'secondary',
      },
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: 'rgba(0, 0, 0, 0.2) !important',
          },
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiSelected: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& :-webkit-autofill': {
            boxShadow: '0 0 0 100px transparent inset !important',
          },
        },
      },
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiNumberInput: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0, 0, 0, 0.1)',
          minHeight: 40,
          lineHeight: 1,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0, 0, 0, 0.1)',
          minHeight: 40,
          lineHeight: 1,
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          '&:before, &:after': {
            display: 'none',
          },
        },
      },
    },
    MuiSlider: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiCheckbox: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiRadio: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiSwitch: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          lg: 'h1',
        },
      },
      variants: [
        {
          props: { color: 'text.secondary' },
          style: {
            color: 'text.secondary',
          },
        },
      ],
    },
  },
}

export const mustHaveThemeOptions = {
  typography: {
    htmlFontSize: 10,
    fontSize: 14,
    body1: {
      fontSize: '1.4rem',
    },
    body2: {
      fontSize: '1.4rem',
    },
  },
}

export const defaultThemes = {
  default: {
    palette: {
      mode: 'light',
      primary: reddalDark,
      secondary: {
        light: lightBlue[400],
        main: lightBlue[600],
        dark: lightBlue[700],
      },
      error: red,
    },
    status: {
      danger: 'orange',
    },
  },
  defaultDark: {
    palette: {
      mode: 'dark',
      primary: reddalDark,
      secondary: {
        light: lightBlue[400],
        main: lightBlue[600],
        dark: lightBlue[700],
      },
      error: red,
    },
    status: {
      danger: 'orange',
    },
  },
}

export function extendThemeWithMixins(obj: ThemeOptions | undefined) {
  const theme = createTheme(obj)
  return {
    border: (width = 1) => ({
      borderWidth: width,
      borderStyle: 'solid',
      borderColor: theme.palette.divider,
    }),
    borderLeft: (width = 1) => ({
      borderLeftWidth: width,
      borderStyle: 'solid',
      borderColor: theme.palette.divider,
    }),
    borderRight: (width = 1) => ({
      borderRightWidth: width,
      borderStyle: 'solid',
      borderColor: theme.palette.divider,
    }),
    borderTop: (width = 1) => ({
      borderTopWidth: width,
      borderStyle: 'solid',
      borderColor: theme.palette.divider,
    }),
    borderBottom: (width = 1) => ({
      borderBottomWidth: width,
      borderStyle: 'solid',
      borderColor: theme.palette.divider,
    }),
  }
}
