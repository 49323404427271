import { AiOutlineHome } from 'react-icons/ai'
import { CgList } from 'react-icons/cg'
import { FaArrowRight, FaChartPie, FaDiscord, FaPlayCircle, FaUndoAlt } from 'react-icons/fa'
import { FiSend } from 'react-icons/fi'
import { GiSettingsKnobs } from 'react-icons/gi'
import { GrFacebook, GrLinkedin, GrTwitter } from 'react-icons/gr'
import { HiOutlineDocument } from 'react-icons/hi'
import { ImBlocked, ImBubbles4 } from 'react-icons/im'
import { IoIosArrowDown } from 'react-icons/io'
import {
  MdClose,
  MdMail,
  MdOutlineAdminPanelSettings,
  MdOutlineCheckCircleOutline,
  MdOutlineContentCopy,
  MdOutlineDelete,
  MdOutlineKeyboardDoubleArrowDown,
  MdOutlineKeyboardDoubleArrowUp,
  MdOutlineManageAccounts,
  MdOutlineModeEditOutline,
  MdOutlinePersonAddDisabled,
  MdOutlineSyncLock,
  MdOutlineThumbDownOffAlt,
  MdOutlineThumbUpOffAlt,
} from 'react-icons/md'
import { RiDeleteBin5Line } from 'react-icons/ri'

import { SvgIcons } from './svg/SvgIcons'

export type IconName = keyof typeof Icons
const Icons = {
  admin: MdOutlineAdminPanelSettings,
  chartPie: FaChartPie,
  delete: RiDeleteBin5Line,
  discord: FaDiscord,
  document: HiOutlineDocument,
  edit: MdOutlineModeEditOutline,
  home: AiOutlineHome,
  list: CgList,
  send: FiSend,
  undo: FaUndoAlt,
  arrowsUp: MdOutlineKeyboardDoubleArrowUp,
  arrowsDown: MdOutlineKeyboardDoubleArrowDown,
  arrowsDown2: IoIosArrowDown,
  arrowRight: FaArrowRight,
  playCircle: FaPlayCircle,
  close: MdClose,
  facebook: GrFacebook,
  linkedin: GrLinkedin,
  twitter: GrTwitter,
  chat: ImBubbles4,
  competition_settings: GiSettingsKnobs,
  copy: MdOutlineContentCopy,
  manage: MdOutlineManageAccounts,
  suspended: ImBlocked,
  pending: MdOutlineSyncLock,
  active: MdOutlineCheckCircleOutline,
  inactive: MdOutlinePersonAddDisabled,
  deleted: MdOutlineDelete,
  mail: MdMail,
  thumbsUp: MdOutlineThumbUpOffAlt,
  thumbsDown: MdOutlineThumbDownOffAlt,
  ...SvgIcons,
}

export default Icons
