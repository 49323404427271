// eslint-disable-next-line max-classes-per-file,@typescript-eslint/ban-ts-comment
// @ts-nocheck
/* tslint:disable */
import Fab from '@mui/material/Fab'
import Hidden from '@mui/material/Hidden'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import { styled } from '@mui/material/styles'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import Tooltip from '@mui/material/Tooltip'
import ReddalScrollbars from '@reddal/core/ReddalScrollbars'
import clsx from 'clsx'
import { memo, useState } from 'react'

import ReddalSvgIcon from '../ReddalSvgIcon'

const Root = styled('div')(({ theme }) => ({
  '& .ReddalSidePanel-paper': {
    display: 'flex',
    width: 56,
    transition: theme.transitions.create(['transform', 'width', 'min-width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shorter,
    }),
    paddingBottom: 64,
    height: '100%',
    maxHeight: '100vh',
    position: 'sticky',
    top: 0,
    zIndex: 999,
    '&.left': {
      '& .ReddalSidePanel-buttonWrapper': {
        left: 0,
        right: 'auto',
      },
      '& .ReddalSidePanel-buttonIcon': {
        transform: 'rotate(0deg)',
      },
    },
    '&.right': {
      '& .ReddalSidePanel-buttonWrapper': {
        right: 0,
        left: 'auto',
      },
      '& .ReddalSidePanel-buttonIcon': {
        transform: 'rotate(-180deg)',
      },
    },
    '&.closed': {
      [theme.breakpoints.up('lg')]: {
        width: 0,
      },
      '&.left': {
        '& .ReddalSidePanel-buttonWrapper': {
          justifyContent: 'start',
        },
        '& .ReddalSidePanel-button': {
          borderBottomLeftRadius: 0,
          borderTopLeftRadius: 0,
          paddingLeft: 4,
        },
        '& .ReddalSidePanel-buttonIcon': {
          transform: 'rotate(-180deg)',
        },
      },
      '&.right': {
        '& .ReddalSidePanel-buttonWrapper': {
          justifyContent: 'flex-end',
        },
        '& .ReddalSidePanel-button': {
          borderBottomRightRadius: 0,
          borderTopRightRadius: 0,
          paddingRight: 4,
        },
        '& .ReddalSidePanel-buttonIcon': {
          transform: 'rotate(0deg)',
        },
      },
      '& .ReddalSidePanel-buttonWrapper': {
        width: 'auto',
      },
      '& .ReddalSidePanel-button': {
        backgroundColor: theme.palette.background.paper,
        borderRadius: 38,
        transition: theme.transitions.create(['background-color', 'border-radius', 'width', 'min-width', 'padding'], {
          easing: theme.transitions.easing.easeInOut,
          duration: theme.transitions.duration.shorter,
        }),
        width: 24,
        '&:hover': {
          width: 52,
          paddingLeft: 8,
          paddingRight: 8,
        },
      },
      '& .ReddalSidePanel-content': {
        opacity: 0,
      },
    },
  },

  '& .ReddalSidePanel-content': {
    overflow: 'hidden',
    opacity: 1,
    transition: theme.transitions.create(['opacity'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.short,
    }),
  },

  '& .ReddalSidePanel-buttonWrapper': {
    position: 'absolute',
    bottom: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '12px 0',
    width: '100%',
    minWidth: 56,
  },

  '& .ReddalSidePanel-button': {
    padding: 8,
    width: 40,
    height: 40,
  },

  '& .ReddalSidePanel-buttonIcon': {
    transition: theme.transitions.create(['transform'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.short,
    }),
  },

  '& .ReddalSidePanel-mobileButton': {
    height: 40,
    position: 'absolute',
    zIndex: 99,
    bottom: 12,
    width: 24,
    borderRadius: 38,
    padding: 8,
    backgroundColor: theme.palette.background.paper,
    transition: theme.transitions.create(['background-color', 'border-radius', 'width', 'min-width', 'padding'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter,
    }),
    '&:hover': {
      width: 52,
      paddingLeft: 8,
      paddingRight: 8,
    },
    '&.left': {
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 0,
      paddingLeft: 4,
      left: 0,
    },

    '&.right': {
      borderBottomRightRadius: 0,
      borderTopRightRadius: 0,
      paddingRight: 4,
      right: 0,
      '& .ReddalSidePanel-buttonIcon': {
        transform: 'rotate(-180deg)',
      },
    },
  },
}))

function ReddalSidePanel(props) {
  const [opened, setOpened] = useState(props.opened)
  const [mobileOpen, setMobileOpen] = useState(false)

  function toggleOpened() {
    setOpened(!opened)
  }

  function toggleMobileDrawer() {
    setMobileOpen(!mobileOpen)
  }

  return (
    <Root>
      <Hidden lgDown>
        <Paper
          className={clsx(
            'ReddalSidePanel-paper',
            props.className,
            opened ? 'opened' : 'closed',
            props.position,
            'shadow-lg',
          )}
          square
        >
          <ReddalScrollbars className={clsx('content', 'ReddalSidePanel-content')}>{props.children}</ReddalScrollbars>

          <div className="ReddalSidePanel-buttonWrapper">
            <Tooltip title="Toggle side panel" placement={props.position === 'left' ? 'right' : 'right'}>
              <IconButton className="ReddalSidePanel-button" onClick={toggleOpened} disableRipple size="large">
                <ReddalSvgIcon className="ReddalSidePanel-buttonIcon">heroicons-outline:chevron-left</ReddalSvgIcon>
              </IconButton>
            </Tooltip>
          </div>
        </Paper>
      </Hidden>
      <Hidden lgUp>
        <SwipeableDrawer
          classes={{
            paper: clsx('ReddalSidePanel-paper', props.className),
          }}
          anchor={props.position}
          open={mobileOpen}
          onOpen={(ev) => {}}
          onClose={toggleMobileDrawer}
          disableSwipeToOpen
        >
          <ReddalScrollbars className={clsx('content', 'ReddalSidePanel-content')}>{props.children}</ReddalScrollbars>
        </SwipeableDrawer>

        <Tooltip title="Hide side panel" placement={props.position === 'left' ? 'right' : 'right'}>
          <Fab
            className={clsx('ReddalSidePanel-mobileButton', props.position)}
            onClick={toggleMobileDrawer}
            disableRipple
          >
            <ReddalSvgIcon className="ReddalSidePanel-buttonIcon">heroicons-outline:chevron-right</ReddalSvgIcon>
          </Fab>
        </Tooltip>
      </Hidden>
    </Root>
  )
}

ReddalSidePanel.propTypes = {}
ReddalSidePanel.defaultProps = {
  position: 'left',
  opened: true,
}

export default memo(ReddalSidePanel)
