import { UserGuidModel } from '@models/guid'
import { ProfileModel } from '@models/profile'
import { UserModel } from '@models/user'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RcFile } from 'antd/lib/upload/interface'
import settingsConfig from 'configs/settingsConfig'

export type AuthState = {
  isAuthenticated: boolean
  isLoading: boolean
  loadingUser: boolean
  authAction: boolean
  error: Error | null
  user: UserModel | null
}
const initialState: AuthState = {
  isAuthenticated: false,
  isLoading: false,
  loadingUser: false,
  authAction: false,
  error: null,
  user: null,
}

interface RegistrationPayload {
  username: string
  email: string
  password: string
}
const authState = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    userLogin: (state) => {
      state.isLoading = true
    },
    discordLogin: (state, _action: PayloadAction<string>) => {
      state.isLoading = true
    },
    userLoginSuccess: (state, action) => {
      state.isLoading = false
      state.isAuthenticated = true
      state.user = action.payload
      if (action.payload.loginRedirectUrl) {
        settingsConfig.loginRedirectUrl = action.payload.loginRedirectUrl // for example '/apps/academy'
      }
    },
    userLoginFailed: (state, action) => {
      state.isLoading = false
      state.isAuthenticated = false
      state.user = null
      state.error = action.payload
    },
    userLogout: (state) => {
      state.isLoading = true
    },
    userLogoutFailed: (_state, action) => {
      return {
        ...initialState,
        error: action.payload,
      }
    },
    userLogoutSuccess: () => initialState,
    signUp: (state, _action: PayloadAction<RegistrationPayload>) => {
      state.isLoading = true
    },
    signUpSuccess: (state, action) => {
      state.isLoading = false
      state.isAuthenticated = true
      state.user = action.payload
    },
    signUpFailed: (_state, action) => {
      return {
        ...initialState,
        error: action.payload,
      }
    },
    forgotPassword: (state, _action: PayloadAction<string>) => {
      state.authAction = true
    },
    forgotPasswordSuccess: (state) => {
      state.authAction = false
    },
    forgotPasswordFailed: (state, action) => {
      state.authAction = false
      state.error = action.payload
    },
    setActiveProfile: (state, _action: PayloadAction<ProfileModel>) => {
      state.loadingUser = true
    },
    setActiveProfileSuccess: (state, action: PayloadAction<ProfileModel>) => {
      state.user!.activeProfile = action.payload
      state.loadingUser = false
    },
    setActiveProfileFailed: (state, action) => {
      state.loadingUser = false
      state.user = null
      state.error = action.payload
    },
    addUserGuid: (state, _action: PayloadAction<Partial<UserGuidModel>>) => {
      state.loadingUser = true
    },
    addUserGuidSuccess: (state) => {
      state.loadingUser = false
    },
    addUserGuidFailed: (state, action) => {
      state.loadingUser = false
      state.error = action.payload
    },
    getUser: (state, _action: PayloadAction<string>) => {
      state.loadingUser = true
    },
    getUserSuccess: (state, action: PayloadAction<UserModel>) => {
      state.user = action.payload
      state.loadingUser = false
    },
    getUserFailed: (state, action) => {
      state.loadingUser = false
      state.error = action.payload
    },
    updateUser: (
      state,
      _action: PayloadAction<
        Partial<
          Omit<UserModel, 'banner' | 'avatar' | 'guids'> & {
            banner: RcFile | string | undefined
            avatar: RcFile | string | undefined
            guids: string[]
          }
        >
      >,
    ) => {
      state.loadingUser = true
    },
    updateUserSuccess: (state, action: PayloadAction<UserModel>) => {
      state.user = action.payload
      state.loadingUser = false
    },
    updateUserFailed: (state, action) => {
      state.loadingUser = false
      state.error = action.payload
    },
  },
})

export const {
  userLoginSuccess,
  userLogin,
  userLoginFailed,
  userLogoutSuccess,
  userLogout,
  signUp,
  signUpFailed,
  signUpSuccess,
  forgotPassword,
  forgotPasswordSuccess,
  forgotPasswordFailed,
  discordLogin,
  setActiveProfile,
  addUserGuid,
  addUserGuidSuccess,
  addUserGuidFailed,
  updateUser,
  updateUserSuccess,
  updateUserFailed,
} = authState.actions

export const selectUser = ({ auth }: { auth: AuthState }) => auth.user
export const selectUserProfiles = ({ auth }: { auth: AuthState }) =>
  auth.user?.profiles?.filter((p: ProfileModel) => p.refId !== auth.user?.activeProfile?.refId)
export const selectUserActiveProfile = ({ auth }: { auth: AuthState }) => auth.user?.activeProfile
export const selectAllUserProfiles = ({ auth }: { auth: AuthState }) => auth.user?.profiles
export const selectUserShortcuts = ({ auth }: FixMe) => auth.user?.settings?.shortcuts || []

export default authState.reducer

export const authActions = authState.actions
