import { lazy } from 'react'
import { Navigate } from 'react-router-dom'

const UsersList = lazy(() => import('./users-list/UsersList'))
const UsersEdit = lazy(() => import('./users-edit/UsersEdit'))

const UsersRoutes = [
  {
    path: 'users',
    children: [
      {
        index: true,
        element: <UsersList />,
      },
      {
        path: 'edit/:id',
        element: <UsersEdit />,
      },
      {
        path: '*',
        element: <Navigate to="" />,
      },
    ],
  },
]

export default UsersRoutes
