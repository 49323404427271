import { useUser } from '@hooks/useUser'
import { getTeam } from '@services/api/teams'
import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

export const useTeam = (refId?: string) => {
  const { user } = useUser()
  const { teamId } = useParams()
  const teamIdToUse = refId || teamId || '7e8db1d8-9f25-45a2-957b-1162adb1acd9'
  const { data: team, isLoading: loadingTeam } = useQuery({
    queryKey: ['team', teamIdToUse],
    queryFn: () => getTeam(teamIdToUse),
    staleTime: 1000 * 60 * 60 * 24,
  })

  const { isOwner, isAdmin, isPlayer, belongsToTeam } = useMemo(() => {
    let isOwner = false
    let isAdmin = false
    let isPlayer = false
    user?.profiles.find((p) => {
      if (p.team?._id === team?._id) {
        if (p.role === 'owner') isOwner = true
        if (p.role === 'admin') isAdmin = true
        if (p.role === 'player') isPlayer = true
      }
    })

    return {
      isOwner,
      isAdmin,
      isPlayer,
      belongsToTeam: isOwner || isAdmin || isPlayer,
    }
  }, [user, teamId])

  return {
    hasPermission: isOwner || isAdmin,
    isOwner,
    isAdmin,
    isPlayer,
    belongsToTeam,
    team,
    loadingTeam,
  }
}
