import { all, call } from 'redux-saga/effects'

import administration from './administration/sagas'
import auth from './auth/authSaga'
import challenges from './challenges/sagas'
import games from './games/sagas'
import { rehydration } from './index'
import ladders from './ladders/laddersSaga'
import platform from './platform/sagas'
import players from './players/playersSaga'
import teams from './teams/sagas'
import tournaments from './tournaments/tournamentsSaga'

export default function* rootSaga(): FixMe {
  yield all([
    yield call(rehydration),
    administration(),
    auth(),
    challenges(),
    games(),
    ladders(),
    platform(),
    players(),
    teams(),
    tournaments(),
  ])
}
