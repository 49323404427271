import { lazy } from 'react'
import { Navigate } from 'react-router-dom'

const GuidsList = lazy(() => import('./guids-list/GuidsList'))
const AddGuid = lazy(() => import('./AddGuid'))
const GuidEdit = lazy(() => import('./GuidEdit'))

const GuidsRoutes = [
  {
    path: 'guids',
    children: [
      {
        index: true,
        element: <GuidsList />,
      },
      {
        path: 'add-guid',
        element: <AddGuid />,
      },
      {
        path: 'edit/:id',
        element: <GuidEdit />,
      },
      {
        path: '*',
        element: <Navigate to="" />,
      },
    ],
  },
]

export default GuidsRoutes
