/// <reference types="vite-plugin-svgr/client" />
import Challenge from 'assets/icons/challenge.svg?react'
import ReddalLogo from 'assets/images/logo/logo.svg?react'
import EtLegacy from 'assets/images/logo/wolfenstein.svg?react'

export const SvgIcons = {
  reddal_logo: ReddalLogo,
  et_legacy: EtLegacy,
  challenge: Challenge,
}
