import { lazy } from 'react'
import { Navigate } from 'react-router-dom'

// const MatchPageLayout = lazy(() => import('./MatchPageLayout'))
// const MatchContent = lazy(() => import('./MatchContent'))

const MatchConfig = {
  settings: {
    layout: {
      config: {},
    },
  },

  routes: [],
}

export default MatchConfig
