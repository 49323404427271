import { ChallengeModel } from '@models/challenge'
import { ProfileModel } from '@models/profile'
import { createId } from '@paralleldrive/cuid2'
import { challenge } from '@services/api/challenges'
import { history } from '@store'
import { selectUserActiveProfile } from '@store/auth/authState'
import { challengeActions } from '@store/challenges/state'
import { notification } from 'antd'
import { call, put, select, takeLatest } from 'redux-saga/effects'

function* CHALLENGE(action: FixMe) {
  const challengeInfo = action.payload
  try {
    challengeInfo.refId = createId()
    const activeProfile: ProfileModel = yield select(selectUserActiveProfile)
    const newChallenge: ChallengeModel = yield call(challenge, challengeInfo)
    yield put(challengeActions.challengeSuccess(newChallenge))
    history.push(`/team/${activeProfile.team.refId}/challenges`)
  } catch (error) {
    yield put(challengeActions.challengeSuccess('Failed to create challenge'))
  }
}

function* CREATE_CHALLENGE(action: ReturnType<typeof challengeActions.createChallenge>) {
  try {
    const challenge = action.payload
    yield put(challengeActions.createChallengeSuccess(challenge))
    history.push(`/challenge`)
  } catch (error) {
    yield put(challengeActions.createChallengeFailed('Failed to create challenge'))
  }
}

export default function* sagas() {
  yield takeLatest(challengeActions.challenge.type, CHALLENGE)
  yield takeLatest(challengeActions.createChallenge.type, CREATE_CHALLENGE)
}
