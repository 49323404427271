// eslint-disable-next-line max-classes-per-file,@typescript-eslint/ban-ts-comment
// @ts-nocheck
/* tslint:disable */
import { Button } from '@components'
import { notificationColorByType, notificationShouldHaveButtons, notificationTitleByType } from '@models/notification'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import NavLinkAdapter from '@reddal/core/NavLinkAdapter'
import ReddalIcon from '@reddal/core/ReddalIcon'
import ReddalSvgIcon from '@reddal/core/ReddalSvgIcon'
import { queryClient } from '@services/api'
import { handleInvite } from '@services/api/auth'
import { deleteNotification } from '@services/api/notifications'
import { showMessage } from '@store/reddal/messageSlice'
import { useMutation } from '@tanstack/react-query'
import clsx from 'clsx'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'

function NotificationCard(props) {
  const { item, className } = props
  const variant = item?.variant || ''

  const notificationColor = notificationColorByType(item.type)

  const notificationMutation = useMutation({
    mutationFn: ({ refId, status }: { refId: string; status: string }) =>
      handleInvite({ refId, status })
        .then(() => {
          const message = status ? 'Invite accepted' : 'Invite declined'
          dispatch(showMessage({ message: message, type: 'success' }))
        })
        .catch((err) => {
          dispatch(showMessage({ message: err.message, type: 'error' }))
        }),
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['user', 'notifications'] }),
  })

  const deleteNotificationMutation = useMutation({
    mutationFn: ({ refId }: { refId: string }) => deleteNotification(refId),
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['user', 'notifications'] }),
  })

  const handleClose = (ev) => {
    ev.preventDefault()
    ev.stopPropagation()

    deleteNotificationMutation.mutate({ refId: item.refId })
  }

  const acceptNotification = (ev) => {
    ev.preventDefault()
    ev.stopPropagation()

    notificationMutation.mutate({ refId: item.relatedId, status: 'ACCEPT' })
    console.log('ACCEPT NOTIFICATION', item)
  }

  const declineNotification = (ev) => {
    ev.preventDefault()
    ev.stopPropagation()

    notificationMutation.mutate({ refId: item.relatedId, status: 'DECLINE' })
    console.log('DECLINE NOTIFICATION', item)
  }

  if (!item) {
    return null
  }

  return (
    <Card
      className={clsx(
        'relative flex min-h-64 w-full items-center space-x-8 rounded-16 shadow',
        variant === 'success' && 'bg-green-600 text-white',
        variant === 'info' && 'bg-blue-700 text-white',
        variant === 'error' && 'bg-red-600 text-white',
        variant === 'warning' && 'bg-orange-600 text-white',
        className,
      )}
      elevation={0}
      component={item.useRouter ? NavLinkAdapter : 'div'}
      to={item?.link || ''}
      role={item?.link && 'button'}
    >
      <div className={`absolute ms--20 h-full w-[4px] ${'bg-' + notificationColor}`}></div>
      <div className={'w-full p-20'}>
        {item.icon && !item.image && (
          <Box
            sx={{ backgroundColor: 'background.default' }}
            className="mr-12 flex h-32 w-32 shrink-0 items-center justify-center rounded-full"
          >
            <ReddalSvgIcon className="opacity-75" color="inherit">
              {item.icon}
            </ReddalSvgIcon>
          </Box>
        )}

        {item.image && (
          <img
            className="mr-12 h-32 w-32 shrink-0 overflow-hidden rounded-full object-cover object-center"
            src={item.image}
            alt="Notification"
          />
        )}

        <div className="flex flex-auto flex-col">
          {item.type && (
            <Typography className="line-clamp-1 font-semibold text-white">
              {notificationTitleByType(item.type)}
            </Typography>
          )}

          {item.message && <div className="line-clamp-2" dangerouslySetInnerHTML={{ __html: item.message }} />}

          {item.createdAt && (
            <Typography className="mt-8 text-sm leading-none " color="text.secondary">
              {formatDistanceToNow(new Date(item.createdAt), { addSuffix: true })}
            </Typography>
          )}

          {notificationShouldHaveButtons(item.type) && (
            <div className={'mt-8 flex flex-row justify-between space-x-4'}>
              <div className={'border-b-1 border-green'}>
                <Button
                  className="whitespace-nowrap"
                  onClick={acceptNotification}
                  startIcon={<ReddalIcon size={12} name="thumbsUp" />}
                >
                  Accept
                </Button>
              </div>

              <div className={'border-b-1 border-red'}>
                <Button
                  className="whitespace-nowrap"
                  onClick={declineNotification}
                  startIcon={<ReddalIcon size={12} name="thumbsDown" />}
                >
                  Decline
                </Button>
              </div>
            </div>
          )}
        </div>

        <IconButton
          disableRipple
          className="absolute right-0 top-0 p-8"
          color="inherit"
          size="small"
          onClick={handleClose}
        >
          <ReddalSvgIcon size={12} className="opacity-75" color="inherit">
            heroicons-solid:x
          </ReddalSvgIcon>
        </IconButton>
        {item.children}
      </div>
    </Card>
  )
}

export default NotificationCard
