// eslint-disable-next-line max-classes-per-file,@typescript-eslint/ban-ts-comment
// @ts-nocheck
/* tslint:disable */
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import { styled } from '@mui/material/styles'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useDispatch } from 'react-redux'

import ReddalSvgIcon from '../../../ReddalSvgIcon'
import ReddalNavBadge from '../../ReddalNavBadge'

const Root = styled(ListItem)(({ theme, ...props }) => ({
  minHeight: 44,
  width: '100%',
  borderRadius: '6px',
  margin: '0 0 4px 0',
  paddingRight: 16,
  paddingLeft: props.itempadding > 80 ? 80 : props.itempadding,
  paddingTop: 10,
  paddingBottom: 10,
  '&.active': {
    backgroundColor: `${theme.palette.secondary.main}!important`,
    color: `${theme.palette.secondary.contrastText}!important`,
    pointerEvents: 'none',
    transition: 'border-radius .15s cubic-bezier(0.4,0.0,0.2,1)',
    '& > .reddal-list-item-text-primary': {
      color: 'inherit',
    },
    '& > .reddal-list-item-icon': {
      color: 'inherit',
    },
  },
  '& > .reddal-list-item-icon': {
    marginRight: 16,
  },
  '& > .reddal-list-item-text': {},
  color: theme.palette.text.primary,
  textDecoration: 'none!important',
}))

function ReddalNavVerticalLink(props) {
  const dispatch = useDispatch()
  const { item, nestedLevel, onItemClick } = props

  const itempadding = nestedLevel > 0 ? 38 + nestedLevel * 16 : 16

  return useMemo(
    () => (
      <Root
        button
        component="a"
        href={item.url}
        target={item.target ? item.target : '_blank'}
        className="reddal-list-item"
        onClick={() => onItemClick && onItemClick(item)}
        role="button"
        itempadding={itempadding}
        sx={item.sx}
        disabled={item.disabled}
      >
        {item.icon && (
          <ReddalSvgIcon className={clsx('reddal-list-item-icon shrink-0', item.iconClass)} color="action">
            {item.icon}
          </ReddalSvgIcon>
        )}

        <ListItemText
          className="reddal-list-item-text"
          primary={item.title}
          secondary={item.subtitle}
          classes={{
            primary: 'text-13 font-medium reddal-list-item-text-primary truncate',
            secondary: 'text-11 font-medium reddal-list-item-text-secondary leading-normal truncate',
          }}
        />

        {item.badge && <ReddalNavBadge badge={item.badge} />}
      </Root>
    ),
    [item, itempadding, onItemClick],
  )
}

ReddalNavVerticalLink.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    icon: PropTypes.string,
    url: PropTypes.string,
    target: PropTypes.string,
  }),
}
ReddalNavVerticalLink.defaultProps = {}

const NavVerticalLink = ReddalNavVerticalLink

export default NavVerticalLink
