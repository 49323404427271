import { useUser } from '@hooks/useUser'
import ReddalSplashScreen from '@reddal/core/ReddalSplashScreen'
import firebaseConfig from 'configs/firebase'
import { getAnalytics } from 'firebase/analytics'
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { createContext, useContext } from 'react'

const app = initializeApp(firebaseConfig)
export const analytics = getAnalytics(app)
export const auth = getAuth(app)

const AuthContext = createContext<FixMe | null>(null)
function AuthProvider({ children }: FixMe) {
  const { user, isLoadingUser } = useUser()
  return isLoadingUser ? (
    <ReddalSplashScreen />
  ) : (
    <AuthContext.Provider value={{ isAuthenticated: !!user }}>{children}</AuthContext.Provider>
  )
}

function useAuth() {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider')
  }
  return context
}

export { AuthProvider, useAuth }
