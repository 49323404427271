// eslint-disable-next-line max-classes-per-file,@typescript-eslint/ban-ts-comment
// @ts-nocheck
/* tslint:disable */
import { useUser } from '@hooks/useUser'
import AppBar from '@mui/material/AppBar'
import Hidden from '@mui/material/Hidden'
import { ThemeProvider } from '@mui/material/styles'
import Toolbar from '@mui/material/Toolbar'
import { selectReddalCurrentLayoutConfig, selectToolbarTheme } from '@store/reddal/settingsSlice'
import clsx from 'clsx'
import { memo } from 'react'
import { useSelector } from 'react-redux'

import AdjustFontSize from '../../shared-components/AdjustFontSize'
import ChatPanelToggleButton from '../../shared-components/chatPanel/ChatPanelToggleButton'
import FullScreenToggle from '../../shared-components/FullScreenToggle'
import LanguageSwitcher from '../../shared-components/LanguageSwitcher'
import Logo from '../../shared-components/Logo'
import NavbarToggleButton from '../../shared-components/NavbarToggleButton'
import NavigationSearch from '../../shared-components/NavigationSearch'
import NotificationPanelToggleButton from '../../shared-components/notificationPanel/NotificationPanelToggleButton'
import QuickPanelToggleButton from '../../shared-components/quickPanel/QuickPanelToggleButton'
import UserMenu from '../../shared-components/UserMenu'

function ToolbarLayout3(props) {
  const config = useSelector(selectReddalCurrentLayoutConfig)
  const toolbarTheme = useSelector(selectToolbarTheme)
  const { user } = useUser()

  return (
    <ThemeProvider theme={toolbarTheme}>
      <AppBar
        id="reddal-toolbar"
        className={clsx('relative z-20 flex shadow-md', props.className)}
        color="default"
        style={{ backgroundColor: toolbarTheme.palette.background.paper }}
      >
        <Toolbar className="container min-h-48 p-0 md:min-h-64 lg:px-24">
          {config.navbar.display && (
            <Hidden lgUp>
              <NavbarToggleButton className="mx-0 h-40 w-40 p-0 sm:mx-8" />
            </Hidden>
          )}

          <Hidden lgDown>
            <div className={clsx('flex shrink-0 items-center')}>
              <Logo />
            </div>
          </Hidden>

          <div className="flex flex-1">
            <Hidden smDown>
              <NavigationSearch className="mx-16 lg:mx-24" variant="basic" />
            </Hidden>
          </div>

          <div className="flex h-full items-center overflow-x-auto px-8 md:px-0">
            <Hidden smUp>
              <NavigationSearch />
            </Hidden>

            <Hidden lgUp>
              <ChatPanelToggleButton />
            </Hidden>

            <LanguageSwitcher />

            <AdjustFontSize />

            <FullScreenToggle />

            <QuickPanelToggleButton />

            <NotificationPanelToggleButton />

            {user && <UserMenu />}
          </div>
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  )
}

export default memo(ToolbarLayout3)
