import { LadderModel } from '@models/ladder'
import { MatchModel } from '@models/match'
import { RawDraftContentState } from 'draft-js'
import { apiClient } from 'services/api'

export async function getLadder(refId: string): Promise<LadderModel> {
  return apiClient
    .get(`/ladder/${refId}`)
    .then((response) => {
      if (response) {
        return response.data
      }
      throw new Error('No response data')
    })
    .catch((err) => {
      throw err
    })
}

export async function addLadder(ladder: Partial<LadderModel>): Promise<LadderModel> {
  return apiClient
    .post(`/ladder`, ladder)
    .then((response) => {
      if (response) {
        return response.data
      }
      throw new Error('No response data')
    })
    .catch((err) => {
      throw err
    })
}

export async function joinLadder(ladder: { ladderId: string; teamId: string }) {
  const response = await apiClient.post(`/teamLadder`, ladder)
  if (response) {
    return response.data
  }
  throw new Error(response)
}

export async function getLadderUpcomingMatches(refId: string): Promise<MatchModel[]> {
  return apiClient
    .get(`/ladder/${refId}/upcoming`)
    .then((response) => {
      if (response) {
        return response.data
      }
      throw new Error('No ladder upcoming response data')
    })
    .catch((err) => {
      throw err
    })
}

export async function getLadderLatestMatches(refId: string): Promise<MatchModel[]> {
  return apiClient
    .get(`/ladder/${refId}/latest`)
    .then((response) => {
      if (response) {
        return response.data
      }
      throw new Error('No ladder latest response data')
    })
    .catch((err) => {
      throw err
    })
}

export async function updateLadder(data: { ladder: LadderModel; rules: RawDraftContentState }) {
  return apiClient
    .put(`/ladder/${data.ladder.refId}`, { rules: data.rules })
    .then((response) => {
      if (response) {
        return response.data
      }
      throw new Error('No response data')
    })
    .catch((err) => {
      throw err
    })
}

export async function leaveLadder(teamLadderRefId: string) {
  return apiClient
    .delete(`/teamLadder/${teamLadderRefId}`, {})
    .then((response) => {
      if (response) {
        return response.data
      }
      throw new Error('No deleted response data')
    })
    .catch((err) => {
      throw err
    })
}
