import { ChallengeModel } from '@models/challenge'
import { apiClient } from 'services/api'

export async function challenge(challengeInfo: FixMe) {
  return apiClient
    .post(`/challenge`, challengeInfo)
    .then((response) => {
      if (response) {
        return response.data
      }
      throw new Error(response)
    })
    .catch((err) => {
      throw err
    })
}

export async function getChallenges(teamId: string): Promise<ChallengeModel[]> {
  return apiClient
    .get(`/challenges/${teamId}`)
    .then((response) => {
      if (response) {
        return response.data
      }
      throw new Error('No get challenges response data')
    })
    .catch((err) => {
      throw err
    })
}

export async function getCompetitionChallenges(competitionId: string) {
  return apiClient
    .get(`/challenges/competition/${competitionId}`)
    .then((response) => {
      if (response) {
        return response.data
      }
      throw new Error('No get competition challenges response data')
    })
    .catch((err) => {
      throw err
    })
}

export async function getChallenge(challengeId: string) {
  return apiClient
    .get(`/challenge/${challengeId}`)
    .then((response) => {
      if (response) {
        return response.data
      }
      throw new Error('No get challenges response data')
    })
    .catch((err) => {
      throw err
    })
}

// export async function handleChallenge(challengeInfo: ChallengeModel): Promise<ChallengeModel> {
//   return apiClient
//     .put(`/challenge/${challengeInfo.refId}`, challengeInfo)
//     .then((response) => {
//       if (response) {
//         return response.data
//       }
//       throw new Error('No response data')
//     })
//     .catch((err) => {
//       throw err
//     })
// }
//
// export async function cancelChallenge(challengeInfo: ChallengeModel) {
//   return apiClient
//     .delete(`/challenge/${challengeInfo.refId}`)
//     .then((response) => {
//       if (response) {
//         return response.data
//       }
//       throw new Error('No response data')
//     })
//     .catch((err) => {
//       throw err
//     })
// }
//
// export async function forceChallenge(challengeInfo: ChallengeModel): Promise<ChallengeModel> {
//   return apiClient
//     .post(`/ladder/${challengeInfo.competition.refId}/forceMatch/${challengeInfo.refId}`, challengeInfo)
//     .then((response) => {
//       if (response) {
//         return response.data
//       }
//       throw new Error('No response data')
//     })
//     .catch((err) => {
//       throw err
//     })
// }
