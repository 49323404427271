import authRoles from 'configs/authRoles'
import { lazy } from 'react'

const ChallengePageLayout = lazy(() => import('./ChallengePageLayout'))
const ChallengeContent = lazy(() => import('./ChallengeContent'))
const CreateChallenge = lazy(() => import('./CreateChallenge'))

const ChallengeConfig = {
  settings: {
    layout: {
      config: {},
    },
  },

  routes: [
    {
      path: 'challenge',
      auth: null,
      element: <ChallengePageLayout />,
      children: [
        {
          path: ':challengeId',
          auth: authRoles.user,
          element: <ChallengeContent />,
        },
        {
          auth: null,
          index: true,
          element: <CreateChallenge />,
        },
      ],
    },
  ],
}

export default ChallengeConfig
