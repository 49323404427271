import { TournamentModel } from '@models/tournament'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface TournamentState {
  tournaments: TournamentModel[]
  tournament: TournamentModel | null
  isLoading: boolean
  error: FixMe
}
const initialState: TournamentState = {
  isLoading: false,
  tournament: null,
  tournaments: [],
  error: null,
}

export interface CreateTournamentPayload {
  refId: string
}

export interface UpdateTournamentPayload {
  refId: string
}

const tournamentsState = createSlice({
  name: 'adminGames',
  initialState,
  reducers: {
    fetchTournaments: (state) => {
      state.isLoading = true
    },
    fetchTournamentsSuccess: (state, action) => {
      state.isLoading = false
      state.tournaments = action.payload
    },
    fetchTournamentsFailed: (state, action) => {
      state.isLoading = false
      state.error = action.payload
    },
    fetchTournament: (state, _action: PayloadAction<string>) => {
      state.isLoading = true
    },
    fetchTournamentSuccess: (state, action) => {
      state.isLoading = false
      state.tournament = action.payload
    },
    fetchTournamentFailed: (state, action) => {
      state.isLoading = false
      state.error = action.payload
    },
    updateTournament: (state, _action: PayloadAction<string>) => {
      state.isLoading = true
    },
    updateTournamentSuccess: (state, action) => {
      state.isLoading = false
      state.tournament = action.payload
    },
    updateTournamentFailed: (state, action) => {
      state.isLoading = false
      state.error = action.payload
    },
    createTournament: (state, _action: PayloadAction<FixMe>) => {
      state.isLoading = true
    },
    createTournamentSuccess: (state, action) => {
      state.isLoading = false
      state.tournament = action.payload
    },
    createTournamentFailed: (state, action) => {
      state.isLoading = false
      state.error = action.payload
    },
    deleteTournament: (state, _action: PayloadAction<TournamentModel>) => {
      state.isLoading = true
    },
    deleteTournamentSuccess: (state, action) => {
      state.isLoading = false
      state.tournament = action.payload
    },
    deleteTournamentFailed: (state, action) => {
      state.isLoading = false
      state.error = action.payload
    },
  },
})

export const {
  fetchTournaments,
  fetchTournamentsSuccess,
  fetchTournamentsFailed,
  fetchTournament,
  fetchTournamentSuccess,
  fetchTournamentFailed,
  updateTournament,
  createTournament,
  createTournamentSuccess,
  createTournamentFailed,
  deleteTournamentSuccess,
  deleteTournamentFailed,
  deleteTournament,
} = tournamentsState.actions

export default tournamentsState.reducer

export const adminTournamentsActions = tournamentsState.actions
