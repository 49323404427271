import { useUser } from '@hooks/useUser'
import AppBar from '@mui/material/AppBar'
import Button from '@mui/material/Button'
import Hidden from '@mui/material/Hidden'
import { ThemeProvider } from '@mui/material/styles'
import Toolbar from '@mui/material/Toolbar'
import ReddalSvgIcon from '@reddal/core/ReddalSvgIcon'
import { useAppSelector } from '@store/hooks'
import { selectReddalNavbar } from '@store/reddal/navbarSlice'
import { selectReddalCurrentLayoutConfig, selectToolbarTheme } from '@store/reddal/settingsSlice'
import clsx from 'clsx'
import { ProfilePicker } from 'layouts/shared-components/ProfilePicker'
import { rgba } from 'polished'
import { memo } from 'react'
import { useNavigate } from 'react-router-dom'

import AdjustFontSize from '../../shared-components/AdjustFontSize'
import ChatPanelToggleButton from '../../shared-components/chatPanel/ChatPanelToggleButton'
import FullScreenToggle from '../../shared-components/FullScreenToggle'
import LanguageSwitcher from '../../shared-components/LanguageSwitcher'
import NavbarToggleButton from '../../shared-components/NavbarToggleButton'
import NavigationSearch from '../../shared-components/NavigationSearch'
import NavigationShortcuts from '../../shared-components/NavigationShortcuts'
import NotificationPanelToggleButton from '../../shared-components/notificationPanel/NotificationPanelToggleButton'
import QuickPanelToggleButton from '../../shared-components/quickPanel/QuickPanelToggleButton'
import UserMenu from '../../shared-components/UserMenu'

function ToolbarLayout1(props: FixMe) {
  const { user } = useUser()
  const config = useAppSelector(selectReddalCurrentLayoutConfig)
  const navbar = useAppSelector(selectReddalNavbar)
  const toolbarTheme = useAppSelector(selectToolbarTheme)
  const navigate = useNavigate()
  const navigateToLogin = () => {
    navigate('/sign-in')
  }
  return (
    <ThemeProvider theme={toolbarTheme}>
      <AppBar
        id="reddal-toolbar"
        className={clsx('relative z-20 flex shadow-md', props.className)}
        color="default"
        sx={{
          background: (theme) =>
            `linear-gradient(to right, ${rgba(theme.palette.primary.dark, 1)}, 
            ${rgba(theme.palette.primary.light, 0.5)}, ${rgba(theme.palette.primary.dark, 1)})`,
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        }}
        position="static"
      >
        <Toolbar className="min-h-48 p-0 md:min-h-64">
          <div className="flex flex-1 px-16">
            {config.navbar.display && config.navbar.position === 'left' && (
              <>
                <Hidden lgDown>
                  {(config.navbar.style === 'style-3' || config.navbar.style === 'style-3-dense') && (
                    <NavbarToggleButton className="mx-0 h-40 w-40 p-0" />
                  )}

                  {config.navbar.style === 'style-1' && !navbar.open && (
                    <NavbarToggleButton className="mx-0 h-40 w-40 p-0" />
                  )}
                </Hidden>

                <Hidden lgUp>
                  <NavbarToggleButton className="mx-0 h-40 w-40 p-0 sm:mx-8" />
                </Hidden>
              </>
            )}

            <Hidden lgDown>
              <NavigationShortcuts />
            </Hidden>
          </div>

          <div className="flex h-full items-center overflow-x-auto px-8">
            <LanguageSwitcher />

            <AdjustFontSize />

            <FullScreenToggle />

            <NavigationSearch />

            {user && (
              <Hidden lgUp>
                <ChatPanelToggleButton />
              </Hidden>
            )}
            <QuickPanelToggleButton />
            {user && <NotificationPanelToggleButton />}
            {user && <ProfilePicker />}
            {user && <UserMenu />}
            {!user && (
              <>
                <Button
                  className="px-16"
                  endIcon={<ReddalSvgIcon size={20}>heroicons-outline:login</ReddalSvgIcon>}
                  onClick={navigateToLogin}
                >
                  Login / Register
                </Button>
              </>
            )}
          </div>

          {config.navbar.display && config.navbar.position === 'right' && (
            <>
              <Hidden lgDown>{!navbar.open && <NavbarToggleButton className="mx-0 h-40 w-40 p-0" />}</Hidden>

              <Hidden lgUp>
                <NavbarToggleButton className="mx-0 h-40 w-40 p-0 sm:mx-8" />
              </Hidden>
            </>
          )}
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  )
}

export default memo(ToolbarLayout1)
