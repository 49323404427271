import _ from '@lodash'
import { useEffect, useRef, useState } from 'react'

function useDebounce(func: FixMe, wait: FixMe, options: FixMe) {
  return useRef(_.debounce(func, wait, options)).current
}

export default useDebounce
export function useDebounceValue(value: FixMe, delay: FixMe) {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])

  return debouncedValue
}
