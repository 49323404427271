import { lazy } from 'react'

const UserEditPageLayout = lazy(() => import('./UserEditPageLayout'))
const UserEditPage = lazy(() => import('./UserEditPage'))

// FALLBACK TO DOCUMENTATION PAGES ON WEBAPP
const UserEditConfig = {
  settings: {
    layout: {
      config: {},
    },
  },

  routes: [
    {
      path: 'user-edit',
      auth: null,
      element: <UserEditPageLayout />,
      children: [
        {
          auth: null,
          index: true,
          element: <UserEditPage />,
        },
      ],
    },
  ],
}

export default UserEditConfig
