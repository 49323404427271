import Flag from '@components/Flag'
import { LadderModel } from '@models/ladder'
import { UserModel } from '@models/user'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { joinLadder, leaveLadder } from '@store/ladders/laddersState'
import { Button } from 'antd'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { useCallback } from 'react'
import { Link, useNavigate } from 'react-router-dom'

dayjs.extend(localizedFormat)

const TournamentInfo = () => {
  // const user = useAppSelector(selectUser)
  // const navigate = useNavigate()
  // const dispatch = useAppDispatch()
  // const navigateToCreateTeam = useCallback(() => {
  //   return navigate('/create-team')
  // }, [])
  // // const getCurrentTeamLadder = useCallback(() => {
  // //   if (ladder.teams.length > 0) {
  // //     return ladder.teams.find(t => t.team._id === user.activeProfile?.team._id)
  // //   }
  // //   return null
  // // }, [ladder, user])
  // const dispatchJoinLadder = () => {
  //   if (user?.activeProfile) {
  //     dispatch(joinLadder({ ladderId: ladder.refId, teamId: user?.activeProfile!.team._id }))
  //   }
  // }
  // const dispatchLeaveLadder = useCallback(() => {
  //   const currentTeamLadder = ladder.teams.find((t) => t.team._id === user?.activeProfile?.team._id)
  //   if (currentTeamLadder) {
  //     dispatch(leaveLadder(currentTeamLadder.refId))
  //   }
  // }, [ladder])
  //
  // const renderJoinButton = () => {
  //   if (!user) return null
  //   if (!user.activeProfile) {
  //     return (
  //       <>
  //         <Button type="ghost" size="large" className="text-center " onClick={navigateToCreateTeam}>
  //           <strong>Create or Join a Team</strong>
  //         </Button>
  //         <div className="text-gray-6 mt-2 text-left">You need to be Owner or Admin of a team to Join a ladder</div>
  //       </>
  //     )
  //   }
  //   if (!ladder.teams.find((team) => team.team._id === user.activeProfile?.team._id)) {
  //     const allowedRole = ['owner', 'admin'].includes(user.activeProfile.role)
  //     const isSingle = ladder.minPlayers === 1 && ladder.maxPlayers === 1
  //     if (allowedRole && (isSingle ? user.activeProfile.team.single : !user.activeProfile.team.single)) {
  //       return (
  //         <Button type="primary" size="large" className="w-100 text-center" onClick={dispatchJoinLadder}>
  //           <strong>JOIN THIS LADDER</strong>
  //         </Button>
  //       )
  //     }
  //     return (
  //       <>
  //         <Button type="ghost" size="large" className="text-center " onClick={navigateToCreateTeam}>
  //           <strong>Create a Team</strong>
  //         </Button>
  //         <div className="text-gray-6 mt-2 text-left">
  //           You need to be Owner or Admin of a team to Join a ladder and the team needs to have a minimum of{' '}
  //           {ladder.minPlayers} players
  //         </div>
  //       </>
  //     )
  //   }
  //   if (user.activeProfile?.role === 'owner' || user.activeProfile?.role === 'admin') {
  //     return (
  //       <Button size="large" className="w-100 text-center" onClick={dispatchLeaveLadder}>
  //         <strong>Leave ladder</strong>
  //       </Button>
  //     )
  //   }
  //   return null
  // }

  return (
    <div className="card">
      {/*<div className="card-body">*/}
      {/*  <div className="d-flex flex-column align-items-center flex-wrap">*/}
      {/*    <div className="mr-auto">*/}
      {/*      <p className="text-uppercase text-dark font-weight-bold mb-3" />*/}
      {/*    </div>*/}
      {/*    <div>{ladder.locked ? 'CLOSED' : 'ACTIVE'}</div>*/}
      {/*    <div className="kit__utils__avatar kit__utils__avatar--size64 mb-3">*/}
      {/*      <img src={ladder.game?.logo} alt={ladder.game?.name} />*/}
      {/*    </div>*/}
      {/*    <div className="text-center">*/}
      {/*      <div className="text-dark font-weight-bold font-size-18">{ladder.game?.name}</div>*/}
      {/*      <div className="text-uppercase font-size-12 mb-3">{ladder.name}</div>*/}
      {/*      {renderJoinButton()}*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*  <div className="table-responsive mt-3">*/}
      {/*    <table className="table-borderless mb-0 table">*/}
      {/*      <tbody>*/}
      {/*        <tr className="text-gray-6">*/}
      {/*          <td className="pb-0 pl-0">Supervisors</td>*/}
      {/*        </tr>*/}
      {/*        {ladder.supervisor?.map((s) => (*/}
      {/*          <tr key={s._id}>*/}
      {/*            <td className="d-flex p-0 pt-2">*/}
      {/*              <Flag country={s.country} />*/}
      {/*              <Link to={`/player/${s.refId}`} className="kit__utils__link ml-1">*/}
      {/*                {s.username}*/}
      {/*              </Link>*/}
      {/*            </td>*/}
      {/*          </tr>*/}
      {/*        ))}*/}
      {/*        <tr>*/}
      {/*          <td className="text-gray-6 pl-0">Total teams</td>*/}
      {/*          <td className="text-dark pr-0 text-right">{ladder.teams.length}</td>*/}
      {/*        </tr>*/}
      {/*        <tr>*/}
      {/*          <td className="text-gray-6 pl-0">Active teams</td>*/}
      {/*          <td className="text-dark pr-0 text-right">{ladder.teams.length}</td>*/}
      {/*        </tr>*/}
      {/*        {ladder.guids.length > 0 && (*/}
      {/*          <tr>*/}
      {/*            <td className="text-gray-6 pl-0">GUID Required</td>*/}
      {/*            <td className="text-dark pr-0 text-right">{ladder.guids[0]?.label}</td>*/}
      {/*          </tr>*/}
      {/*        )}*/}
      {/*        <tr>*/}
      {/*          <td className="text-gray-6 pl-0">Ladder start</td>*/}
      {/*          <td className="text-dark pr-0 text-right">{dayjs(ladder.createdAt).format('LL')}</td>*/}
      {/*        </tr>*/}
      {/*        /!*<tr>*!/*/}
      {/*        /!*  <td className="text-gray-6 pl-0">Rank 1 Record</td>*!/*/}
      {/*        /!*  <td className="pr-0 text-right text-dark">{ladder.ladderRecord}</td>*!/*/}
      {/*        /!*</tr>*!/*/}
      {/*        /!*<tr>*!/*/}
      {/*        /!*  <td className="text-gray-6 pl-0">Record holder</td>*!/*/}
      {/*        /!*  <td className="pr-0 text-right text-dark align-items-center">*!/*/}
      {/*        /!*    {ladder.recordHolder && (*!/*/}
      {/*        /!*      <>*!/*/}
      {/*        /!*        <Flag country={ladder.recordHolder.country} />*!/*/}
      {/*        /!*        <Link to={`/team/${ladder.recordHolder.refId}`} className="kit__utils__link ml-1">*!/*/}
      {/*        /!*          {ladder.recordHolder.name}*!/*/}
      {/*        /!*        </Link>*!/*/}
      {/*        /!*      </>*!/*/}
      {/*        /!*    )}*!/*/}
      {/*        /!*  </td>*!/*/}
      {/*        /!*</tr>*!/*/}
      {/*      </tbody>*/}
      {/*    </table>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  )
}

export default TournamentInfo
