// eslint-disable-next-line max-classes-per-file,@typescript-eslint/ban-ts-comment
// @ts-nocheck
/* tslint:disable */
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { apiClient } from 'services/api'

import { closeChatPanel } from './stateSlice'

export const getContacts = createAsyncThunk('chatPanel/contacts/getContacts', async (params) => {
  const response = await apiClient.get('/api/chat/contacts', { params })
  return await response.data
})

const contactsAdapter = createEntityAdapter({})

export const { selectAll: selectContacts, selectById: selectContactById } = contactsAdapter.getSelectors(
  (state) => state.chatPanel.contacts,
)

const contactsSlice = createSlice({
  name: 'chatPanel/contacts',
  initialState: contactsAdapter.getInitialState({
    selectedContactId: null,
  }),
  reducers: {
    setSelectedContactId: (state, action) => {
      state.selectedContactId = action.payload
    },
    removeSelectedContactId: (state, action) => {
      state.selectedContactId = null
    },
  },
  extraReducers: {
    [getContacts.fulfilled]: contactsAdapter.setAll,
    [closeChatPanel]: (state, action) => {
      state.selectedContactId = null
    },
  },
})

export const { setSelectedContactId, removeSelectedContactId } = contactsSlice.actions

export const selectSelectedContactId = ({ chatPanel }) => chatPanel.contacts.selectedContactId

export default contactsSlice.reducer
