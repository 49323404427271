import { Card, CardContent, CardHeader, Divider, SxProps, Typography } from '@mui/material'
// material-ui
import { useTheme } from '@mui/material/styles'
import React, { ReactNode, Ref } from 'react'

interface SubCardProps {
  children: ReactNode | string | null
  content?: boolean
  className?: string
  contentClass?: string
  darkTitle?: boolean
  secondary?: ReactNode | string
  sx?: SxProps
  contentSX?: SxProps
  title?: ReactNode | string
}

// ==============================|| CUSTOM SUB CARD ||============================== //

export const SubCard = React.forwardRef(
  (
    {
      children,
      className,
      content,
      contentClass,
      darkTitle,
      secondary,
      sx = {},
      contentSX = {},
      title,
      ...others
    }: SubCardProps,
    ref: Ref<HTMLDivElement>,
  ) => {
    const theme = useTheme()

    return (
      <Card
        ref={ref}
        sx={{
          border: '1px solid',
          borderColor: theme.palette.mode === 'dark' ? theme.palette.background.default + 15 : theme.palette.grey[200],
          ':hover': {
            boxShadow:
              theme.palette.mode === 'dark' ? '0 2px 14px 0 rgb(33 150 243 / 10%)' : '0 2px 14px 0 rgb(32 40 45 / 8%)',
          },
          ...sx,
        }}
        {...others}
      >
        {/* card header and action */}
        {!darkTitle && title && (
          <CardHeader sx={{ p: 2.5 }} title={<Typography variant={'xl'}>{title}</Typography>} action={secondary} />
        )}
        {darkTitle && title && (
          <CardHeader sx={{ p: 2.5 }} title={<Typography variant="md">{title}</Typography>} action={secondary} />
        )}

        {/* content & header divider */}
        {title && (
          <Divider
            sx={{
              opacity: 1,
              borderColor: theme.palette.mode === 'dark' ? theme.palette.divider + 15 : theme.palette.grey[200],
            }}
          />
        )}

        {/* card content */}
        {content && (
          <CardContent sx={{ p: 2.5, ...contentSX }} className={contentClass || ''}>
            {children}
          </CardContent>
        )}
        {!content && children}
      </Card>
    )
  },
)
