import createCache, { Options } from '@emotion/cache'
import { CacheProvider } from '@emotion/react'
import { usePlatformSettings } from '@hooks/usePlatformSettings'
import { useUser } from '@hooks/useUser'
import BrowserRouter from '@reddal/core/BrowserRouter'
import ReddalAuthorization from '@reddal/core/ReddalAuthorization'
import ReddalLayout from '@reddal/core/ReddalLayout'
import ReddalTheme from '@reddal/core/ReddalTheme'
import { useAppSelector } from '@store/hooks'
import { selectCurrentLanguageDirection } from '@store/i18nSlice'
import { selectMainTheme } from '@store/reddal/settingsSlice'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import settingsConfig from 'configs/settingsConfig'
import { AuthProvider } from 'context/AuthContext'
import themeLayouts from 'layouts/themeLayouts'
import { SnackbarProvider } from 'notistack'
import rtlPlugin from 'stylis-plugin-rtl'

import withAppProviders from './withAppProviders'

// import Counter from './features/counter/Counter'

const emotionCacheOptions = {
  rtl: {
    key: 'muirtl',
    stylisPlugins: [rtlPlugin],
    insertionPoint: document.getElementById('emotion-insertion-point'),
  },
  ltr: {
    key: 'muiltr',
    stylisPlugins: [],
    insertionPoint: document.getElementById('emotion-insertion-point'),
  },
}

function App() {
  const { user } = useUser()
  const langDirection = useAppSelector(selectCurrentLanguageDirection)
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const cacheLangDirection: Options = emotionCacheOptions[langDirection]
  const mainTheme = useAppSelector(selectMainTheme)
  usePlatformSettings()

  return (
    <CacheProvider value={createCache(cacheLangDirection)}>
      <ReactQueryDevtools initialIsOpen={false} />
      <ReddalTheme theme={mainTheme} direction={langDirection}>
        {/*// AUTHENTICATION*/}
        <AuthProvider>
          <BrowserRouter>
            <ReddalAuthorization userRole={user?.roles} loginRedirectUrl={settingsConfig.loginRedirectUrl}>
              <SnackbarProvider
                maxSnack={5}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                classes={{
                  containerRoot: 'bottom-0 right-0 mb-52 md:mb-68 mr-8 lg:mr-80 z-99',
                }}
              >
                <ReddalLayout layouts={themeLayouts} />
              </SnackbarProvider>
            </ReddalAuthorization>
          </BrowserRouter>
        </AuthProvider>
      </ReddalTheme>
    </CacheProvider>
  )
}

export default withAppProviders(App)
