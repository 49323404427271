import { lazy } from 'react'

const CreateTeamPageLayout = lazy(() => import('./CreateTeamPageLayout'))
const CreateTeamPage = lazy(() => import('./CreateTeamPage'))

// FALLBACK TO DOCUMENTATION PAGES ON WEBAPP
const CreateTeamConfig = {
  settings: {
    layout: {
      config: {},
    },
  },

  routes: [
    // {
    //   path: 'documentation',
    //   element: <Navigate to="/documentation/components" />,
    // },
    {
      path: 'create-team',
      auth: null,
      element: <CreateTeamPageLayout />,
      children: [
        {
          auth: null,
          index: true,
          element: <CreateTeamPage />,
        },
      ],
    },
  ],
}

export default CreateTeamConfig
