import { useUser } from '@hooks/useUser'
import { getMenu } from '@services/api/platform/menu'
import { useAppDispatch } from '@store/hooks'
import { getSliceMenu } from '@store/reddal/navigationSlice'
import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'

export const usePlatformSettings = () => {
  const dispatch = useAppDispatch()
  const { data: menu, refetch } = useQuery({ queryKey: ['platformSettings'], queryFn: getMenu, staleTime: 0 })
  const { user } = useUser()
  useEffect(() => {
    if (menu) {
      dispatch(getSliceMenu())
    }
  }, [menu, user])

  useEffect(() => {
    refetch().finally()
  }, [user])
  return { menu }
}
