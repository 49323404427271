import { TournamentModel } from '@models/tournament'
import { UpdateTournamentPayload } from '@store/administration/tournament/tournamentState'
import { apiClient } from 'services/api'

export async function getAllTournaments(): Promise<TournamentModel[]> {
  return apiClient
    .get('/tournaments')
    .then((response) => {
      if (response) {
        return response.data
      }
      throw new Error('Error while fetching tournaments')
    })
    .catch((err) => {
      throw err
    })
}

export async function getTournament(refId: string): Promise<TournamentModel> {
  return apiClient
    .get(`/tournaments/${refId}`)
    .then((response) => {
      if (response) {
        return response.data
      }
      throw new Error('Error while fetching tournament')
    })
    .catch((err) => {
      throw err
    })
}

export async function updateTournament(tournament: UpdateTournamentPayload): Promise<TournamentModel> {
  return apiClient
    .patch(`/tournaments/${tournament.refId}`, tournament)
    .then((response) => {
      if (response) {
        return response.data
      }
      throw new Error('Error while updating tournament')
    })
    .catch((err) => {
      throw err
    })
}

export async function createTournament(tournament: FixMe): Promise<TournamentModel> {
  return apiClient
    .post(`/tournaments/create`, tournament)
    .then((response) => {
      if (response) {
        return response.data
      }
      throw new Error('Error while creating tournament')
    })
    .catch((err) => {
      throw err
    })
}

export async function deleteTournament(tournament: TournamentModel): Promise<TournamentModel> {
  return apiClient
    .delete(`/tournaments/${tournament.refId}`)
    .then((response) => {
      if (response) {
        return response.data
      }
      throw new Error('Error while creating tournament')
    })
    .catch((err) => {
      throw err
    })
}
