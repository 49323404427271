import { UserModel } from '@models/user'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface UsersState {
  users: UserModel[]
  user: UserModel | null
  isLoading: boolean
  error: FixMe
}
const initialState: UsersState = {
  isLoading: false,
  user: null,
  users: [],
  error: null,
}

export interface GetUserPayload {
  refId: string
}

export interface UpdateUserPayload {
  refId: string
}

const usersState = createSlice({
  name: 'adminGames',
  initialState,
  reducers: {
    fetchUsers: (state) => {
      state.isLoading = true
    },
    fetchUsersSuccess: (state, action) => {
      state.isLoading = false
      state.users = action.payload
    },
    fetchUsersFailed: (state, action) => {
      state.isLoading = false
      state.error = action.payload
    },
    fetchUser: (state, _action: PayloadAction<GetUserPayload>) => {
      state.isLoading = true
    },
    fetchUserSuccess: (state, action) => {
      state.isLoading = false
      state.user = action.payload
    },
    fetchUserFailed: (state, action) => {
      state.isLoading = false
      state.error = action.payload
    },
    updateUser: (state, _action: PayloadAction<UpdateUserPayload>) => {
      state.isLoading = true
    },
    updateUserSuccess: (state, action) => {
      state.isLoading = false
      state.user = action.payload
    },
    updateUserFailed: (state, action) => {
      state.isLoading = false
      state.error = action.payload
    },
  },
})

export const {
  fetchUsers,
  fetchUsersSuccess,
  fetchUsersFailed,
  fetchUser,
  fetchUserSuccess,
  fetchUserFailed,
  updateUser,
} = usersState.actions

export default usersState.reducer

export const adminUsersActions = usersState.actions
