// eslint-disable-next-line max-classes-per-file,@typescript-eslint/ban-ts-comment
// @ts-nocheck
/* tslint:disable */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiClient } from 'services/api'

export const getUserData = createAsyncThunk('chatPanel/user/getUserData', async () => {
  const response = await apiClient.get('/api/chat/user')

  const data = await response.data

  return data
})

export const updateUserData = createAsyncThunk('chatPanel/user/updateUserData', async (newData) => {
  const response = await apiClient.post('/api/chat/user', newData)

  const data = await response.data

  return data
})

const userSlice = createSlice({
  name: 'chatPanel/user',
  initialState: null,
  extraReducers: {
    [getUserData.fulfilled]: (state, action) => action.payload,
    [updateUserData.fulfilled]: (state, action) => action.payload,
  },
})

export const { updateUserChatList } = userSlice.actions

export const selectUser = ({ chatPanel }) => chatPanel.user

export default userSlice.reducer
