import { login } from '@services/api/auth'
import { useQuery } from '@tanstack/react-query'
import { useCallback, useMemo } from 'react'

export const useProfiles = () => {
  const { data: userData } = useQuery({ queryKey: ['user'], queryFn: login })

  const admin = useMemo(() => {
    return userData?.profiles?.find((profile) => profile.role === 'admin' || profile.role === 'owner')
  }, [userData])

  const owner = useMemo(() => {
    return userData?.profiles?.find((profile) => profile.role === 'owner')
  }, [userData])

  const teams = useMemo(() => {
    return userData?.profiles?.map((profile) => profile.team)
  }, [userData])

  const isAdmin = useCallback(
    (teamId: string) => {
      if (userData && userData.profiles && teamId) {
        // Check if any of the user's profiles have admin or owner role for the given teamId
        return !!userData.profiles.find((profile) => {
          return (profile.role === 'admin' || profile.role === 'owner') && profile.team && profile.team._id === teamId
        })
      }
      return false // Return false if no user data or teamId is provided
    },
    [userData],
  )

  return {
    activeProfile: userData?.activeProfile,
    adminProfiles: admin,
    isAdmin: isAdmin,
    ownerProfiles: owner,
    profiles: userData?.profiles,
    teams: teams,
    user: userData,
  }
}
