import { createSlice } from '@reduxjs/toolkit'

interface RolesState {
  roles: FixMe[]
  isLoading: boolean
  error: FixMe
}
const initialState: RolesState = {
  isLoading: false,
  roles: [],
  error: null,
}

const rolesState = createSlice({
  name: 'rolesState',
  initialState,
  reducers: {
    fetchRoles: (state) => {
      state.isLoading = true
    },
    fetchRolesSuccess: (state, action) => {
      state.isLoading = false
      state.roles = action.payload
    },
    fetchRolesFailed: (state, action) => {
      state.isLoading = false
      state.roles = []
      state.error = action.payload
    },
  },
})

export const { fetchRoles, fetchRolesSuccess, fetchRolesFailed } = rolesState.actions

export default rolesState.reducer

export const rolesActions = rolesState.actions
