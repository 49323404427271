import _ from '@lodash'
import Divider from '@mui/material/Divider'
import GlobalStyles from '@mui/material/GlobalStyles'
import PropTypes from 'prop-types'
import { memo } from 'react'

import ReddalNavHorizontalLayout1 from './horizontal/ReddalNavHorizontalLayout1'
import ReddalNavHorizontalCollapse from './horizontal/types/ReddalNavHorizontalCollapse'
import ReddalNavHorizontalGroup from './horizontal/types/ReddalNavHorizontalGroup'
import ReddalNavHorizontalItem from './horizontal/types/ReddalNavHorizontalItem'
import ReddalNavHorizontalLink from './horizontal/types/ReddalNavHorizontalLink'
import { registerComponent } from './ReddalNavItem'
import ReddalNavVerticalLayout1 from './vertical/ReddalNavVerticalLayout1'
import ReddalNavVerticalLayout2 from './vertical/ReddalNavVerticalLayout2'
import ReddalNavVerticalCollapse from './vertical/types/ReddalNavVerticalCollapse'
import ReddalNavVerticalGroup from './vertical/types/ReddalNavVerticalGroup'
import ReddalNavVerticalItem from './vertical/types/ReddalNavVerticalItem'
import ReddalNavVerticalLink from './vertical/types/ReddalNavVerticalLink'

const inputGlobalStyles = (
  <GlobalStyles
    styles={() => ({
      '.popper-navigation-list': {
        '& .reddal-list-item': {
          padding: '8px 12px 8px 12px',
          height: 40,
          minHeight: 40,
          '& .reddal-list-item-text': {
            padding: '0 0 0 8px',
          },
        },
        '&.dense': {
          '& .reddal-list-item': {
            minHeight: 32,
            height: 32,
            '& .reddal-list-item-text': {
              padding: '0 0 0 8px',
            },
          },
        },
      },
    })}
  />
)

/*
Register Reddal Navigation Components
 */
registerComponent('vertical-group', ReddalNavVerticalGroup)
registerComponent('vertical-collapse', ReddalNavVerticalCollapse)
registerComponent('vertical-item', ReddalNavVerticalItem)
registerComponent('vertical-link', ReddalNavVerticalLink)
registerComponent('horizontal-group', ReddalNavHorizontalGroup)
registerComponent('horizontal-collapse', ReddalNavHorizontalCollapse)
registerComponent('horizontal-item', ReddalNavHorizontalItem)
registerComponent('horizontal-link', ReddalNavHorizontalLink)
registerComponent('vertical-divider', () => <Divider className="my-16" />)
registerComponent('horizontal-divider', () => <Divider className="my-16" />)

function ReddalNavigation(props: FixMe) {
  const options = _.pick(props, [
    'navigation',
    'layout',
    'active',
    'dense',
    'className',
    'onItemClick',
    'firstLevel',
    'selectedId',
  ])
  if (props.navigation.length > 0) {
    return (
      <>
        {inputGlobalStyles}
        {props.layout === 'horizontal' && <ReddalNavHorizontalLayout1 {...options} />}
        {props.layout === 'vertical' && <ReddalNavVerticalLayout1 {...options} />}
        {props.layout === 'vertical-2' && <ReddalNavVerticalLayout2 {...options} />}
      </>
    )
  }
  return null
}

ReddalNavigation.propTypes = {
  navigation: PropTypes.array.isRequired,
  className: PropTypes.string,
}

ReddalNavigation.defaultProps = {
  layout: 'vertical',
}

export default memo(ReddalNavigation)
