import { lazy } from 'react'
import { Navigate } from 'react-router-dom'

import { TeamPermission } from './TeamPermission'

const TeamPageLayout = lazy(() => import('./TeamPageLayout'))
const TeamRoster = lazy(() => import('./pages/TeamRoster'))
const TeamAdministration = lazy(() => import('./pages/TeamAdministration'))

// FALLBACK TO DOCUMENTATION PAGES ON WEBAPP
const TeamConfig = {
  settings: {
    layout: {
      config: {},
    },
  },

  routes: [
    {
      path: 'teams',
      auth: null,
      children: [
        {
          auth: null,
          index: true,
          element: <Navigate to="/news" />,
        },
        {
          path: 'create-team',
          children: [
            {
              auth: null,
              index: true,
              element: <div>new-team</div>,
            },
            {
              path: 'wtf',
              element: <div>wtf</div>,
            },
          ],
        },
      ],
    },
    {
      path: 'team',
      auth: null,
      element: <TeamPageLayout />,
      children: [
        {
          path: ':teamId',
          auth: null,
          children: [
            {
              index: true,
              element: <TeamRoster />,
            },
            {
              path: 'matches',
              auth: null,
              element: <div>TODO: We should add a table with the matches</div>,
            },
            {
              path: 'awards',
              auth: null,
              element: <div>TODO: We should add a table with the awards</div>,
            },
            {
              path: 'challenges',
              auth: null,
              element: <div>TODO: We should add a table with the challenges</div>,
            },
            {
              access: 'isPlayer',
              path: 'administration',
              element: (
                <TeamPermission>
                  <TeamAdministration />
                </TeamPermission>
              ),
            },
            {
              path: '*',
              auth: null,
              element: <Navigate to="" relative="route" />,
            },
          ],
        },
      ],
    },
  ],
}

export default TeamConfig
