import { getPlayer } from '@services/api/players'
import { playersActions } from '@store/players/playersState'
import { showMessage } from '@store/reddal/messageSlice'
import { call, put, select, takeLatest } from 'redux-saga/effects'

function* GET_PLAYER(action: FixMe): FixMe {
  try {
    const currentUser = yield select((state) => state.auth.user)
    if (action.payload === currentUser.refId) {
      yield put(playersActions.getPlayerSuccess(currentUser))
      return
    }
    const playerData = yield call(getPlayer, action.payload)
    yield put(playersActions.getPlayerSuccess(playerData))
  } catch (e) {
    yield put(
      showMessage({
        variant: 'error',
        message: 'Error fetching player',
        autoHideDuration: 2000,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      }),
    )
    yield put(playersActions.getPlayerFailed(new Error('Error getting player')))
  }
}

export default function* playersSaga() {
  yield takeLatest(playersActions.getPlayer.type, GET_PLAYER)
}
