import { useUser } from '@hooks/useUser'
import { getNotifications } from '@services/api/notifications'
import { useQuery } from '@tanstack/react-query'

export const useNotifications = () => {
  const { user } = useUser()
  const { data: notifications, isLoading: loadingNotifications } = useQuery({
    queryKey: ['user', 'notifications'],
    queryFn: getNotifications,
    enabled: !!user,
    placeholderData: [],
  })

  return {
    notifications,
    loadingNotifications,
  }
}
