import { lazy } from 'react'
import { Navigate } from 'react-router-dom'

const TournamentsList = lazy(() => import('./tournaments-list/TournamentsList'))
const TournamentsAdd = lazy(() => import('./TournamentsAdd'))
const TournamentsEdit = lazy(() => import('./TournamentsEdit'))

const TournamentRoutes = [
  {
    path: 'tournaments',
    children: [
      {
        index: true,
        element: <TournamentsList />,
      },
      {
        path: 'add',
        element: <TournamentsAdd />,
      },
      {
        path: 'edit/:tournamentId',
        element: <TournamentsEdit />,
      },
      {
        path: '*',
        element: <Navigate to="" />,
      },
    ],
  },
]

export default TournamentRoutes
