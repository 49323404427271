import { GameModel } from '@models/game'
import { createSlice } from '@reduxjs/toolkit'

interface GameState {
  games: GameModel[]
  isLoading: boolean
  error: FixMe
}
const initialState: GameState = {
  isLoading: false,
  games: [],
  error: null,
}

const gamesState = createSlice({
  name: 'adminGames',
  initialState,
  reducers: {
    fetchGames: (state) => {
      state.isLoading = true
    },
    fetchGamesSuccess: (state, action) => {
      state.isLoading = false
      state.games = action.payload
    },
    fetchGamesFailed: (state, action) => {
      state.isLoading = false
      state.games = []
      state.error = action.payload
    },
  },
})

export const { fetchGames, fetchGamesSuccess, fetchGamesFailed } = gamesState.actions

export default gamesState.reducer

export const adminGamesActions = gamesState.actions
