import Button from '@mui/material/Button'
import ReddalSvgIcon from '@reddal/core/ReddalSvgIcon'
import clsx from 'clsx'

function PurchaseButton({ className }: FixMe) {
  return (
    <Button
      component="a"
      href="https://1.envato.market/zDGL6"
      target="_blank"
      rel="noreferrer noopener"
      role="button"
      className={clsx('', className)}
      variant="contained"
      color="secondary"
      startIcon={<ReddalSvgIcon size={16}>heroicons-outline:shopping-cart</ReddalSvgIcon>}
    >
      Purchase REDDAL React
    </Button>
  )
}

export default PurchaseButton
