import { combineReducers } from '@reduxjs/toolkit'

import dialog from './dialogSlice'
import message from './messageSlice'
import navbar from './navbarSlice'
import navigation from './navigationSlice'
import settings from './settingsSlice'

const reddalReducers = combineReducers({
  dialog,
  message,
  navbar,
  navigation,
  settings,
})

export default reddalReducers
