import { UserCredential } from '@firebase/auth'
import { UploadEntity, UploadType } from '@models/upload'
import { UserModel } from '@models/user'
import { queryClient } from '@services/api'
import { uploadImage } from '@services/api/utils'
import { history } from '@store'
import { authActions, forgotPasswordFailed, forgotPasswordSuccess } from '@store/auth/authState'
import { showMessage } from '@store/reddal/messageSlice'
import { notification } from 'antd'
import {
  Auth,
  createUserWithEmailAndPassword,
  getAuth,
  sendPasswordResetEmail,
  signInWithCustomToken,
  signOut,
} from 'firebase/auth'
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { addUserGuid, getUser, login, register, updateUser } from 'services/api/auth'

function* LOGIN(): FixMe {
  try {
    console.log('login')
    const userData = yield call(login)
    console.log('LOGIN() userData::::::::', userData)
    yield put(authActions.userLoginSuccess(userData))
    history.push('/')
  } catch (e) {
    yield put(
      showMessage({
        variant: 'error',
        message: 'Error logging in',
        autoHideDuration: 2000,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      }),
    )
    yield put(authActions.userLoginFailed(new Error('Error logging in')))
  }
}

function* DISCORD_LOGIN(action: ReturnType<typeof authActions.discordLogin>) {
  const token = action.payload as string
  try {
    console.log('DISCORD_LOGIN() token::::::::', token)
    // const jwtPayload = jwt.verify(token, 'YJMfaksdr8hRS2').toString()
    const auth: Auth = yield call(getAuth)
    console.log('auth', auth)
    const firebaseUser: UserCredential = yield call(signInWithCustomToken, auth, token)
    console.log('firebaseUser', firebaseUser)
    yield put(authActions.userLogin())
    // yield call(() => queryClient.invalidateQueries({ queryKey: ['user'] }))
  } catch (error) {
    console.log('error:::::', error)
    // notification.warning({
    //   message: error.message,
    // })
  }
}

function* LOGOUT(): FixMe {
  try {
    const auth = yield call(getAuth)
    yield call(signOut, auth)
    // yield call(currentAccount)
    // yield call(() => queryClient.clear())
    yield call(() => queryClient.removeQueries({ predicate: (query) => query.queryKey.includes('user') }))

    yield put(authActions.userLogoutSuccess())
    history.push('/')
  } catch (e) {
    // history.push('/')
    yield put(authActions.userLogoutFailed(new Error('Error logging in')))
  }
}

function* REGISTER(action: ReturnType<typeof authActions.signUp>) {
  const { email, password, username } = action.payload
  try {
    const auth: Auth = yield call(getAuth)
    yield call(createUserWithEmailAndPassword, auth, email, password)
    const user: UserModel = yield call(register, email, username)
    yield put(authActions.signUpSuccess(user))
    history.push('/')
  } catch (e) {
    yield put(authActions.signUpFailed(new Error('Error signing up')))
  }
}

function* FORGOT_PASSWORD(action: ReturnType<typeof authActions.forgotPassword>) {
  try {
    const email = action.payload
    const auth: Auth = yield call(getAuth)
    yield call(sendPasswordResetEmail, auth, email)
    yield put(forgotPasswordSuccess())
    history.push('/reset-sent')
  } catch (error) {
    if (error instanceof Error) {
      notification.warning({
        message: error.message,
      })
      yield put(forgotPasswordFailed(error.message))
    }
  }
}

function* GET_USER(action: ReturnType<typeof authActions.getUser>) {
  try {
    const refId = action.payload
    const userData: UserModel = yield call(getUser, refId)
    yield put(authActions.getUserSuccess(userData))
  } catch (error) {
    if (error instanceof Error) {
      notification.warning({
        message: error.message,
      })
      yield put(authActions.getUserFailed('Failed to get user'))
    }
  }
}

function* SET_ACTIVE_PROFILE(action: ReturnType<typeof authActions.setActiveProfile>) {
  // Get the active profile from the action payload.
  const activeProfile = action.payload
  try {
    // Call the `updateUser` function with the active profile ID.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    yield call(updateUser, { activeProfile: activeProfile._id })
    yield call(() =>
      queryClient.invalidateQueries({
        queryKey: ['user'],
      }),
    )
    // Dispatch the `setActiveProfileSuccess` action with the active profile.
    yield put(authActions.setActiveProfileSuccess(activeProfile))
  } catch (error) {
    // notification.warning({
    //   message: error.message,
    // })
    // If an error occurs, dispatch the `setActiveProfileFailed` action with an error message.
    yield put(authActions.setActiveProfileFailed('Failed to set Active Profile'))
  }
}

function* ADD_USER_GUID(action: ReturnType<typeof authActions.addUserGuid>) {
  const guid = action.payload
  try {
    // const user = yield select((state) => state.auth.user)
    yield call(addUserGuid, guid)
    const userData: UserModel = yield call(() => queryClient.fetchQuery({ queryKey: ['user'], queryFn: login }))
    yield put(authActions.getUserSuccess(userData))
    yield put(authActions.addUserGuidSuccess())
    notification.success({
      message: 'Game guid added.',
    })
  } catch (error) {
    if (error instanceof Error) {
      notification.warning({
        message: error.message,
      })
      yield put(authActions.addUserGuidFailed(error.message))
    }
    console.log('Unexpected error ADD_USER_GUID', error)
  }
}

function* LOAD_CURRENT_ACCOUNT() {
  try {
    const localFirebaseToken = localStorage.getItem('firebaseToken')
    const firebaseToken: string = yield call(() => getAuth().currentUser?.getIdToken(true))
    if (!firebaseToken && !localFirebaseToken) {
      yield put(authActions.userLogoutSuccess())
    } else {
      const userData: UserModel = yield call(() => queryClient.fetchQuery({ queryKey: ['user'], queryFn: login }))
      if (userData?.refId) {
        yield put(authActions.userLoginSuccess(userData))
      } else {
        yield put(authActions.userLogoutSuccess())
      }
    }
  } catch (error) {
    console.log('error', error)
    console.log('Error loading current account')
    // yield put({
    //   type: .LOAD_CURRENT_ACCOUNT_FAILED.toString(),
    //   payload: {
    //     loading: false,
    //     error: error.message,
    //   },
    // })
    yield put(authActions.userLogoutSuccess())
  }
}

function* UPDATE_USER(action: ReturnType<typeof authActions.updateUser>) {
  const userData = action.payload
  const user = queryClient.getQueryData(['user']) as UserModel

  try {
    if (userData.avatar && typeof userData.avatar !== 'string') {
      const avatar: { file: string } = yield call(
        uploadImage,
        UploadEntity.users,
        UploadType.avatar,
        user.refId,
        userData.avatar,
      )
      userData.avatar = avatar.file
    }
    if (userData.banner && typeof userData.banner !== 'string') {
      const banner: { file: string } = yield call(
        uploadImage,
        UploadEntity.users,
        UploadType.banner,
        user.refId,
        userData.banner,
      )
      userData.banner = banner.file
    }
    delete userData.activeProfile

    const userSuccess: UserModel = yield call(updateUser, userData as Partial<UserModel>)
    yield call(() => queryClient.invalidateQueries({ queryKey: ['user'] }))
    yield call(() => queryClient.invalidateQueries({ queryKey: ['player'] }))
    history.push(`/player/${userSuccess.refId}`)
  } catch (error) {
    if (error instanceof Error) {
      yield put(authActions.updateUserFailed(`Failed to create team: ${error.message}`))
    } else {
      console.log('Unexpected error', error)
    }
  }
}

export default function* authSaga() {
  yield LOAD_CURRENT_ACCOUNT()
  yield takeLatest(authActions.userLogin.type, LOGIN)
  yield takeLatest(authActions.discordLogin.type, DISCORD_LOGIN)
  yield takeLatest(authActions.signUp.type, REGISTER)
  yield takeLatest(authActions.forgotPassword.type, FORGOT_PASSWORD)
  yield takeEvery(authActions.userLogout.type, LOGOUT)
  yield takeEvery(authActions.userLoginFailed.type, LOGOUT)
  yield takeLatest(authActions.setActiveProfile.type, SET_ACTIVE_PROFILE)
  yield takeLatest(authActions.getUser.type, GET_USER)
  yield takeLatest(authActions.addUserGuid.type, ADD_USER_GUID)
  yield takeLatest(authActions.updateUser.type, UPDATE_USER)
}
