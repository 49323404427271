import bronze_ladder from '../assets/images/awards/bronze_ladder.gif'
import bronze_tournament from '../assets/images/awards/bronze_tournament.gif'
import gold_ladder from '../assets/images/awards/gold_ladder.gif'
import gold_tournament from '../assets/images/awards/gold_tournament.gif'
import silver_ladder from '../assets/images/awards/silver_ladder.gif'
import silver_tournament from '../assets/images/awards/silver_tournament.gif'

export default (awardImage: string) => {
  switch (awardImage) {
    case 'gold_ladder':
      return gold_ladder
    case 'silver_ladder':
      return silver_ladder
    case 'bronze_ladder':
      return bronze_ladder
    case 'gold_tournament':
      return gold_tournament
    case 'silver_tournament':
      return silver_tournament
    case 'bronze_tournament':
      return bronze_tournament
    default:
      return ''
  }
}
