import { CommentModel } from './comments'
import { LadderModel } from './ladder'
import { MatchReportModel } from './matchReport'
import { TeamModel } from './team'
import { TeamLadderModel } from './team-ladder'
import { TournamentModel } from './tournament'

export enum CompetitionType {
  'Ladder' = 'Ladder',
  'Tournament' = 'Tournament',
}

export enum MatchStatus {
  UPCOMING = 'UPCOMING',
  PENDING = 'PENDING',
  CONFLICT = 'CONFLICT',
  DONE = 'DONE',
}
export interface MatchModel {
  _id: string
  refId: string
  date: Date
  size: string
  length: string
  room: string
  password: string
  challenger: TeamLadderModel
  challengerTeam: string
  opponent: TeamLadderModel
  opponentTeam: string
  waitingTeam: string | null
  winNumberOne: string | null
  lostNumberOne: string | null
  forfeit: string | null
  noshow: string | null
  isCanceled: boolean | null
  maps: FixMe[]
  comments: CommentModel[]
  competition: LadderModel & TournamentModel & string
  competitionType: CompetitionType
  challengerScore: number | null
  challengerPoints: number
  challengerMatchReport: MatchReportModel
  opponentScore: number | null
  opponentPoints: number
  opponentMatchReport: MatchReportModel
  status: MatchStatus
  count: number
  drawDisabled: boolean
  teams: TeamModel[]
}
