// eslint-disable-next-line max-classes-per-file,@typescript-eslint/ban-ts-comment
// @ts-nocheck
/* tslint:disable */
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import clsx from 'clsx'
import { memo } from 'react'

function SchemePreview({ theme, className, id, onSelect }) {
  const _theme = useTheme()
  const primaryColor = theme.palette.primary[500] ? theme.palette.primary[500] : theme.palette.primary.main
  const primaryColorContrast = theme.palette.primary.contrastText || _theme.palette.getContrastText(primaryColor)
  const secondaryColor = theme.palette.secondary[500] ? theme.palette.secondary[500] : theme.palette.secondary.main
  const secondaryColorContrast = theme.palette.secondary.contrastText || _theme.palette.getContrastText(secondaryColor)
  const backgroundColor = theme.palette.background.default
  const backgroundColorContrast = _theme.palette.getContrastText(theme.palette.background.default)
  const paperColor = theme.palette.background.paper
  const paperColorContrast = _theme.palette.getContrastText(theme.palette.background.paper)

  return (
    <div className={clsx(className, 'mb-8')}>
      <button
        className={clsx(
          'relative w-full cursor-pointer overflow-hidden rounded-6 text-left font-500 shadow transition-shadow hover:shadow-md',
        )}
        style={{
          backgroundColor,
          color: backgroundColorContrast,
        }}
        onClick={() => onSelect(theme)}
        type="button"
      >
        <div
          className="relative h-56 w-full px-8 pt-8"
          style={{
            backgroundColor: primaryColor,
            color: primaryColorContrast,
          }}
        >
          <span className="text-12 opacity-75">Header (Primary)</span>

          <div
            className="absolute right-0 bottom-0 mr-4 -mb-10 flex h-20 w-20 items-center justify-center rounded-full text-10 shadow"
            style={{
              backgroundColor: secondaryColor,
              color: secondaryColorContrast,
            }}
          >
            <span className="opacity-75">S</span>
          </div>
        </div>
        <div className="-mt-24 w-full pr-28 pl-8">
          <div
            className="relative h-96 w-full rounded-4 p-8 shadow"
            style={{
              backgroundColor: paperColor,
              color: paperColorContrast,
            }}
          >
            <span className="text-12 opacity-75">Paper</span>
          </div>
        </div>

        <div className="w-full p-8">
          <span className="text-12 opacity-75">Background</span>
        </div>
      </button>
      <Typography className="mt-12 w-full text-center font-semibold">{id}</Typography>
    </div>
  )
}

function ReddalThemeSchemes(props) {
  const { themes } = props

  return (
    <div>
      <div className="-mx-8 flex w-full flex-wrap">
        {Object.entries(themes)
          .filter(([key]) => !(key === 'mainThemeDark' || key === 'mainThemeLight'))
          .map(([key, val]) => (
            <div key={key} className="w-1/2 p-8">
              <SchemePreview id={key} theme={val} onSelect={() => props?.onSelect(val)} />
            </div>
          ))}
      </div>
    </div>
  )
}

export default memo(ReddalThemeSchemes)
