import { StyledEngineProvider } from '@mui/material/styles'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import routes from 'configs/routesConfig'
import AppContext from 'context/AppContext'
import { ComponentType, PropsWithChildren, useMemo } from 'react'
import { HelmetProvider } from 'react-helmet-async'

type ComponentProps = {
  name?: string
}

/**
 * A Higher Order Component that provides the necessary context providers for the app.
 */
function withAppProviders(Component: ComponentType<ComponentProps>) {
  /**
   * The component that wraps the provided component with the necessary context providers.
   */
  function WithAppProviders(props: PropsWithChildren<ComponentProps>) {
    /**
     * The value to pass to the AppContext provider.
     */
    const val = useMemo(
      () => ({
        routes,
      }),
      [routes],
    )

    return (
      <AppContext.Provider value={val}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <StyledEngineProvider injectFirst>
            <HelmetProvider>
              <Component {...props} />
            </HelmetProvider>
          </StyledEngineProvider>
        </LocalizationProvider>
      </AppContext.Provider>
    )
  }

  return WithAppProviders
}

export default withAppProviders
