// import authRoles from 'configs/authRoles'
import authRoles from 'configs/authRoles'
import { lazy } from 'react'
import { Navigate } from 'react-router-dom'

import GamesRoutes from './games/GamesRoutes'
import GuidsRoutes from './guids/GuidsRoutes'
import LaddersRoutes from './ladders/LaddersRoutes'
import TournamentsRoutes from './tournaments/TournamentsRoutes'
import UsersRoutes from './users/UsersRoutes'

const AdministrationPageLayout = lazy(() => import('./AdministrationPageLayout'))

// FALLBACK TO DOCUMENTATION PAGES ON WEBAPP
const AdministrationConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'administration',
      element: <Navigate to="/administration/settings/general-settings" />,
    },
    {
      path: 'administration',
      auth: authRoles.admin,
      element: <AdministrationPageLayout />,
      children: [
        {
          path: 'settings/general-settings',
          element: <div>General Settings Content</div>,
        },
        ...UsersRoutes,
        ...GuidsRoutes,
        ...GamesRoutes,
        ...TournamentsRoutes,
        ...LaddersRoutes,
      ],
    },
  ],
}

export default AdministrationConfig
