// eslint-disable-next-line max-classes-per-file,@typescript-eslint/ban-ts-comment
// @ts-nocheck
/* tslint:disable */
import ReddalShortcuts from '@reddal/core/ReddalShortcuts'
import { selectUserShortcuts } from '@store/auth/authState'
import { selectFlatNavigation } from '@store/reddal/navigationSlice'
import { useDispatch, useSelector } from 'react-redux'

function NavigationShortcuts(props: FixMe) {
  const { variant, className } = props
  const dispatch = useDispatch()
  const shortcuts = useSelector(selectUserShortcuts) || []
  const navigation = useSelector(selectFlatNavigation)

  function handleShortcutsChange(newShortcuts: FixMe) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // dispatch(updateUserShortcuts(newShortcuts))
  }

  return (
    <ReddalShortcuts
      className={className}
      variant={variant}
      navigation={navigation}
      shortcuts={shortcuts}
      onChange={handleShortcutsChange}
    />
  )
}

export default NavigationShortcuts
