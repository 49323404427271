// eslint-disable-next-line max-classes-per-file,@typescript-eslint/ban-ts-comment
// @ts-nocheck
/* tslint:disable */
import { useNotifications } from '@hooks/notifications/useNotifications'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import Typography from '@mui/material/Typography'
import ReddalScrollbars from '@reddal/core/ReddalScrollbars'
import ReddalSvgIcon from '@reddal/core/ReddalSvgIcon'
import withReducer from '@store/withReducer'
import NotificationTemplate from 'layouts/shared-components/notificationPanel/NotificationTemplate'
import { useSnackbar } from 'notistack'
import { memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import NotificationModel from './model/NotificationModel'
import NotificationCard from './NotificationCard'
import reducer from './store'
import { addNotification, dismissAll, dismissItem, getNotifications } from './store/dataSlice'
import { closeNotificationPanel, selectNotificationPanelState, toggleNotificationPanel } from './store/stateSlice'

const StyledSwipeableDrawer = styled(SwipeableDrawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    backgroundColor: theme.palette.background.default,
    width: 320,
  },
}))

function NotificationPanel(props) {
  const location = useLocation()
  const dispatch = useDispatch()
  const state = useSelector(selectNotificationPanelState)
  const { notifications } = useNotifications()

  console.log('notifications', notifications)

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  useEffect(() => {
    /*
                            Get Notifications from db
                             */
    dispatch(getNotifications())
  }, [dispatch])

  useEffect(() => {
    if (state) {
      dispatch(closeNotificationPanel())
    }
    // eslint-disable-next-line
  }, [location, dispatch])

  function handleClose() {
    dispatch(closeNotificationPanel())
  }

  function handleDismiss(id) {
    dispatch(dismissItem(id))
  }

  function handleDismissAll() {
    dispatch(dismissAll())
  }

  function demoNotification() {
    const item = NotificationModel({ title: 'Great Job! this is awesome.' })

    enqueueSnackbar(item.title, {
      key: item.id,
      // autoHideDuration: 3000,
      content: () => (
        <NotificationTemplate
          item={item}
          onClose={() => {
            closeSnackbar(item.id)
          }}
        />
      ),
    })

    dispatch(addNotification(item))
  }

  return (
    <StyledSwipeableDrawer
      open={state}
      anchor="right"
      onOpen={(ev) => {}}
      onClose={(ev) => dispatch(toggleNotificationPanel())}
      disableSwipeToOpen
    >
      <IconButton className="absolute right-0 top-0 z-999 m-4" onClick={handleClose} size="large">
        <ReddalSvgIcon color="action">heroicons-outline:x</ReddalSvgIcon>
      </IconButton>
      {notifications.length > 0 ? (
        <ReddalScrollbars className="p-16">
          <div className="flex flex-col">
            <div className="mb-36 flex items-end justify-between pt-96">
              <Typography className="text-28 font-semibold leading-none">Notifications</Typography>
              <Typography className="cursor-pointer text-12 underline" color="secondary" onClick={handleDismissAll}>
                dismiss all
              </Typography>
            </div>
            {notifications.map((item) => (
              <NotificationCard key={item?.id} className="mb-16" item={item} onClose={handleDismiss} />
            ))}
          </div>
        </ReddalScrollbars>
      ) : (
        <div className="flex flex-1 items-center justify-center p-16">
          <Typography className="text-center text-24" color="text.secondary">
            There are no notifications for now.
          </Typography>
        </div>
      )}
    </StyledSwipeableDrawer>
  )
}

export default withReducer('notificationPanel', reducer)(memo(NotificationPanel))
