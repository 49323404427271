// eslint-disable-next-line max-classes-per-file,@typescript-eslint/ban-ts-comment
// @ts-nocheck
/* tslint:disable */
import ClickAwayListener from '@mui/material/ClickAwayListener'
import { styled, useTheme } from '@mui/material/styles'
import ReddalNavigation from '@reddal/core/ReddalNavigation'
import ReddalScrollbars from '@reddal/core/ReddalScrollbars'
import useThemeMediaQuery from '@reddal/hooks/useThemeMediaQuery'
import { navbarCloseMobile } from '@store/reddal/navbarSlice'
import { selectNavigation } from '@store/reddal/navigationSlice'
import { selectContrastMainTheme } from '@store/reddal/settingsSlice'
import clsx from 'clsx'
import { rgba } from 'polished'
import { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

const Root = styled('div')(({ theme }) => ({
  background: `linear-gradient(to bottom, ${rgba(theme.palette.primary.dark, 0.99)}, 
            ${rgba(theme.palette.primary.main, 0.98)}, ${rgba(theme.palette.primary.dark, 0.99)})`,
  color: theme.palette.text.primary,
}))

const StyledPanel = styled(ReddalScrollbars)(({ theme, opened }) => ({
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.primary,
  transition: theme.transitions.create(['opacity'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.shortest,
  }),
  opacity: 0,
  pointerEvents: 'none',
  ...(opened && {
    opacity: 1,
    pointerEvents: 'initial',
  }),
}))

function needsToBeOpened(location, item) {
  return location && isUrlInChildren(item, location.pathname)
}

function isUrlInChildren(parent, url) {
  if (!parent.children) {
    return false
  }

  for (let i = 0; i < parent.children.length; i += 1) {
    if (parent.children[i].children) {
      if (isUrlInChildren(parent.children[i], url)) {
        return true
      }
    }
    if (parent.children[i].url === url || url.includes(parent.children[i].url)) {
      return true
    }
  }

  return false
}

function parentNeedsToBeOpened(location, item) {
  return location && isUrlparent(item, location.pathname)
}

function isUrlparent(parent, url) {
  return !!((!parent.children && parent.url === url) || url.includes(parent.url))
}

function NavbarStyle3Content(props) {
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'))
  const navigation = useSelector(selectNavigation)
  const [selectedNavigation, setSelectedNavigation] = useState([])
  const [selectedParent, setSelectedParent] = useState([])
  const [panelOpen, setPanelOpen] = useState(false)
  const theme = useTheme()
  const dispatch = useDispatch()
  const contrastTheme = useSelector(selectContrastMainTheme(theme.palette.background.default))
  const location = useLocation()

  useEffect(() => {
    navigation?.forEach((item) => {
      if (parentNeedsToBeOpened(location, item)) {
        setSelectedParent([item])
      }
      if (needsToBeOpened(location, item)) {
        setSelectedNavigation([item])
      }
    })
  }, [navigation, location])

  function handleParentItemClick(selected) {
    /** if there is no child item do not set/open panel
     */
    if (!selected.children && selectedNavigation[0]?.id !== selected.id) {
      setSelectedParent([selected])
      setPanelOpen(false)
    }
    if (!selected.children) {
      setSelectedNavigation([])
      setSelectedParent([])
      setPanelOpen(false)
      return
    }
    /**
     * If navigation already selected toggle panel visibility
     */
    if (selectedNavigation[0]?.id === selected.id) {
      setPanelOpen(!panelOpen)
    } else {
      /**
       * Set navigation and open panel
       */
      setSelectedParent([])
      setSelectedNavigation([selected])
      setPanelOpen(true)
    }
  }

  function handleChildItemClick(selected) {
    setPanelOpen(false)
    if (isMobile) {
      dispatch(navbarCloseMobile())
    }
  }

  return (
    <ClickAwayListener onClickAway={() => setPanelOpen(false)}>
      <Root className={clsx('flex h-full flex-auto', props.className)}>
        <div id="reddal-navbar-side-panel" className="flex shrink-0 flex-col items-center">
          <img className="my-32 w-44" src="/assets/images/logo/logo.svg" alt="logo" />

          <ReddalScrollbars
            className="flex min-h-0 w-full flex-1 justify-center overflow-y-auto overflow-x-hidden"
            option={{ suppressScrollX: true, wheelPropagation: false }}
          >
            <ReddalNavigation
              className={clsx('navigation')}
              navigation={navigation}
              layout="vertical-2"
              onItemClick={handleParentItemClick}
              firstLevel
              selectedId={selectedNavigation[0]?.id ?? selectedParent[0]?.id}
              dense={props.dense}
            />
          </ReddalScrollbars>
        </div>

        {selectedNavigation.length > 0 && (
          <StyledPanel
            id="reddal-navbar-panel"
            opened={panelOpen}
            className={clsx('overflow-y-auto overflow-x-hidden shadow-5')}
            option={{ suppressScrollX: true, wheelPropagation: false }}
          >
            <ReddalNavigation
              className={clsx('navigation')}
              navigation={selectedNavigation}
              layout="vertical"
              onItemClick={handleChildItemClick}
            />
          </StyledPanel>
        )}
      </Root>
    </ClickAwayListener>
  )
}

export default memo(NavbarStyle3Content)
