import { PlayerModel } from '@models/player'
import { UserModel } from '@models/user'
import { apiClient } from 'services/api'

export async function getPlayer(refId: string): Promise<PlayerModel> {
  return apiClient
    .get(`/player/${refId}`)
    .then((response) => {
      if (response) {
        return response.data
      }
      throw new Error('No response data')
    })
    .catch((err) => {
      throw err
    })
}

export async function searchPlayer(term: string): Promise<PlayerModel[]> {
  return apiClient
    .get(`/search/user?query=${term}`)
    .then((response) => {
      if (response) {
        return response.data
      }
      throw new Error('No users found')
    })
    .catch((err) => {
      throw err
    })
}

export async function searchUser(term: string): Promise<UserModel[]> {
  return apiClient
    .get(`/search/user?query=${term}`)
    .then((response) => {
      if (response) {
        return response.data
      }
      throw new Error('No users found')
    })
    .catch((err) => {
      throw err
    })
}
