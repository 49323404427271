// eslint-disable-next-line max-classes-per-file,@typescript-eslint/ban-ts-comment
// @ts-nocheck
/* tslint:disable */
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import { styled } from '@mui/material/styles'
import withRouter from '@reddal/core/withRouter'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { memo, useMemo } from 'react'

import ReddalSvgIcon from '../../../ReddalSvgIcon'
import ReddalNavBadge from '../../ReddalNavBadge'

const StyledListItem = styled(ListItem)(({ theme }) => ({
  color: theme.palette.text.primary,
  textDecoration: 'none!important',
  minHeight: 48,
  '&.active': {
    backgroundColor: `${theme.palette.secondary.main}!important`,
    color: `${theme.palette.secondary.contrastText}!important`,
    pointerEvents: 'none',
    '& .reddal-list-item-text-primary': {
      color: 'inherit',
    },
    '& .reddal-list-item-icon': {
      color: 'inherit',
    },
  },
  '& .reddal-list-item-icon': {},
  '& .reddal-list-item-text': {
    padding: '0 0 0 16px',
  },
}))

function ReddalNavHorizontalLink(props) {
  const { item } = props

  return useMemo(
    () => (
      <StyledListItem
        button
        component="a"
        href={item.url}
        target={item.target ? item.target : '_blank'}
        className={clsx('reddal-list-item')}
        role="button"
        sx={item.sx}
        disabled={item.disabled}
      >
        {item.icon && (
          <ReddalSvgIcon className={clsx('reddal-list-item-icon shrink-0', item.iconClass)} color="action">
            {item.icon}
          </ReddalSvgIcon>
        )}

        <ListItemText
          className="reddal-list-item-text"
          primary={item.title}
          classes={{ primary: 'text-13 reddal-list-item-text-primary truncate' }}
        />

        {item.badge && <ReddalNavBadge className="ltr:ml-8 rtl:mr-8" badge={item.badge} />}
      </StyledListItem>
    ),
    [item.badge, item.icon, item.iconClass, item.target, item.title, item.url, item.disabled, item.sx],
  )
}

ReddalNavHorizontalLink.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    icon: PropTypes.string,
    url: PropTypes.string,
    target: PropTypes.string,
  }),
}

ReddalNavHorizontalLink.defaultProps = {}

const NavHorizontalLink = withRouter(memo(ReddalNavHorizontalLink))

export default NavHorizontalLink
