import { useTournament } from '@hooks/useTournament'
import { Grid } from '@mui/material'
import { useParams } from 'react-router-dom'

import TournamentInfo from './TournamentInfo/TournamentInfo'

function TournamentsRankings() {
  const { tournament } = useTournament()
  if (!tournament) return <div>No Tournament Content</div>

  // https://codepen.io/srikanth4b9/pen/qBqJNOx
  return (
    <Grid container spacing={2} mt={5}>
      <Grid item xs={8} md={9}>
        <div>Content</div>
        <div>{JSON.stringify(tournament.leagues)}</div>
      </Grid>
      <Grid item xs={4} md={3}>
        <TournamentInfo />
        {/*{!!latestMatches && (*/}
        {/*  <div>*/}
        {/*    <h3>Latest Matches</h3>*/}
        {/*    {latestMatches.map((m) => (*/}
        {/*      <div key={m._id}>{m._id}</div>*/}
        {/*    ))}*/}
        {/*  </div>*/}
        {/*)}*/}
        {/*{!!upcomingMatches && (*/}
        {/*  <div>*/}
        {/*    <h3>Upcoming Matches</h3>*/}
        {/*    {upcomingMatches.map((m) => (*/}
        {/*      <div key={m._id}>{m._id}</div>*/}
        {/*    ))}*/}
        {/*  </div>*/}
        {/*)}*/}
      </Grid>
    </Grid>
  )
}
export default TournamentsRankings
