import { combineReducers } from '@reduxjs/toolkit'

import adminGames from './games/gamesState'
import adminUsers from './users/usersState'

const administrationReducers = combineReducers({
  adminGames,
  adminUsers,
})

export default administrationReducers
