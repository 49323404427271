import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import { ConfigProvider } from 'antd'
import { memo, useEffect, useLayoutEffect } from 'react'

const useEnhancedEffect = typeof window === 'undefined' ? useEffect : useLayoutEffect

function ReddalTheme(props: { children?: FixMe; theme?: FixMe; direction?: FixMe }) {
  const { theme, direction } = props
  const { mode } = theme.palette

  useEnhancedEffect(() => {
    document.dir = direction
  }, [direction])

  useEffect(() => {
    document.body.classList.add(mode === 'light' ? 'light' : 'dark')
    document.body.classList.remove(mode === 'light' ? 'dark' : 'light')
  }, [mode])

  // console.warn('ReddalTheme:: rendered',mainTheme);
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: theme.palette.primary.light,
            },
          }}
        >
          {props.children}
        </ConfigProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default memo(ReddalTheme)
