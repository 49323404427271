import { queryClient } from '@services/api'
import { rolesActions } from '@store/platform/roles/rolesState'
import { all, call, put, takeLatest } from 'redux-saga/effects'

function* GET_ROLES(): FixMe {
  try {
    const roles = yield call(() => queryClient.getQueryData(['roles']))
    yield put(rolesActions.fetchRolesSuccess(roles || []))
  } catch (e) {
    yield put(rolesActions.fetchRolesFailed('Failed to fetch roles'))
  }
}

export default function* rolesSaga() {
  yield all([takeLatest(rolesActions.fetchRoles.type, GET_ROLES), GET_ROLES()])
}
