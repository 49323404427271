import { adminGamesActions } from '@store/administration/games/gamesState'
import { call, put, takeLatest } from 'redux-saga/effects'
import { getAllGames } from 'services/api/administration/games'

function* GET_ALL_GAMES(): FixMe {
  try {
    const games: FixMe = yield call(getAllGames)
    yield put(adminGamesActions.fetchGamesSuccess(games))
  } catch (e) {
    yield put(adminGamesActions.fetchGamesFailed(new Error('Failed to fetch games')))
  }
}

export default function* gamesSaga() {
  yield takeLatest(adminGamesActions.fetchGames.type, GET_ALL_GAMES)
}
