const sessionStorageKey = 'reddalRedirectUrl'
const sessionPermissionStorageKey = 'reddalPermissionRedirectUrl'

export const getSessionRedirectUrl = () => {
  return window.sessionStorage.getItem(sessionStorageKey)
}

export const setSessionRedirectUrl = (url: string) => {
  window.sessionStorage.setItem(sessionStorageKey, url)
}

export const resetSessionRedirectUrl = () => {
  window.sessionStorage.removeItem(sessionStorageKey)
}

export const getLastUrl = () => {
  return window.sessionStorage.getItem(sessionPermissionStorageKey)
}

export const setLastUrl = (url: string) => {
  window.sessionStorage.setItem(sessionPermissionStorageKey, url)
}

export const resetLastUrl = () => {
  window.sessionStorage.removeItem(sessionPermissionStorageKey)
}
