import AppBar from '@mui/material/AppBar'
import { ThemeProvider } from '@mui/material/styles'
import Toolbar from '@mui/material/Toolbar'
import { selectFooterTheme } from '@store/reddal/settingsSlice'
import clsx from 'clsx'
import { memo } from 'react'
import { useSelector } from 'react-redux'

import DocumentationButton from '../../shared-components/DocumentationButton'
import PoweredByLinks from '../../shared-components/PoweredByLinks'
import PurchaseButton from '../../shared-components/PurchaseButton'

function FooterLayout1(props: FixMe) {
  const footerTheme = useSelector(selectFooterTheme)

  return (
    <ThemeProvider theme={footerTheme}>
      <AppBar
        id="reddal-footer"
        className={clsx('relative z-20 shadow-md', props.className)}
        color="default"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? footerTheme.palette.background.paper
              : footerTheme.palette.background.default,
        }}
      >
        <Toolbar className="flex min-h-48 items-center overflow-x-auto px-8 py-0 sm:px-12 md:min-h-64">
          <div className="flex shrink-0 grow">
            <PurchaseButton className="mx-4" />
            <DocumentationButton className="mx-4" />
          </div>
          <span className="text-gray opacity-30">
            version: {import.meta.env.VITE_APP_COMMIT_DEPLOYED ?? 'running locally'}
          </span>

          <div className="flex shrink-0 grow justify-end px-12">
            <PoweredByLinks />
          </div>
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  )
}

export default memo(FooterLayout1)
