import { combineReducers } from '@reduxjs/toolkit'
import administrationReducers from '@store/administration/reducer'
import authReducer from '@store/auth/authState'
import challenges from '@store/challenges/state'
import games from '@store/games/state'
import i18n from '@store/i18nSlice'
import laddersState from '@store/ladders/laddersState'
import playersState from '@store/players/playersState'
import reddalReducers from '@store/reddal/reducer'
import teams from '@store/teams/state'
import tournamentsState from '@store/tournaments/tournamentsState'
import { connectRouter } from 'connected-react-router'
import { History } from 'history'

import { counterSlice } from '../features/counter/counterSlice'

const createRootReducer = (history: History, asyncReducers?: FixMe) =>
  combineReducers({
    administration: administrationReducers,
    auth: authReducer,
    challenges,
    counter: counterSlice.reducer,
    games,
    i18n,
    ladders: laddersState,
    players: playersState,
    reddal: reddalReducers,
    router: connectRouter(history),
    teams,
    tournaments: tournamentsState,
    ...asyncReducers,
  })
export default createRootReducer
