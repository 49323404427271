import Box from '@mui/material/Box'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { visuallyHidden } from '@mui/utils'
import { ChangeEvent, MouseEvent } from 'react'

import { Order } from '../../utils/tableUtils'

export interface HeadCell {
  disablePadding: boolean
  id: string
  label: string
  numeric: boolean
  align: 'left' | 'right' | 'inherit' | 'center' | 'justify' | undefined
}

export interface EnhancedTableProps {
  headCells: HeadCell[]
  numSelected: number
  onRequestSort: (event: MouseEvent, property: string) => void
  onSelectAllClick: (event: ChangeEvent<HTMLInputElement>) => void
  order: Order
  orderBy: string
  rowCount: number
}

export function EnhancedTableHead(props: EnhancedTableProps) {
  const { headCells, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props
  const createSortHandler = (property: string) => (event: MouseEvent) => {
    onRequestSort(event, property)
  }

  console.log('EnhancedTableHead: headCells: ', headCells)

  return (
    <TableHead>
      <TableRow>
        {/*<TableCell padding="checkbox">*/}
        {/*  <Checkbox*/}
        {/*    color="primary"*/}
        {/*    indeterminate={numSelected > 0 && numSelected < rowCount}*/}
        {/*    checked={rowCount > 0 && numSelected === rowCount}*/}
        {/*    onChange={onSelectAllClick}*/}
        {/*    inputProps={{*/}
        {/*      'aria-label': 'select all desserts',*/}
        {/*    }}*/}
        {/*  />*/}
        {/*</TableCell>*/}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {/*<TableSortLabel*/}
            {/*  active={orderBy === headCell.id}*/}
            {/*  direction={orderBy === headCell.id ? order : 'asc'}*/}
            {/*  onClick={createSortHandler(headCell.id)}*/}
            {/*>*/}
            {headCell.label}
            {orderBy === headCell.id ? (
              <Box component="span" sx={visuallyHidden}>
                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
              </Box>
            ) : null}
            {/*</TableSortLabel>*/}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}
