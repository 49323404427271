import { useTeam } from '@hooks/team/useTeam'
import { useContext } from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import AppContext from '../../context/AppContext'

export const TeamPermission = ({ children }: PropsWithChildren) => {
  useContext(AppContext)
  const location = useLocation()
  const { belongsToTeam, team } = useTeam()
  if (location.pathname === `/team/${team?.refId}/administration` && !belongsToTeam) {
    return <Navigate to=".." />
  }

  return children
}
