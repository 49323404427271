import ReddalSidePanel from '@reddal/core/ReddalSidePanel'
import { memo } from 'react'

import NavigationShortcuts from '../../shared-components/NavigationShortcuts'

function LeftSideLayout3() {
  return (
    <>
      <ReddalSidePanel>
        <NavigationShortcuts className="py-16 px-8" variant="vertical" />
      </ReddalSidePanel>
    </>
  )
}

export default memo(LeftSideLayout3)
