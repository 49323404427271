import { AwardModel } from '@models/award'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import getAwardsImage from '@utils/getAwardsImage'
import { Link } from 'react-router-dom'

interface NameWithAwardProps {
  name: string
  link: string
  awards?: AwardModel[]
  currentAward: AwardModel
}

export const NameWithAward = ({ name, link, awards, currentAward }: NameWithAwardProps) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Link to={link} className="kit__utils__link">
        {name}
      </Link>
      {currentAward && (
        <>
          <Tooltip
            title={
              (
                <div className="d-flex flex-column">
                  {awards?.map((award) => <div key={award._id}>{award.title}</div>)}
                </div>
              ) || ''
            }
            placement="right"
          >
            <span className="ml-4">
              <img src={getAwardsImage(currentAward.icon)} alt="" />
            </span>
          </Tooltip>
        </>
      )}
    </Box>
  )
}
