import {
  Card,
  CardContent,
  CardContentProps,
  CardHeader,
  CardHeaderProps,
  CardProps,
  Divider,
  Typography,
} from '@mui/material'
// material-ui
import { useTheme } from '@mui/material/styles'
import { rgba } from 'polished'
import React, { Ref } from 'react'
// project imports
import { KeyedObject } from 'types'

// constant
const headerSXConstant = {
  '& .MuiCardHeader-action': { mr: 0 },
}

// ==============================|| CUSTOM MAIN CARD ||============================== //

export interface MainCardProps extends KeyedObject {
  border?: boolean
  boxShadow?: boolean
  children: React.ReactNode | string
  style?: React.CSSProperties
  content?: boolean
  className?: string
  contentClass?: string
  contentSX?: CardContentProps['sx']
  darkTitle?: boolean
  sx?: CardProps['sx']
  secondary?: CardHeaderProps['action']
  shadow?: string
  elevation?: number
  title?: React.ReactNode | string
}

///    background: rgb(255 255 255 / 0%);
//     border-radius: 8px;
//     box-shadow: 0 4px 30px rgb(0 0 0 / 10%);
//     backdrop-filter: blur(5px);
//     -webkit-backdrop-filter: blur(5px);
//     border: 1px solid rgb(255 255 255 / 5%);
export const MainCard = React.forwardRef(
  (
    {
      border = true,
      boxShadow,
      children,
      content = true,
      contentClass = '',
      contentSX = {},
      headerSX = {},
      darkTitle,
      secondary,
      shadow,
      sx = {},
      title,
      ...others
    }: MainCardProps,
    ref: Ref<HTMLDivElement>,
  ) => {
    const theme = useTheme()

    return (
      <Card
        ref={ref}
        {...others}
        sx={{
          backgroundColor: rgba(0, 0, 0, 0.15),
          border: border ? '1px solid rgb(255 255 255 / 5%)' : 'none',
          boxShadow: `0 4px 30px rgb(0 0 0 / 10%)`,
          backdropFilter: 'blur(12px)',
          width: '100%',
          // borderColor: theme.palette.mode === 'dark' ? '#203648' : theme.palette.background.paper,
          ':hover': {
            boxShadow: boxShadow
              ? shadow ||
                (theme.palette.mode === 'dark'
                  ? '0 2px 14px 0 rgb(33 150 243 / 10%)'
                  : '0 2px 14px 0 rgb(32 40 45 / 8%)')
              : 'inherit',
          },
          ...sx,
        }}
      >
        {/* card header and action */}
        {!darkTitle && title && (
          <CardHeader
            sx={{ ...headerSX, ...headerSXConstant }}
            title={<Typography variant="h6">{title}</Typography>}
            action={secondary}
            className={'font-medium leading-8 tracking-tight'}
            disableTypography
          />
        )}
        {darkTitle && title && (
          <CardHeader
            sx={{ ...headerSX, ...headerSXConstant }}
            title={<Typography variant="h3">{title}</Typography>}
            action={secondary}
            disableTypography
            className={'font-medium leading-8 tracking-tight'}
          />
        )}

        {/* content & header divider */}
        {title && <Divider />}

        {/* card content */}
        {content && (
          <CardContent sx={contentSX} className={contentClass}>
            {children}
          </CardContent>
        )}
        {!content && children}
      </Card>
    )
  },
)
