import { Button as MuiButton, ButtonProps as MuiButtonProps } from '@mui/material'
import { LinkProps } from 'react-router-dom'

interface ButtonProps extends MuiButtonProps {
  /**
   * Is this the principal call to action on the page?
   */
  primary?: boolean
  /**
   * What background color to use
   */
  backgroundColor?: string
  /**
   * How large should the button be?
   */
  size?: 'xsmall' | 'small' | 'medium' | 'large'
  /**
   * Button contents
   */
  label: string
  /**
   * Optional click handler
   */
  onClick?: () => void
}

/**
 * Primary UI component for user interaction
 */

export const Button = ({ label, children, ...rest }: ButtonProps & Partial<LinkProps>) => (
  <MuiButton {...rest}>{label ?? children}</MuiButton>
)
