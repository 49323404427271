import { PlayerModel } from '@models/player'
import { createSlice } from '@reduxjs/toolkit'

type PlayersType = {
  isLoading: boolean
  error: Error | null
  player: PlayerModel | null
}
const initialState: PlayersType = {
  isLoading: false,
  error: null,
  player: null,
}

const playersState = createSlice({
  name: 'players',
  initialState,
  reducers: {
    getPlayer: (state, _action) => {
      state.isLoading = true
    },
    getPlayerSuccess: (state, action) => {
      state.isLoading = false
      state.player = action.payload
    },
    getPlayerFailed: (state, action) => {
      state.isLoading = false
      state.player = null
      state.error = action.payload
    },
  },
})

export const { getPlayer, getPlayerSuccess, getPlayerFailed } = playersState.actions

export const selectPlayer = ({ players }: { players: PlayersType }) => players.player

export default playersState.reducer

export const playersActions = playersState.actions
