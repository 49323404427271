import ForgotPasswordConfig from './forgot-password/ForgotPasswordConfig'
import ResetPasswordConfig from './reset-password/ResetPasswordConfig'
import ResetLinkConfig from './reset-sent/ResetLinkConfig'
import SignInConfig from './sign-in/SignInConfig'
import SignOutConfig from './sign-out/SignOutConfig'
import SignUpConfig from './sign-up/SignUpConfig'

const authConfigs = [
  ForgotPasswordConfig,
  SignInConfig,
  SignOutConfig,
  SignUpConfig,
  ResetLinkConfig,
  ResetPasswordConfig,
]

export default authConfigs
