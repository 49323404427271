import { TeamModel } from '@models/team'
import { UploadEntity, UploadType } from '@models/upload'
import { UserModel } from '@models/user'
import { createId } from '@paralleldrive/cuid2'
import { queryClient } from '@services/api'
import { createTeam, getTeam } from '@services/api/teams'
import { uploadImage } from '@services/api/utils'
import { history } from '@store'
import { teamsActions } from '@store/teams/state'
import { call, put, takeLatest } from 'redux-saga/effects'

function* GET_TEAM(action: ReturnType<typeof teamsActions.getTeam>) {
  const refId = action.payload as string
  try {
    const team: TeamModel = yield call(getTeam, refId)
    yield put(teamsActions.getTeamSuccess(team))
  } catch (error) {
    if (error instanceof Error) {
      yield put(teamsActions.getTeamFailed(`Failed to fetch team: ${error.message}`))
    } else {
      console.log('Unexpected error', error)
    }
  }
}

function* CREATE_TEAM(action: ReturnType<typeof teamsActions.createTeam>) {
  try {
    const team = action.payload
    team.refId = createId()
    if (team.logo && typeof team.logo !== 'string') {
      const logo: { file: string } = yield call(uploadImage, UploadEntity.teams, UploadType.logo, team.refId, team.logo)
      team.logo = logo.file
    }
    if (team.banner && typeof team.banner !== 'string') {
      const banner: { file: string } = yield call(
        uploadImage,
        UploadEntity.teams,
        UploadType.banner,
        team.refId!,
        team.banner!,
      )
      team.banner = banner.file
    }
    const teamCreatedSuccess: { user: UserModel; team: TeamModel } = yield call(createTeam, team)
    yield call(() => queryClient.invalidateQueries({ queryKey: ['user'] }))
    history.push(`/team/${teamCreatedSuccess.team.refId}`)
  } catch (error) {
    if (error instanceof Error) {
      yield put(teamsActions.createTeamFailed(`Failed to create team: ${error.message}`))
    } else {
      console.log('Unexpected error', error)
    }
  }
}

export default function* sagas() {
  yield takeLatest(teamsActions.createTeam.type, CREATE_TEAM)
  yield takeLatest(teamsActions.getTeam.type, GET_TEAM)
}
