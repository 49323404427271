import { GuidModel } from '@models/guid'
import { UploadEntity, UploadType } from '@models/upload'
import { createId } from '@paralleldrive/cuid2'
import { queryClient } from '@services/api'
import { addGame } from '@services/api/administration/games'
import { gamesGuids } from '@services/api/games'
import { uploadImage } from '@services/api/utils'
import { history } from '@store'
import { gamesActions } from '@store/games/state'
import { showMessage } from '@store/reddal/messageSlice'
import { isAxiosError } from 'axios'
import { call, put, takeLatest } from 'redux-saga/effects'

function* GET_ALL_GAMES_GUIDS() {
  try {
    const guids: GuidModel[] = yield call(gamesGuids)
    yield put(gamesActions.getAllGamesGuidsSuccess(guids))
  } catch (error) {
    if (error instanceof Error) {
      yield put(gamesActions.getAllGamesGuidsFailed(`Failed to fetch guids: ${error.message}`))
    } else {
      console.log('Unexpected error', error)
    }
  }
}

function* ADD_GAME(action: ReturnType<typeof gamesActions.addGame>) {
  try {
    const { game, logo, banner } = action.payload
    game.refId = createId()
    if (logo) {
      const gameLogo: { file: string } = yield call(uploadImage, UploadEntity.games, UploadType.logo, game.refId, logo)
      game.logo = gameLogo.file
    } else {
      game.logo = `${import.meta.env.VITE_APP_CDN_URL}/files/placeholders/game_logo.png`
    }
    if (banner) {
      const gameBanner: { file: string } = yield call(
        uploadImage,
        UploadEntity.games,
        UploadType.banner,
        game.refId,
        banner,
      )
      game.banner = gameBanner.file
    }
    yield call(addGame, game)
    yield call(() => queryClient.invalidateQueries({ queryKey: ['games'] }))
    history.push(`/administration/games`)
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response?.data.code === 11000) {
        yield put(
          showMessage({
            variant: 'error',
            message: `Duplicate field: ${Object.keys(error.response?.data?.keyValue) || error.message}`,
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
          }),
        )
        yield put(
          gamesActions.addGameFailed(`Failed to create game: ${error.response?.data?.keyValue[0] || error.message}`),
        )
      }
      console.log('error ADDING GAME::::::::', error.response)
      console.log('error ADDING GAME::::::::', error.response?.data?.message)
      console.log('error ADDING GAME::::::::', JSON.stringify(error))
      yield put(gamesActions.addGameFailed(`Failed to create game: ${error.response?.data?.message || error.message}`))
    } else {
      console.log('Unexpected error', error)
    }
  }
}

export default function* sagas() {
  yield takeLatest(gamesActions.getAllGamesGuids, GET_ALL_GAMES_GUIDS)
  yield takeLatest(gamesActions.addGame, ADD_GAME)
}
