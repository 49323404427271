import { memo } from 'react'

import ChatPanel from '../../shared-components/chatPanel/ChatPanel'
import NotificationPanel from '../../shared-components/notificationPanel/NotificationPanel'
import QuickPanel from '../../shared-components/quickPanel/QuickPanel'

function RightSideLayout2() {
  return (
    <>
      <ChatPanel />

      <QuickPanel />

      <NotificationPanel />
    </>
  )
}

export default memo(RightSideLayout2)
