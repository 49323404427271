import Button from '@mui/material/Button'
import ReddalSvgIcon from '@reddal/core/ReddalSvgIcon'
import { Link } from 'react-router-dom'

function DocumentationButton({ className }: FixMe) {
  return (
    <Button
      component={Link}
      to="/documentation"
      role="button"
      className={className}
      variant="contained"
      color="primary"
      startIcon={<ReddalSvgIcon size={16}>heroicons-outline:book-open</ReddalSvgIcon>}
    >
      Documentation
    </Button>
  )
}

export default DocumentationButton
