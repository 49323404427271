import { FlagProps } from './flag.props'
import { FlagType, isoCountries } from './flags'

// TODO https://github.com/benmneb/mui-image
// Image component
const Flag = ({ code, size = 24, country, className, alt }: FlagProps) => {
  const flag = code || (Object.keys(isoCountries) as Array<FlagType>).find((k) => isoCountries[k] === country)
  const countryName = country || (code ? isoCountries[code] : undefined)
  return (
    <img
      style={{ width: size || 24, height: size || 24 }}
      src={`/assets/flags/${size || 24}/${flag}.png`}
      alt={alt ?? countryName?.toUpperCase()}
      className={className}
    />
  )
}

export default Flag
