// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* tslint:disable */
import Icon from '@mui/material/Icon'
import { styled } from '@mui/material/styles'
import { Box, SxProps } from '@mui/system'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { forwardRef, useMemo } from 'react'

const Root = styled(Box)(({ theme, ...props }) => ({
  width: props.size,
  height: props.size,
  minWidth: props.size,
  minHeight: props.size,
  fontSize: props.size,
  lineHeight: props.size,
  color: {
    primary: theme.palette.primary.main,
    secondary: theme.palette.secondary.main,
    info: theme.palette.info.main,
    success: theme.palette.success.main,
    warning: theme.palette.warning.main,
    action: theme.palette.action.active,
    error: theme.palette.error.main,
    disabled: theme.palette.action.disabled,
    inherit: undefined,
  }[props.color],
}))

interface ReddalSvgIconProps {
  children: string
  size?: number | string
  sx?: SxProps
  className?: string
  color?: 'inherit' | 'disabled' | 'primary' | 'secondary' | 'action' | 'error' | 'info' | 'success' | 'warning'
}

const ReddalSvgIcon = forwardRef((props: ReddalSvgIconProps, ref) => {
  const { children, size, sx, className, color } = props

  const iconPath = props.children.replace(':', '.svg#')
  return useMemo(
    () => (
      <>
        {!props.children.includes(':') ? (
          <Icon ref={ref} {...props} />
        ) : (
          <Root
            {...props}
            component="svg"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            className={clsx('shrink-0 fill-current ', className)}
            ref={ref}
            size={size}
            sx={sx}
            color={color}
          >
            <use xlinkHref={`/assets/icons/${iconPath}`} />
          </Root>
        )}
      </>
    ),
    [children, ref, className, size, sx, color, iconPath],
  )
})

ReddalSvgIcon.propTypes = {
  children: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  sx: PropTypes.object,
  color: PropTypes.oneOf([
    'inherit',
    'disabled',
    'primary',
    'secondary',
    'action',
    'error',
    'info',
    'success',
    'warning',
  ]),
}

ReddalSvgIcon.defaultProps = {
  children: '',
  size: 24,
  sx: {},
  color: 'inherit',
}

export default ReddalSvgIcon
