import { GameModel } from '@models/game'
import { apiClient } from 'services/api'

export async function gamesGuids() {
  const response = await apiClient.get(`/guids`)
  if (response) {
    return response.data
  }
  throw new Error('No guids response data')
}

export async function updateGame(game: Partial<GameModel>) {
  return apiClient
    .put(`/game/${game.refId}`, game)
    .then((response) => {
      if (response) {
        return response.data
      }
      throw new Error('Error while adding game')
    })
    .catch((err) => {
      throw err
    })
}
