// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* tslint:disable */
import { amber, blue, green } from '@mui/material/colors'
import IconButton from '@mui/material/IconButton'
import Snackbar from '@mui/material/Snackbar'
import SnackbarContent from '@mui/material/SnackbarContent'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { hideMessage, selectReddalMessageOptions, selectReddalMessageState } from '@store/reddal/messageSlice'
import { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import ReddalSvgIcon from '../ReddalSvgIcon'

export type ReddalMessageVariantType = 'success' | 'error' | 'warning' | 'info'

type StyledSnackbarProps = {
  variant?: ReddalMessageVariantType
}

const StyledSnackbar = styled(Snackbar)<StyledSnackbarProps>(({ theme, variant }) => ({
  '& .ReddalMessage-content': {
    ...(variant === 'success' && {
      backgroundColor: green[600],
      color: '#FFFFFF',
    }),

    ...(variant === 'error' && {
      backgroundColor: theme.palette.error.dark,
      color: theme.palette.getContrastText(theme.palette.error.dark),
    }),

    ...(variant === 'info' && {
      backgroundColor: blue[600],
      color: '#FFFFFF',
    }),

    ...(variant === 'warning' && {
      backgroundColor: amber[600],
      color: '#FFFFFF',
    }),
  },
}))

const variantIcon = {
  success: 'check_circle',
  warning: 'warning',
  error: 'error_outline',
  info: 'info',
}

function ReddalMessage() {
  const dispatch = useDispatch()
  const state = useSelector(selectReddalMessageState)
  const options = useSelector(selectReddalMessageOptions)

  return (
    <StyledSnackbar
      {...options}
      open={state}
      onClose={() => dispatch(hideMessage())}
      ContentProps={{
        variant: 'body2',
        headlineMapping: {
          body1: 'div',
          body2: 'div',
        },
      }}
    >
      <SnackbarContent
        className="ReddalMessage-content"
        message={
          <div className="flex items-center">
            {variantIcon[options.variant] && (
              <ReddalSvgIcon color="inherit">{variantIcon[options.variant]}</ReddalSvgIcon>
            )}
            <Typography className="mx-8">{options.message}</Typography>
          </div>
        }
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => dispatch(hideMessage())}
            size="large"
          >
            <ReddalSvgIcon>heroicons-outline:x</ReddalSvgIcon>
          </IconButton>,
        ]}
      />
    </StyledSnackbar>
  )
}

export default memo(ReddalMessage)
