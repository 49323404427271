import { getLadder, getLadderLatestMatches, getLadderUpcomingMatches } from '@services/api/ladders'
import { useQuery } from '@tanstack/react-query'

export const useLadder = (ladderId?: string) => {
  const { data: ladder, isLoading: loadingLadder } = useQuery({
    queryKey: ['ladder', ladderId],
    queryFn: () => getLadder(ladderId!),
    enabled: !!ladderId,
    // should be 24h
    staleTime: 1000 * 60 * 60,
  })
  const { data: upcomingMatches, isLoading: loadingUpcomingMatches } = useQuery({
    queryKey: ['ladder', ladderId, 'upcomingMatches'],
    queryFn: () => getLadderUpcomingMatches(ladderId!),
    placeholderData: [],
    enabled: !!ladderId,
    // should be 24h
    staleTime: 1000 * 60 * 60,
  })
  const { data: latestMatches, isLoading: loadingLatestMatches } = useQuery({
    queryKey: ['ladder', ladderId, 'latestMatches'],
    queryFn: () => getLadderLatestMatches(ladderId!),
    placeholderData: [],
    enabled: !!ladderId,
    // should be 24h > 1000 * 60 * 60 * 24,
    staleTime: 1000 * 60 * 60,
  })

  return {
    isSupervisor: true,
    isAdmin: true,
    ladder,
    loadingLadder,
    latestMatches,
    loadingLatestMatches,
    upcomingMatches,
    loadingUpcomingMatches,
  }
}
