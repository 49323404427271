import GlobalStyles from '@mui/material/GlobalStyles'
import { styled } from '@mui/material/styles'
import ReddalDialog from '@reddal/core/ReddalDialog'
import ReddalMessage from '@reddal/core/ReddalMessage'
import ReddalSuspense from '@reddal/core/ReddalSuspense'
import { selectReddalCurrentLayoutConfig } from '@store/reddal/settingsSlice'
import AppContext from 'context/AppContext'
import { ConfigDefaults } from 'layouts/layout1/Layout1Config'
import { rgba } from 'polished'
import { memo, ReactElement, ReactFragment, ReactPortal, useContext } from 'react'
import { useSelector } from 'react-redux'
import { useRoutes } from 'react-router-dom'

import SettingsPanel from '../shared-components/SettingsPanel'
import FooterLayout1 from './components/FooterLayout1'
import LeftSideLayout1 from './components/LeftSideLayout1'
import NavbarWrapperLayout1 from './components/NavbarWrapperLayout1'
import RightSideLayout1 from './components/RightSideLayout1'
import ToolbarLayout1 from './components/ToolbarLayout1'

const Root = styled('div')(({ config }: { config: ConfigDefaults }) => ({
  ...(config.mode === 'boxed' && {
    clipPath: 'inset(0)',
    maxWidth: `${config.containerWidth}px`,
    margin: '0 auto',
    boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
  }),
  ...(config.mode === 'container' && {
    '& .container': {
      padding: '0 24px',
      maxWidth: `${config.containerWidth}px`,
      width: '100%',
      margin: '0 auto',
    },
  }),
}))
const Main = styled('main')(({ theme }) => ({
  background: `radial-gradient(${rgba(theme.palette.primary.light, 0.9)}, ${rgba(theme.palette.primary.dark, 1)})`,
}))
function Layout1(props: {
  children: string | number | boolean | ReactElement | ReactFragment | ReactPortal | null | undefined
}) {
  const config = useSelector(selectReddalCurrentLayoutConfig)
  const appContext = useContext(AppContext)
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { routes } = appContext

  return (
    <Root id="reddal-layout" config={config} className="flex w-full">
      {config.leftSidePanel.display && <LeftSideLayout1 />}

      <div className="flex min-w-0 flex-auto">
        {config.navbar.display && config.navbar.position === 'left' && <NavbarWrapperLayout1 />}

        <Main id="reddal-main" className="relative z-10 flex min-h-full min-w-0 flex-auto flex-col">
          {config.toolbar.display && <ToolbarLayout1 className={config.toolbar.style === 'fixed' && 'sticky top-0'} />}
          {/*TODO dont forget about this in order to allow users to select their own themes*/}
          {/*{import.meta.env.DEV && (*/}
          {/*  <div className="sticky top-0 z-99">*/}
          {/*    <SettingsPanel />*/}
          {/*  </div>*/}
          {/*)}*/}
          <div className="relative z-10 flex min-h-0 flex-auto flex-col">
            <ReddalDialog />

            <ReddalSuspense>{useRoutes(routes)}</ReddalSuspense>

            {props.children}
          </div>
          {config.footer.display && <FooterLayout1 className={config.footer.style === 'fixed' && 'sticky bottom-0'} />}
        </Main>

        {config.navbar.display && config.navbar.position === 'right' && <NavbarWrapperLayout1 />}
      </div>

      {config.rightSidePanel.display && <RightSideLayout1 />}
      <ReddalMessage />
    </Root>
  )
}

export default memo(Layout1)
