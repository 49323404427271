import Dialog from '@mui/material/Dialog'
import { closeDialog, selectReddalDialogOptions, selectReddalDialogState } from '@store/reddal/dialogSlice'
import { useDispatch, useSelector } from 'react-redux'

function ReddalDialog() {
  const dispatch = useDispatch()
  const state = useSelector(selectReddalDialogState)
  const options = useSelector(selectReddalDialogOptions)

  return (
    <Dialog
      open={!!state}
      onClose={() => dispatch(closeDialog())}
      aria-labelledby="reddal-dialog-title"
      classes={{
        paper: 'rounded-8',
      }}
      {...options}
    />
  )
}

export default ReddalDialog
