import { UserModel } from '@models/user'
import { apiClient } from 'services/api'

export async function getAllUsers(): Promise<UserModel[]> {
  return apiClient
    .get('/users')
    .then((response) => {
      if (response) {
        return response.data
      }
      throw new Error('Error while fetching users')
    })
    .catch((err) => {
      console.log('Error while fetching users', err)
      throw err
    })
}

export async function getUser(refId: string): Promise<UserModel> {
  return apiClient
    .get(`admin/user/${refId}`)
    .then((response) => {
      if (response) {
        return response.data
      }
      throw new Error('Error while fetching users')
    })
    .catch((err) => {
      throw err
    })
}

// export async function getUser(refId: string): Promise<UserModel> {
//   const response = await apiClient.get(`/users/${refId}`)
//   return response.data
// }

export async function updateUser(user: Partial<UserModel> & Pick<UserModel, 'refId'>): Promise<UserModel> {
  return apiClient
    .patch(`/user/${user.refId}`, user)
    .then((response) => {
      if (response) {
        return response.data
      }
      throw new Error('Error while fetching users')
    })
    .catch((err) => {
      throw err
    })
}
