import { apiClient } from 'services/api'

export async function getMenu() {
  return apiClient
    .get('/menu')
    .then((response) => {
      if (response) {
        console.log('response ::::::::::::::::::::: GET MENUUUUUUUUU data')
        return response.data
      }
      console.log('response ::::::::::::::::::::: GET MENUUUUUUUUU response')

      throw new Error('Error while fetching menu')
    })
    .catch((err) => {
      console.log('response ::::::::::::::::::::: GET err', err)

      throw err
    })
}
