import { lazy } from 'react'
import { Navigate } from 'react-router-dom'

import TournamentsRankings from './TournamentsRankings'

const TournamentsPageLayout = lazy(() => import('./TournamentsPageLayout'))
const TournamentsContent = lazy(() => import('./TournamentsContent'))

// FALLBACK TO DOCUMENTATION PAGES ON WEBAPP
const TournamentsConfig = {
  settings: {
    layout: {
      config: {},
    },
  },

  routes: [
    {
      path: 'tournaments',
      auth: null,
      element: <div>All Tournaments</div>,
    },
    {
      path: 'tournaments',
      auth: null,
      children: [
        {
          path: ':gameId',
          auth: null,
          index: true,
          element: <div>All Game Tournaments</div>,
        },
      ],
    },
    {
      path: 'tournament',
      auth: null,
      element: <TournamentsPageLayout />,
      children: [
        {
          path: ':tournamentId',
          auth: null,
          children: [
            {
              index: true,
              element: <TournamentsContent />,
            },
            {
              path: 'rankings',
              auth: null,
              element: <TournamentsRankings />,
            },
            {
              path: 'matches',
              auth: null,
              element: <div>Matches</div>,
            },
            {
              path: 'teams',
              auth: null,
              element: <div>Teams</div>,
            },
            {
              path: 'rules',
              auth: null,
              element: <div>Rules</div>,
            },
            {
              path: '*',
              auth: null,
              element: <Navigate to="" relative="route" />,
            },
          ],
        },
      ],
    },
  ],
}

export default TournamentsConfig
