// eslint-disable-next-line max-classes-per-file,@typescript-eslint/ban-ts-comment
// @ts-nocheck
/* tslint:disable */
import { useUser } from '@hooks/useUser'
import Avatar from '@mui/material/Avatar'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

const Root = styled('div')(({ theme }) => ({
  '& .username, & .email': {
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
  },

  '& .avatar': {
    background: theme.palette.background.default,
    transition: theme.transitions.create('all', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
    bottom: 0,
    '& > img': {
      borderRadius: '50%',
    },
  },
}))

function UserNavbarHeader() {
  const { user } = useUser()

  if (!user) return null
  return (
    <Root className="user relative flex flex-col items-center justify-center p-16 pb-14 shadow-0">
      <div className="mb-24 flex items-center justify-center">
        <Avatar
          sx={{
            backgroundColor: 'background.paper',
            color: 'text.secondary',
          }}
          className="avatar h-96 w-96 text-32 font-bold"
          src={user.avatar}
          alt={user.username}
        >
          {user.username.charAt(0)}
        </Avatar>
      </div>
      <Typography className="username whitespace-nowrap text-14 font-medium">{user.username}</Typography>
      <Typography className="email whitespace-nowrap text-13 font-medium" color="text.secondary">
        {user.email}
      </Typography>
    </Root>
  )
}

export default UserNavbarHeader
