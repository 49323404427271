import plugin from 'tailwindcss/plugin'

const iconSizePlugin = plugin(
  ({ addUtilities, theme, e, variants }: FixMe) => {
    const values = theme('iconSize')

    addUtilities(
      Object.entries(values).map(([key, value]) => ({
        [`.${e(`icon-size-${key}`)}`]: {
          width: value,
          height: value,
          minWidth: value,
          minHeight: value,
          fontSize: value,
          lineHeight: value,
          [`svg`]: {
            width: value,
            height: value,
          },
        },
      })),
      variants('iconSize'),
    )
  },
  {
    theme: {
      iconSize: (theme: FixMe) => ({
        ...theme('spacing'),
      }),
    },
    variants: {
      iconSize: ['responsive'],
    },
  },
)

export default iconSizePlugin
