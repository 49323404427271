import MuiAvatar, { AvatarProps } from '@mui/material/Avatar'
import { OverridableComponent } from '@mui/material/OverridableComponent'
// material-ui
import { useTheme } from '@mui/material/styles'
import { ReactNode } from 'react'
import { LinkTarget } from 'types'
// ==============================|| AVATAR ||============================== //

interface avatarProps extends AvatarProps {
  alt?: string
  src?: string
  className?: string
  color?: string
  component?: OverridableComponent<FixMe> /** Any component can override */
  target?: LinkTarget
  href?: string
  sx?: AvatarProps['sx']
  children?: ReactNode
  outline?: boolean
  size?: 'badge' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | 'profile'
}

export const Avatar = ({ className, color, outline, size, sx, ...others }: avatarProps) => {
  const theme = useTheme()

  const colorSX = color && !outline && { color: theme.palette.background.paper, bgcolor: `${color}.main` }
  const outlineSX = outline && {
    color: color ? `${color}.main` : `primary.main`,
    bgcolor: theme.palette.background.paper,
    border: '2px solid',
    borderColor: color ? `${color}.main` : `primary.main`,
  }
  let sizeSX = {}
  switch (size) {
    case 'badge':
      sizeSX = {
        width: theme.spacing(8),
        height: theme.spacing(8),
      }
      break
    case 'xs':
      sizeSX = {
        width: 24,
        height: 24,
      }
      break
    case 'sm':
      sizeSX = {
        width: theme.spacing(16),
        height: theme.spacing(16),
      }
      break
    case 'lg':
      sizeSX = {
        width: theme.spacing(20),
        height: theme.spacing(20),
      }
      break
    case 'xl':
      sizeSX = {
        width: theme.spacing(24),
        height: theme.spacing(24),
      }
      break
    case '2xl':
      sizeSX = {
        width: theme.spacing(48),
        height: theme.spacing(48),
      }
      break
    case 'md':
      sizeSX = {
        width: theme.spacing(7.5),
        height: theme.spacing(7.5),
      }
      break
    case 'profile':
      sizeSX = {
        width: 116,
        height: 116,
      }
      break
    default:
      sizeSX = {}
  }

  return <MuiAvatar sx={{ ...colorSX, ...outlineSX, ...sizeSX, ...sx }} {...others} className={className} />
}
