// eslint-disable-next-line max-classes-per-file,@typescript-eslint/ban-ts-comment
// @ts-nocheck
/* tslint:disable */
import _ from '@lodash'
import ReddalUtils from '@reddal/utils'
import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit'
import { queryClient } from '@services/api'
import authRoles from 'configs/authRoles'
import navigationConfig from 'configs/navigationConfig'
import i18next from 'i18next'

const navigationAdapter = createEntityAdapter()
const emptyInitialState = navigationAdapter.getInitialState()
const initialState =
  navigationConfig?.length > 0 ? navigationAdapter.upsertMany(emptyInitialState, navigationConfig) : []

export const getSliceMenu = createAsyncThunk('navigation/menu', async (_data, { getState, dispatch }) => {
  const data = queryClient.getQueryData(['platformSettings'])
  const menu = [
    ...data,
    // {
    //   id: 'administration',
    //   title: 'Administration',
    //   subtitle: 'Platform Administration',
    //   translate: 'ADMINISTRATION',
    //   type: 'item',
    //   icon: 'admin',
    //   url: '/administration',
    //   auth: authRoles.admin,
    // },
    // {
    //   id: 'documentation',
    //   title: 'Documentation',
    //   subtitle: 'Documentation Platform',
    //   translate: 'DOCUMENTATION',
    //   type: 'item',
    //   icon: 'document',
    //   url: '/documentation',
    //   // auth: authRoles.admin,
    //   auth: null,
    // },
  ]
  menu.map((item) => dispatch(appendNavigationItem(item)))

  return data
})

export const appendNavigationItem = (item, parentId) => (dispatch, getState) => {
  const navigation = selectNavigationAll(getState())
  return dispatch(setNavigation(ReddalUtils.appendNavItem(navigation, item, parentId)))
}

export const prependNavigationItem = (item, parentId) => (dispatch, getState) => {
  const navigation = selectNavigationAll(getState())

  return dispatch(setNavigation(ReddalUtils.prependNavItem(navigation, item, parentId)))
}

export const updateNavigationItem = (id, item) => (dispatch, getState) => {
  const navigation = selectNavigationAll(getState())

  return dispatch(setNavigation(ReddalUtils.updateNavItem(navigation, id, item)))
}

export const removeNavigationItem = (id) => (dispatch, getState) => {
  const navigation = selectNavigationAll(getState())

  return dispatch(setNavigation(ReddalUtils.removeNavItem(navigation, id)))
}

export const {
  selectAll: selectNavigationAll,
  selectIds: selectNavigationIds,
  selectById: selectNavigationItemById,
} = navigationAdapter.getSelectors((state) => state.reddal.navigation)

const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    setNavigation: navigationAdapter.setAll,
    resetNavigation: (state, action) => initialState,
  },
  extraReducers: {
    [getSliceMenu.fulfilled]: (state, action) => {
      // TODO why?
      // console.log(action.payload)
    },
  },
})

export const { setNavigation, resetNavigation } = navigationSlice.actions

const getUserRole = (state) => state.auth.user?.roles

const getQueryClientUserRole = () => {
  const user = queryClient.getQueryData(['user'])
  return user?.roles
}
export const selectNavigation = createSelector(
  [selectNavigationAll, ({ i18n }) => i18n.language, getQueryClientUserRole],
  (navigation, language, userRole) => {
    function setTranslationValues(data) {
      // loop through every object in the array
      return data.map((item) => {
        if (item.translate && item.title) {
          item.title = i18next.t(item.translate)
        }

        // see if there is a children node
        if (item.children) {
          // run this function recursively on the children array
          item.children = setTranslationValues(item.children)
        }
        return item
      })
    }

    return setTranslationValues(
      _.merge(
        [],
        filterRecursively(navigation, (item) => ReddalUtils.hasPermission(item.auth, userRole)),
      ),
    )
  },
)

function filterRecursively(arr, predicate) {
  return arr.filter(predicate).map((item) => {
    item = { ...item }
    if (item.children) {
      item.children = filterRecursively(item.children, predicate)
    }
    return item
  })
}

export const selectFlatNavigation = createSelector([selectNavigation], (navigation) =>
  ReddalUtils.getFlatNavigation(navigation),
)

export default navigationSlice.reducer
