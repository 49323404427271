import { SnackbarProps } from '@mui/material'
import { ReddalMessageVariantType } from '@reddal/core/ReddalMessage/ReddalMessage'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

/**
 * The type definition for the initial state of the message slice.
 */
type SnackBarOptions = Pick<SnackbarProps, 'anchorOrigin' | 'autoHideDuration' | 'message'> & {
  variant: ReddalMessageVariantType
}

type initialStateProps = {
  state: boolean
  options: SnackBarOptions
}

/**
 * The initial state of the message slice.
 */
const initialState: initialStateProps = {
  state: false,
  options: {
    variant: 'info',
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
    autoHideDuration: 2000,
    message: '',
  },
}

const messageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {
    showMessage: (state, action: PayloadAction<SnackBarOptions>) => {
      state.state = true
      state.options = {
        ...initialState.options,
        ...action.payload,
      } as initialStateProps['options']
    },
    hideMessage: (state) => {
      state.state = false
    },
  },
})

export const { hideMessage, showMessage } = messageSlice.actions

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const selectReddalMessageState = ({ reddal }) => reddal.message.state

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const selectReddalMessageOptions = ({ reddal }) => reddal.message.options

export default messageSlice.reducer
