import withRouter from '@reddal/core/withRouter'
import { Component, forwardRef } from 'react'

const withRouterAndRef = (WrappedComponent: FixMe) => {
  class InnerComponentWithRef extends Component {
    render() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const { forwardRef: _forwardRef, ...rest } = this.props
      return <WrappedComponent {...rest} ref={_forwardRef} />
    }
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const ComponentWithRouter = withRouter(InnerComponentWithRef, { withRef: true })
  return forwardRef((props, ref) => <ComponentWithRouter {...props} forwardRef={ref} />)
}

export default withRouterAndRef
