// eslint-disable-next-line max-classes-per-file,@typescript-eslint/ban-ts-comment
// @ts-nocheck
/* tslint:disable */
import IconButton from '@mui/material/IconButton'
import ReddalSvgIcon from '@reddal/core/ReddalSvgIcon'
import { useDispatch } from 'react-redux'

import { toggleChatPanel } from './store/stateSlice'

const ChatPanelToggleButton = (props) => {
  const dispatch = useDispatch()

  return (
    <IconButton className="h-40 w-40" onClick={(ev) => dispatch(toggleChatPanel())} size="large">
      {props.children}
    </IconButton>
  )
}

ChatPanelToggleButton.defaultProps = {
  children: <ReddalSvgIcon>heroicons-outline:chat</ReddalSvgIcon>,
}

export default ChatPanelToggleButton
