import { joinTournament } from '@services/api/tournaments'
import { showMessage } from '@store/reddal/messageSlice'
import { tournamentsActions } from '@store/tournaments/tournamentsState'
import { isAxiosError } from 'axios'
import { call, put, takeLatest } from 'redux-saga/effects'

function* JOIN_TOURNAMENT(action: ReturnType<typeof tournamentsActions.joinTournament>) {
  const tournamentInfo = action.payload
  try {
    yield call(joinTournament, tournamentInfo)
    yield put(tournamentsActions.joinTournamentSuccess())
  } catch (error) {
    console.log('error', error)
    if (isAxiosError(error)) {
      yield put(
        showMessage({
          variant: 'error',
          message: error.response?.data || 'Error joining tournament',
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        }),
      )
    }
    yield put(tournamentsActions.joinTournamentFailed(error))
  }
}

export default function* tournamentsSaga() {
  yield takeLatest(tournamentsActions.joinTournament.type, JOIN_TOURNAMENT)
}
