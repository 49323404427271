// eslint-disable-next-line max-classes-per-file,@typescript-eslint/ban-ts-comment
// @ts-nocheck
/* tslint:disable */
import { styled } from '@mui/material/styles'
import ReddalDialog from '@reddal/core/ReddalDialog'
import ReddalMessage from '@reddal/core/ReddalMessage'
import ReddalSuspense from '@reddal/core/ReddalSuspense'
import { selectReddalCurrentLayoutConfig } from '@store/reddal/settingsSlice'
import clsx from 'clsx'
import AppContext from 'context/AppContext'
import { rgba } from 'polished'
import { memo, useContext } from 'react'
import { useSelector } from 'react-redux'
import { useRoutes } from 'react-router-dom'

import SettingsPanel from '../shared-components/SettingsPanel'
import FooterLayout3 from './components/FooterLayout3'
import LeftSideLayout3 from './components/LeftSideLayout3'
import NavbarWrapperLayout3 from './components/NavbarWrapperLayout3'
import RightSideLayout3 from './components/RightSideLayout3'
import ToolbarLayout3 from './components/ToolbarLayout3'

const Root = styled('div')(({ theme, config }) => ({
  ...(config.mode === 'boxed' && {
    clipPath: 'inset(0)',
    maxWidth: `${config.containerWidth}px`,
    margin: '0 auto',
    boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
  }),
  ...(config.mode === 'container' && {
    '& .container': {
      maxWidth: `${config.containerWidth}px`,
      width: '100%',
      margin: '0 auto',
    },
  }),
}))

const Main = styled('main')(({ theme }) => ({
  background: `radial-gradient(${rgba(theme.palette.primary.light, 0.9)}, ${rgba(theme.palette.primary.dark, 1)})`,
}))

function Layout3(props) {
  const config = useSelector(selectReddalCurrentLayoutConfig)
  const appContext = useContext(AppContext)
  const { routes } = appContext
  return (
    <Root id="reddal-layout" className="flex w-full" config={config}>
      {config.leftSidePanel.display && <LeftSideLayout3 />}

      <div className="flex min-w-0 flex-auto flex-col">
        <Main id="reddal-main" className="relative flex min-h-full min-w-0 flex-auto flex-col">
          {config.navbar.display && (
            <NavbarWrapperLayout3 className={clsx(config.navbar.style === 'fixed' && 'sticky top-0 z-50')} />
          )}

          {config.toolbar.display && (
            <ToolbarLayout3
              className={clsx(
                config.toolbar.style === 'fixed' && 'sticky top-0',
                config.toolbar.position === 'above' && 'z-40 order-first',
              )}
            />
          )}

          <div className="sticky top-0 z-99">
            <SettingsPanel />
          </div>

          <div className="relative z-10 flex min-h-0 flex-auto flex-col">
            <ReddalDialog />

            <ReddalSuspense>{useRoutes(routes)}</ReddalSuspense>

            {props.children}
          </div>

          {config.footer.display && <FooterLayout3 className={config.footer.style === 'fixed' && 'sticky bottom-0'} />}
        </Main>
      </div>

      {config.rightSidePanel.display && <RightSideLayout3 />}
      <ReddalMessage />
    </Root>
  )
}

export default memo(Layout3)
