import { GameModel } from '@models/game'
import { GuidModel } from '@models/guid'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RcFile } from 'antd/lib/upload/interface'

type State = {
  guids: GuidModel[]
  isLoading: boolean
  error: unknown | null
}
const initialState: State = {
  guids: [],
  isLoading: false,
  error: null,
}

const state = createSlice({
  name: 'games',
  initialState,
  reducers: {
    getAllGamesGuids: (state) => {
      state.isLoading = true
    },
    getAllGamesGuidsSuccess: (state, action: PayloadAction<GuidModel[]>) => {
      state.isLoading = false
      state.guids = action.payload
    },
    getAllGamesGuidsFailed: (state, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
    addGame: (state, _action: PayloadAction<{ game: Partial<GameModel>; logo?: RcFile; banner?: RcFile }>) => {
      state.isLoading = true
    },
    addGameSuccess: (state, _action: PayloadAction<GameModel>) => {
      state.isLoading = false
    },
    addGameFailed: (state, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
  },
})

export const {
  getAllGamesGuids,
  getAllGamesGuidsSuccess,
  getAllGamesGuidsFailed,
  addGameSuccess,
  addGameFailed,
  addGame,
} = state.actions

export const selectAllGamesGuids = ({ games }: { games: State }) => games.guids
export default state.reducer

export const gamesActions = state.actions
