import { apiClient } from '@services/api'

// CDN dvfjiyqu46c2g.cloudfront.net
export async function uploadImage(entity: string, type: string, refId: string, file: File): Promise<{ file: string }> {
  const formData = new FormData()
  formData.append('entity', entity)
  formData.append('type', type)
  formData.append('refId', refId)
  formData.append('file', file)
  return apiClient
    .post('/upload', formData)
    .then((response) => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch((err) => {
      throw err
    })
}
