import { lazy } from 'react'

const NewsPageLayout = lazy(() => import('./NewsPageLayout'))
const NewsPageContent = lazy(() => import('./NewsPageContent'))

const NewsConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'news',
      auth: null,
      element: <NewsPageLayout />,
      children: [
        {
          auth: null,
          index: true,
          element: <NewsPageContent />,
        },
      ],
    },
  ],
}

export default NewsConfig
