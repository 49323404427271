import Flag from '@components/Flag'
import Button from '@mui/material/Button'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import Popover from '@mui/material/Popover'
import Typography from '@mui/material/Typography'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { changeLanguage, selectCurrentLanguage, selectLanguages } from '@store/i18nSlice'
import { useState } from 'react'

function LanguageSwitcher() {
  const currentLanguage = useAppSelector(selectCurrentLanguage)
  const languages = useAppSelector(selectLanguages)
  const [menu, setMenu] = useState(null)
  const dispatch = useAppDispatch()

  const langMenuClick = (event: FixMe) => {
    setMenu(event.currentTarget)
  }

  const langMenuClose = () => {
    setMenu(null)
  }

  function handleLanguageChange(lng: FixMe) {
    dispatch(changeLanguage(lng.id))

    langMenuClose()
  }

  return (
    <>
      <Button className="h-40 w-64" onClick={langMenuClick}>
        <Flag className="min-w-20" code={currentLanguage.flag} alt={currentLanguage.title} />

        <Typography className="mx-4 font-semibold uppercase" color="text.secondary">
          {currentLanguage.id}
        </Typography>
      </Button>

      <Popover
        open={Boolean(menu)}
        anchorEl={menu}
        onClose={langMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{
          paper: 'py-8',
        }}
      >
        {languages.map((lng: FixMe) => (
          <MenuItem key={lng.id} onClick={() => handleLanguageChange(lng)}>
            <ListItemIcon className="min-w-40">
              <Flag className="min-w-20" code={lng.flag} alt={lng.title} />
            </ListItemIcon>
            <ListItemText primary={lng.title} />
          </MenuItem>
        ))}

        {/*<MenuItem*/}
        {/*  component={Link}*/}
        {/*  to="/documentation/configuration/multi-language"*/}
        {/*  onClick={langMenuClose}*/}
        {/*  role="button"*/}
        {/*>*/}
        {/*  <ListItemText primary="Learn More" />*/}
        {/*</MenuItem>*/}
      </Popover>
    </>
  )
}

export default LanguageSwitcher
