import { ChallengeModel } from '@models/challenge'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type State = {
  currentChallenge: ChallengeModel | undefined
  isLoading: boolean
  isChallenging: boolean
  error: unknown | null
}
const initialState: State = {
  currentChallenge: undefined,
  isLoading: false,
  isChallenging: false,
  error: null,
}

const state = createSlice({
  name: 'challenges',
  initialState,
  reducers: {
    challenge: (state, _action) => {
      state.isLoading = true
    },
    challengeSuccess: (state, action) => {
      state.isLoading = false
      state.currentChallenge = action.payload
    },
    challengeFailed: (state, action) => {
      state.isLoading = false
      state.currentChallenge = undefined
      state.error = action.payload
    },
    createChallenge: (state, _action: PayloadAction<ChallengeModel>) => {
      state.isLoading = true
    },
    createChallengeFailed: (state, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
    createChallengeSuccess: (state, action: PayloadAction<ChallengeModel>) => {
      state.currentChallenge = action.payload
    },
  },
})

export const {
  challenge,
  challengeFailed,
  challengeSuccess,
  createChallenge,
  createChallengeFailed,
  createChallengeSuccess,
} = state.actions

export const selectCurrentChallenge = ({ challenges }: { challenges: State }) => challenges.currentChallenge

export default state.reducer

export const challengeActions = state.actions
