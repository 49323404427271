import ReddalSearch from '@reddal/core/ReddalSearch'
import { selectFlatNavigation } from '@store/reddal/navigationSlice'
import { useSelector } from 'react-redux'

function NavigationSearch(props: FixMe) {
  const { variant, className } = props
  const navigation = useSelector(selectFlatNavigation)

  return <ReddalSearch className={className} variant={variant} navigation={navigation} />
}

export default NavigationSearch
