import { useUser } from '@hooks/useUser'
import { memo } from 'react'

import ChatPanel from '../../shared-components/chatPanel/ChatPanel'
import NotificationPanel from '../../shared-components/notificationPanel/NotificationPanel'
import QuickPanel from '../../shared-components/quickPanel/QuickPanel'

function RightSideLayout1() {
  const { user } = useUser()
  return (
    <>
      {/*Chat Panel*/}
      {user && <ChatPanel />}
      {/*Schedule Panel*/}
      <QuickPanel />
      {/*Notifications Panel*/}
      <NotificationPanel />
    </>
  )
}

export default memo(RightSideLayout1)
