import { useEffect, useRef } from 'react'

function useTimeout(callback: unknown, delay: unknown) {
  const callbackRef = useRef(callback)

  useEffect(() => {
    callbackRef.current = callback
  }, [callback])

  useEffect(() => {
    let timer: NodeJS.Timeout

    if (delay && callback && typeof callback === 'function') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      timer = setTimeout(callbackRef?.current, delay || 0)
    }

    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [callback, delay])
}

export default useTimeout
