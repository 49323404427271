import { Avatar } from '@components/Avatar'
import Flag from '@components/Flag'
import { NameWithAward } from '@components/NameWithAward/NameWithAward'
import { EnhancedTableHead, HeadCell } from '@components/Table/EnhacedTabHead'
import { Typography } from '@components/Typography'
import { useProfiles } from '@hooks/useProfiles'
import { LadderModel } from '@models/ladder'
import { CompetitionType } from '@models/match'
import { TeamLadderModel } from '@models/team-ladder'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import { alpha } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import Toolbar from '@mui/material/Toolbar'
import Tooltip from '@mui/material/Tooltip'
import { createId } from '@paralleldrive/cuid2'
import ReddalIcon from '@reddal/core/ReddalIcon'
import ReddalSvgIcon from '@reddal/core/ReddalSvgIcon'
import { createChallenge } from '@store/challenges/state'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { isTeamAdministrator } from '@store/teams/state'
import classNames from 'classnames'
import { ChangeEvent, MouseEvent, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { getComparator, Order, stableSort } from 'utils/tableUtils'

interface Data {
  rank: number
  points: number
  logo: string
  tag: string
  name: string
  country: string
  win: number
  loss: number
  draw: number
  streak: number
  recruiting: string
  challenge: null
}

const headCells: HeadCell[] = [
  {
    id: 'rank',
    numeric: false,
    disablePadding: true,
    label: 'Rank',
    align: 'center',
  },
  {
    id: 'points',
    numeric: false,
    disablePadding: true,
    label: 'Rating',
    align: 'center',
  },
  {
    id: 'logo',
    numeric: false,
    disablePadding: true,
    label: '',
    align: 'left',
  },
  {
    id: 'tag',
    numeric: false,
    disablePadding: true,
    label: 'Tag',
    align: 'left',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name',
    align: 'left',
  },
  {
    id: 'country',
    numeric: false,
    disablePadding: true,
    label: '',
    align: 'left',
  },
  {
    id: 'win',
    numeric: true,
    disablePadding: true,
    label: 'Win',
    align: 'center',
  },
  {
    id: 'loss',
    numeric: true,
    disablePadding: true,
    label: 'Loss',
    align: 'center',
  },
  {
    id: 'draw',
    numeric: true,
    disablePadding: true,
    label: 'Draw',
    align: 'center',
  },
  {
    id: 'streak',
    numeric: true,
    disablePadding: true,
    label: 'Streak',
    align: 'center',
  },
  {
    id: 'recruiting',
    numeric: true,
    disablePadding: true,
    label: 'Recruiting',
    align: 'center',
  },
  {
    id: 'challenge',
    numeric: true,
    disablePadding: true,
    label: 'Challenge',
    align: 'center',
  },
]

interface EnhancedTableToolbarProps {
  numSelected: number
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const { numSelected } = props

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography sx={{ flex: '1 1 100%' }} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div">
          Nutrition
        </Typography>
      )}
      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <ReddalSvgIcon>heroicons-solid:trash</ReddalSvgIcon>
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <ReddalSvgIcon>heroicons-solid:view-list</ReddalSvgIcon>
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  )
}

interface TableProps {
  ladder: LadderModel
}

export const RankingTable = ({ ladder }: TableProps) => {
  const dispatch = useAppDispatch()
  const [order, setOrder] = useState<Order>('asc')
  const [orderBy, setOrderBy] = useState<keyof Data>('rank')
  const [selected, setSelected] = useState<readonly string[]>([])
  const [page, setPage] = useState(0)
  const [dense, setDense] = useState(true)
  const [rowsPerPage, setRowsPerPage] = useState(30)
  const { activeProfile } = useProfiles()

  const isTeamAdmin = useAppSelector((state) => isTeamAdministrator(state))

  const profileTeamLadder = useMemo(() => {
    return ladder.teams.find((team) => team.team.refId === activeProfile?.team.refId)
  }, [activeProfile?.team.refId, ladder?.teams?.length])

  const handleRequestSort = (_event: MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = ladder.teams.map((n) => n._id)
      setSelected(newSelected)
      return
    }
    setSelected([])
  }

  const handleClick = (_event: MouseEvent<unknown>, _name: string) => {
    // const selectedIndex = selected.indexOf(name)
    // let newSelected: readonly string[] = []
    //
    // if (selectedIndex === -1) {
    //   newSelected = newSelected.concat(selected, name)
    // } else if (selectedIndex === 0) {
    //   newSelected = newSelected.concat(selected.slice(1))
    // } else if (selectedIndex === selected.length - 1) {
    //   newSelected = newSelected.concat(selected.slice(0, -1))
    // } else if (selectedIndex > 0) {
    //   newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
    // }
    //
    // setSelected(newSelected)
  }

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleChangeDense = (event: ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked)
  }

  const isSelected = (name: string) => selected.indexOf(name) !== -1

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - ladder.teams.length) : 0

  const doChallenge = (record: FixMe) => {
    if (!profileTeamLadder) return
    const challenge = {
      challenger: profileTeamLadder._id,
      challengerTeam: profileTeamLadder.team.refId, // Team RefId
      competition: ladder._id,
      competitionType: CompetitionType.Ladder,
      date: Date.now(),
      opponent: record._id,
      opponentTeam: record.team.refId,
      refId: createId(),
      drawDisabled: ladder.drawDisabled,
      count: 1,
      status: 'NEW',
      waitingTeam: record.team.refId,
      ladder: ladder,
    }
    dispatch(createChallenge(challenge))
  }
  const renderChallengeButton = (row: TeamLadderModel) => {
    console.log(activeProfile?.team.refId)
    if (!isTeamAdmin) return null
    if (!profileTeamLadder) return null
    if (row.team.refId === activeProfile?.team.refId) return null

    return (
      <IconButton aria-label="challenge" color="primary" size="small" onClick={() => doChallenge(row)}>
        <ReddalIcon name="challenge" size={16} />
      </IconButton>
    )
  }

  const renderStreak = (text: number) => {
    if (Math.abs(text) <= 1) return null
    let color = 'text-gray-6'
    let winOrLoss = ''
    // if (Math.sign(text) === 1) {
    if (text > 1) {
      color = 'common.success'
      winOrLoss = `W`
    }
    // if (Math.sign(text) === -1) {
    if (text < -1) {
      color = 'common.error'
      winOrLoss = `L`
    }
    return <Typography color={color}>{Math.abs(text) + winOrLoss}</Typography>
  }
  return (
    <Box>
      {/*<EnhancedTableToolbar numSelected={selected.length} />*/}
      <TableContainer>
        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={dense ? 'small' : 'medium'}>
          <EnhancedTableHead
            headCells={headCells}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={ladder.teams.length}
          />
          <TableBody>
            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
              rows.slice().sort(getComparator(order, orderBy)) */}
            {stableSort<TeamLadderModel>(ladder.teams, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = isSelected(row._id)
                const labelId = `enhanced-table-checkbox-${index}`

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row._id)}
                    role="row"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row._id}
                    selected={isItemSelected}
                  >
                    {/*<TableCell padding="checkbox">*/}
                    {/*  <Checkbox*/}
                    {/*    color="primary"*/}
                    {/*    checked={isItemSelected}*/}
                    {/*    inputProps={{*/}
                    {/*      'aria-labelledby': labelId,*/}
                    {/*    }}*/}
                    {/*  />*/}
                    {/*</TableCell>*/}
                    <TableCell align="center" id={labelId} sx={{ p: 0 }}>
                      {row.rank}
                    </TableCell>
                    <TableCell align="center" sx={{ p: 0 }}>
                      {row.points}
                    </TableCell>
                    <TableCell align="center" sx={{ p: 0 }}>
                      <Link key={row._id} to={`/team/${row.team.refId}`}>
                        <Avatar src={row.team.logo} variant="rounded" size="xs" />
                      </Link>
                    </TableCell>
                    <TableCell align="left" sx={{ p: 0 }}>
                      <Link key={row._id} to={`/team/${row.team.refId}`}>
                        {row.team.tag}
                      </Link>
                    </TableCell>
                    <TableCell align="left" sx={{ p: 0 }}>
                      <NameWithAward
                        name={row.team.name}
                        link={`/team/${row.team.refId}`}
                        currentAward={row.team.currentAward}
                        awards={row.team.awards}
                      />
                    </TableCell>
                    <TableCell align="right" sx={{ p: 0 }}>
                      {<Flag country={row.team.country} />}
                    </TableCell>
                    <TableCell align="center" sx={{ p: 0 }}>
                      <Typography color="common.success">{row.win}</Typography>
                    </TableCell>
                    <TableCell align="center" sx={{ p: 0 }}>
                      <Typography color="common.error">{row.loss}</Typography>
                    </TableCell>
                    <TableCell align="center" sx={{ p: 0 }}>
                      <Typography color="common.warning">{row.draw}</Typography>
                    </TableCell>
                    <TableCell align="center" sx={{ p: 0 }}>
                      {renderStreak(Number(row.streak))}
                    </TableCell>
                    <TableCell align="center" sx={{ p: 0 }}>
                      {row.team.recruiting}
                    </TableCell>
                    <TableCell align="center" sx={{ p: 0 }}>
                      {renderChallengeButton(row)}
                    </TableCell>
                  </TableRow>
                )
              })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: (dense ? 33 : 53) * emptyRows,
                }}
              >
                <TableCell colSpan={12} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        slotProps={{
          select: {
            inputProps: {
              sx: {
                display: 'flex',
                alignItems: 'center',
              },
            },
          },
        }}
        rowsPerPageOptions={[30, 45, 60]}
        component="div"
        count={ladder.teams.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {/*<FormControlLabel control={<Switch checked={dense} onChange={handleChangeDense} />} label="Dense padding" />*/}
    </Box>
  )
}
