import { TournamentModel } from '@models/tournament'
import { PayloadAction } from '@reduxjs/toolkit'
import { queryClient } from '@services/api'
import {
  createTournament,
  deleteTournament,
  getAllTournaments,
  getTournament,
  updateTournament,
} from '@services/api/administration/tournaments'
import { adminTournamentsActions, UpdateTournamentPayload } from '@store/administration/tournament/tournamentState'
import { call, put, takeLatest } from 'redux-saga/effects'

function* GET_ALL_TOURNAMENTS() {
  try {
    const tournaments: TournamentModel[] = yield call(getAllTournaments)
    yield put(adminTournamentsActions.fetchTournamentsSuccess(tournaments))
  } catch (e) {
    yield put(adminTournamentsActions.fetchTournamentsFailed(new Error('Failed to fetch tournaments')))
  }
}

function* GET_TOURNAMENT(action: PayloadAction<string>) {
  try {
    const tournamentId = action.payload
    const tournament: TournamentModel = yield call(getTournament, tournamentId)
    yield put(adminTournamentsActions.fetchTournamentSuccess(tournament))
  } catch (e) {
    yield put(adminTournamentsActions.fetchTournamentFailed(new Error('Failed to fetch tournament')))
  }
}

function* UPDATE_TOURNAMENT(action: PayloadAction<UpdateTournamentPayload>) {
  try {
    const tournament = action.payload
    const updatedTournament: TournamentModel = yield call(updateTournament, tournament)
    yield put(adminTournamentsActions.updateTournamentSuccess(updatedTournament))
  } catch (e) {
    yield put(adminTournamentsActions.updateTournamentFailed(new Error('Failed to update tournament')))
  }
}

function* CREATE_TOURNAMENT(action: ReturnType<typeof adminTournamentsActions.createTournament>) {
  try {
    const tournament = action.payload
    const newTournament: TournamentModel = yield call(createTournament, tournament)
    yield call(() => queryClient.invalidateQueries({ queryKey: ['tournaments'] }))
    yield put(adminTournamentsActions.createTournamentSuccess(newTournament))
  } catch (e) {
    yield put(adminTournamentsActions.createTournamentFailed('Failed to create tournament'))
  }
}

function* DELETE_TOURNAMENT(action: ReturnType<typeof adminTournamentsActions.deleteTournament>) {
  try {
    const tournament = action.payload
    yield call(deleteTournament, tournament)
    yield call(() => queryClient.invalidateQueries({ queryKey: ['tournaments'] }))
    yield put(adminTournamentsActions.deleteTournamentSuccess(tournament))
  } catch (e) {
    yield put(adminTournamentsActions.deleteTournamentFailed(`Failed to delete tournament ${JSON.stringify(e)}`))
  }
}

export default function* tournamentSaga() {
  yield takeLatest(adminTournamentsActions.fetchTournaments.type, GET_ALL_TOURNAMENTS)
  yield takeLatest(adminTournamentsActions.fetchTournament.type, GET_TOURNAMENT)
  yield takeLatest(adminTournamentsActions.updateTournament.type, UPDATE_TOURNAMENT)
  yield takeLatest(adminTournamentsActions.createTournament.type, CREATE_TOURNAMENT)
  yield takeLatest(adminTournamentsActions.deleteTournament.type, DELETE_TOURNAMENT)
}
