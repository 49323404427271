// eslint-disable-next-line max-classes-per-file,@typescript-eslint/ban-ts-comment
// @ts-nocheck
/* tslint:disable */
import _ from '@lodash'
import IconButton from '@mui/material/IconButton'
import ReddalSvgIcon from '@reddal/core/ReddalSvgIcon'
import useThemeMediaQuery from '@reddal/hooks/useThemeMediaQuery'
import { navbarToggle, navbarToggleMobile } from '@store/reddal/navbarSlice'
import { selectReddalCurrentSettings, setDefaultSettings } from '@store/reddal/settingsSlice'
import { useDispatch, useSelector } from 'react-redux'

function NavbarToggleButton(props) {
  const dispatch = useDispatch()
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'))
  const settings = useSelector(selectReddalCurrentSettings)
  const { config } = settings.layout

  return (
    <IconButton
      className={props.className}
      color="inherit"
      size="small"
      onClick={() => {
        if (isMobile) {
          dispatch(navbarToggleMobile())
        } else if (config.navbar.style === 'style-2') {
          dispatch(setDefaultSettings(_.set({}, 'layout.config.navbar.folded', !settings.layout.config.navbar.folded)))
        } else {
          dispatch(navbarToggle())
        }
      }}
    >
      {props.children}
    </IconButton>
  )
}

NavbarToggleButton.defaultProps = {
  children: (
    <ReddalSvgIcon size={20} color="action">
      heroicons-outline:view-list
    </ReddalSvgIcon>
  ),
}

export default NavbarToggleButton
