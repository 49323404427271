// eslint-disable-next-line max-classes-per-file,@typescript-eslint/ban-ts-comment
// @ts-nocheck
/* tslint:disable */
import { styled } from '@mui/material/styles'
import ReddalScrollbars from '@reddal/core/ReddalScrollbars'
import clsx from 'clsx'
import { memo } from 'react'

import Logo from '../../shared-components/Logo'
import NavbarToggleButton from '../../shared-components/NavbarToggleButton'
import Navigation from '../../shared-components/Navigation'
import UserNavbarHeader from '../../shared-components/UserNavbarHeader'

const Root = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.primary,

  '& ::-webkit-scrollbar-thumb': {
    boxShadow: `inset 0 0 0 20px ${
      theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.24)' : 'rgba(255, 255, 255, 0.24)'
    }`,
  },
  '& ::-webkit-scrollbar-thumb:active': {
    boxShadow: `inset 0 0 0 20px ${
      theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.37)' : 'rgba(255, 255, 255, 0.37)'
    }`,
  },
}))

const StyledContent = styled(ReddalScrollbars)(({ theme }) => ({
  overscrollBehavior: 'contain',
  overflowX: 'hidden',
  overflowY: 'auto',
  WebkitOverflowScrolling: 'touch',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100% 40px, 100% 10px',
  backgroundAttachment: 'local, scroll',
}))

function NavbarMobileLayout3(props) {
  return (
    <Root className={clsx('flex h-full flex-col overflow-hidden', props.className)}>
      <div className="flex h-48 shrink-0 flex-row items-center px-20 md:h-72">
        <div className="mx-4 flex flex-1">
          <Logo />
        </div>

        <NavbarToggleButton className="h-40 w-40 p-0" />
      </div>

      <StyledContent
        className="flex min-h-0 flex-1 flex-col"
        option={{ suppressScrollX: true, wheelPropagation: false }}
      >
        <UserNavbarHeader />

        <Navigation layout="vertical" />

        <div className="flex-0 flex items-center justify-center py-48 opacity-10">
          <img className="w-full max-w-64" src="/assets/images/logo/logo.svg" alt="footer logo" />
        </div>
      </StyledContent>
    </Root>
  )
}

export default memo(NavbarMobileLayout3)
