import { UserGuidModel } from '@models/guid'
import { ProfileModel } from '@models/profile'
import { Invite } from '@models/team-invite'
import { UserModel } from '@models/user'
import { apiClient, queryClient } from '@services/api'
import { getAuth } from 'firebase/auth'

/**
 * Asynchronously log in a user.
 *
 * @returns - A promise that resolves to the logged-in user object, or rejects with an error.
 */
export async function login(): Promise<UserModel> {
  console.log('0 - DOING LOGIN:::::::::')
  // Make a POST request to the `/login` endpoint.
  return (
    apiClient
      .post('/login', {})
      // If the request is successful, resolve the promise with the response data.
      .then((response) => {
        if (response) {
          return response.data
        }
        // If there is no response, reject the promise with an error.
        throw new Error('No response from Login')
      })
      // If the request fails, reject the promise with the error.
      .catch((err) => {
        throw err
      })
  )
}

/**
 * Asynchronously register a new user.
 *
 * @param email - The email address of the new user.
 * @param username - The username of the new user.
 * @returns - A promise that resolves to the newly-created user object, or rejects with an error.
 */
export async function register(email: string, username: string) {
  // Make a POST request to the `/createUser` endpoint with the provided email and username.
  return (
    apiClient
      .post('/createUser', {
        email,
        username,
      })
      // If the request is successful, resolve the promise with the response data.
      .then((response) => {
        if (response) {
          return response.data
        }
        // If there is no response, reject the promise with an error.
        throw new Error('No response from Registration')
      })
      // If the request fails, reject the promise with the error.
      .catch((err) => {
        throw err
      })
  )
}

/**
 * Asynchronously update a user's information.
 *
 * @param user - The user object containing the updated information. This object should not have
 *   the `activeProfile` property. Instead, it should have an `activeProfile` property containing
 *   the ID of the user's active profile.
 * @returns - A promise that resolves to the updated user object, or rejects with an error.
 */
export async function updateUser(
  // user: Omit<Partial<UserModel>, 'activeProfile'> & {
  //   activeProfile: string
  // },
  user: Partial<UserModel>,
) {
  // Make a PUT request to the `/user` endpoint with the provided user object.
  return (
    apiClient
      .put('/users', user)
      // If the request is successful, resolve the promise with the response data.
      .then((response) => {
        if (response) {
          return response.data
        }
        // If there is no response, reject the promise with an error.
        throw new Error('No response from Update User')
      })
      // If the request fails, reject the promise with the error.
      .catch((err) => {
        throw err
      })
  )
}

export async function getUser(refId: string): Promise<UserModel> {
  return apiClient
    .get(`/admin/user/${refId}`)
    .then((response) => {
      if (response) {
        return response.data
      }
      throw new Error('Error while fetching users')
    })
    .catch((err) => {
      throw err
    })
}

export async function addUserGuid(guid: Partial<UserGuidModel>) {
  return apiClient
    .post(`/userGuid`, guid)
    .then((response) => {
      if (response) {
        return response.data
      }
      throw new Error('No userGuid response data')
    })
    .catch((err) => {
      throw err
    })
}

export async function currentAccount() {
  return new Promise((resolve, reject) => {
    getAuth().onAuthStateChanged(
      (user) => {
        if (!user) {
          // socket.io.opts.query = { token: '' }
          console.log('::::::::::::::::::::: FIREBASE REMOVE TOKEN :::::::::::::::: ')
          localStorage.removeItem('firebaseToken')
          queryClient.removeQueries({ predicate: (query) => query.queryKey.includes('user') })
          reject(new Error('No user token'))
        } else {
          user.getIdToken().then((token) => {
            console.log('::::::::::::::::::::: FIREBASE GOT TOKEN :::::::::::::::: ')
            localStorage.setItem('firebaseToken', token)
            resolve(token)
          })
          queryClient.invalidateQueries({
            queryKey: ['user'],
          })
        }
        // resolve(true)
      },
      (err) => {
        reject(err)
      },
    )
  })
}

export async function getInvitesSent(): Promise<Invite[]> {
  return apiClient
    .get('/invites/sent')
    .then((response) => {
      if (response) {
        return response.data
      }
      throw new Error('No response from invites sent')
    })
    .catch((err) => {
      throw err
    })
}

export async function getInvitesReceived(): Promise<Invite[]> {
  return apiClient
    .get('/invites/received')
    .then((response) => {
      if (response) {
        return response.data
      }
      throw new Error('No response from invites received')
    })
    .catch((err) => {
      throw err
    })
}

export async function setActiveProfile(activeProfile: ProfileModel) {
  return apiClient
    .put('/activeProfile', activeProfile)
    .then((response) => {
      if (response) {
        return response.data
      }
      throw new Error('No response from Active Profile')
    })
    .catch((err) => {
      throw err
    })
}

//Status has to be "ACCEPT" or "DECLINE"
export async function handleInvite(invite: { refId: string; status: string }) {
  return apiClient
    .put('/invite', invite)
    .then((response) => {
      if (response) {
        return response.data
      }
      throw new Error('No response from accept invite')
    })
    .catch((err) => {
      throw err
    })
}
