// eslint-disable-next-line max-classes-per-file,@typescript-eslint/ban-ts-comment
// @ts-nocheck
/* tslint:disable */
import Grow from '@mui/material/Grow'
import IconButton from '@mui/material/IconButton'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Paper from '@mui/material/Paper'
import { styled, useTheme } from '@mui/material/styles'
import NavLinkAdapter from '@reddal/core/NavLinkAdapter'
import withRouter from '@reddal/core/withRouter'
import { useDebounce } from '@reddal/hooks'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { memo, useMemo, useState } from 'react'
import * as ReactDOM from 'react-dom'
import { Manager, Popper, Reference } from 'react-popper'

import ReddalSvgIcon from '../../../ReddalSvgIcon'
import ReddalNavBadge from '../../ReddalNavBadge'
import ReddalNavItem from '../../ReddalNavItem'

const StyledListItem = styled(ListItem)(({ theme }) => ({
  color: theme.palette.text.primary,
  minHeight: 48,
  '&.active, &.active:hover, &.active:focus': {
    backgroundColor: `${theme.palette.secondary.main}!important`,
    color: `${theme.palette.secondary.contrastText}!important`,

    '&.open': {
      backgroundColor: 'rgba(0,0,0,.08)',
    },

    '& > .reddal-list-item-text': {
      padding: '0 0 0 16px',
    },

    '& .reddal-list-item-icon': {
      color: 'inherit',
    },
  },
}))

function isUrlInChildren(parent, url) {
  if (!parent.children) {
    return false
  }

  for (let i = 0; i < parent.children.length; i += 1) {
    if (parent.children[i].children) {
      if (isUrlInChildren(parent.children[i], url)) {
        return true
      }
    }

    if (parent.children[i].url === url || url.includes(parent.children[i].url)) {
      return true
    }
  }

  return false
}

function ReddalNavHorizontalCollapse(props) {
  const [opened, setOpened] = useState(false)
  const { item, nestedLevel, dense } = props
  const theme = useTheme()

  const handleToggle = useDebounce((open) => {
    setOpened(open)
  }, 150)

  return useMemo(
    () => (
      <ul className="relative px-0">
        <Manager>
          <Reference>
            {({ ref }) => (
              <div ref={ref}>
                <StyledListItem
                  button
                  className={clsx(
                    'reddal-list-item',
                    opened && 'open',
                    isUrlInChildren(item, props.location.pathname) && 'active',
                  )}
                  onMouseEnter={() => handleToggle(true)}
                  onMouseLeave={() => handleToggle(false)}
                  aria-owns={opened ? 'menu-reddal-list-grow' : null}
                  aria-haspopup="true"
                  component={item.url ? NavLinkAdapter : 'li'}
                  to={item.url}
                  end={item.end}
                  role="button"
                  sx={item.sx}
                  disabled={item.disabled}
                >
                  {item.icon && (
                    <ReddalSvgIcon color="action" className={clsx('reddal-list-item-icon shrink-0', item.iconClass)}>
                      {item.icon}
                    </ReddalSvgIcon>
                  )}

                  <ListItemText
                    className="reddal-list-item-text"
                    primary={item.title}
                    classes={{ primary: 'text-13 truncate' }}
                  />

                  {item.badge && <ReddalNavBadge className="mx-4" badge={item.badge} />}
                  <IconButton disableRipple className="h-16 w-16 p-0 ltr:ml-4 rtl:mr-4" color="inherit" size="large">
                    <ReddalSvgIcon size={16} className="arrow-icon">
                      {theme.direction === 'ltr'
                        ? 'heroicons-outline:arrow-sm-right'
                        : 'heroicons-outline:arrow-sm-left'}
                    </ReddalSvgIcon>
                  </IconButton>
                </StyledListItem>
              </div>
            )}
          </Reference>
          {ReactDOM.createPortal(
            <Popper placement={theme.direction === 'ltr' ? 'right' : 'left'} eventsEnabled={opened} positionFixed>
              {({ ref, style, placement, arrowProps }) =>
                opened && (
                  <div
                    ref={ref}
                    style={{
                      ...style,
                      zIndex: 999 + nestedLevel + 1,
                    }}
                    data-placement={placement}
                    className={clsx('z-999', !opened && 'pointer-events-none')}
                  >
                    <Grow in={opened} id="menu-reddal-list-grow" style={{ transformOrigin: '0 0 0' }}>
                      <Paper
                        className="rounded-8"
                        onMouseEnter={() => handleToggle(true)}
                        onMouseLeave={() => handleToggle(false)}
                      >
                        {item.children && (
                          <ul className={clsx('popper-navigation-list', dense && 'dense', 'px-0')}>
                            {item.children.map((_item) => (
                              <ReddalNavItem
                                key={_item.id}
                                type={`horizontal-${_item.type}`}
                                item={_item}
                                nestedLevel={nestedLevel + 1}
                                dense={dense}
                              />
                            ))}
                          </ul>
                        )}
                      </Paper>
                    </Grow>
                  </div>
                )
              }
            </Popper>,
            document.querySelector('#root'),
          )}
        </Manager>
      </ul>
    ),
    [dense, handleToggle, item, nestedLevel, opened, props.location.pathname, theme.direction],
  )
}

ReddalNavHorizontalCollapse.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    icon: PropTypes.string,
    children: PropTypes.array,
  }),
}

ReddalNavHorizontalCollapse.defaultProps = {}

const NavHorizontalCollapse = withRouter(memo(ReddalNavHorizontalCollapse))

export default NavHorizontalCollapse
