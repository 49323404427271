// eslint-disable-next-line max-classes-per-file,@typescript-eslint/ban-ts-comment
// @ts-nocheck
/* tslint:disable */
import _ from '@lodash'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import IconButton from '@mui/material/IconButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import { styled } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import withRouter from '@reddal/core/withRouter'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import clsx from 'clsx'
import { memo, useEffect, useReducer, useRef } from 'react'
import Autosuggest from 'react-autosuggest'

import ReddalSvgIcon from '../ReddalSvgIcon'

const Root = styled('div')(({ theme }) => ({
  '& .ReddalSearch-container': {
    position: 'relative',
  },

  '& .ReddalSearch-suggestionsContainerOpen': {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },

  '& .ReddalSearch-suggestion': {
    display: 'block',
  },

  '& .ReddalSearch-suggestionsList': {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },

  '& .ReddalSearch-input': {
    transition: theme.transitions.create(['background-color'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.short,
    }),
    '&:focus': {
      backgroundColor: theme.palette.background.paper,
    },
  },
}))

function renderInputComponent(inputProps) {
  const { variant, inputRef = () => {}, ref, ...other } = inputProps
  return (
    <div className="relative w-full">
      {variant === 'basic' ? (
        // Outlined
        <>
          <TextField
            fullWidth
            InputProps={{
              inputRef: (node) => {
                ref(node)
                inputRef(node)
              },
              classes: {
                input: 'ReddalSearch-input py-0 px-16 h-40 md:h-48 ltr:pr-48 rtl:pl-48',
                notchedOutline: 'rounded-8',
              },
            }}
            variant="outlined"
            {...other}
          />
          <ReddalSvgIcon
            className="pointer-events-none absolute top-0 h-40 w-48 p-12 ltr:right-0 rtl:left-0 md:h-48"
            color="action"
          >
            heroicons-outline:search
          </ReddalSvgIcon>
        </>
      ) : (
        // Standard
        <TextField
          fullWidth
          InputProps={{
            disableUnderline: true,
            inputRef: (node) => {
              ref(node)
              inputRef(node)
            },
            classes: {
              input: 'ReddalSearch-input py-0 px-16 h-48 md:h-64',
            },
          }}
          variant="standard"
          {...other}
        />
      )}
    </div>
  )
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  const matches = match(suggestion.title, query)
  const parts = parse(suggestion.title, matches)

  return (
    <MenuItem selected={isHighlighted} component="div">
      <ListItemIcon className="min-w-40">
        {suggestion.icon ? (
          <ReddalSvgIcon>{suggestion.icon}</ReddalSvgIcon>
        ) : (
          <span className="w-24 text-center text-20 font-semibold uppercase">{suggestion.title[0]}</span>
        )}
      </ListItemIcon>
      <ListItemText
        primary={parts.map((part, index) =>
          part.highlight ? (
            <span key={String(index)} style={{ fontWeight: 600 }}>
              {part.text}
            </span>
          ) : (
            <strong key={String(index)} style={{ fontWeight: 300 }}>
              {part.text}
            </strong>
          ),
        )}
      />
    </MenuItem>
  )
}

function getSuggestions(value, data) {
  const inputValue = _.deburr(value.trim()).toLowerCase()
  const inputLength = inputValue.length
  let count = 0

  return inputLength === 0
    ? []
    : data.filter((suggestion) => {
        const keep = count < 10 && match(suggestion.title, inputValue).length > 0

        if (keep) {
          count += 1
        }

        return keep
      })
}

function getSuggestionValue(suggestion) {
  return suggestion.title
}

const initialState = {
  searchText: '',
  search: false,
  navigation: null,
  suggestions: [],
  noSuggestions: false,
}

function reducer(state, action) {
  switch (action.type) {
    case 'open': {
      return {
        ...state,
        opened: true,
      }
    }
    case 'close': {
      return {
        ...state,
        opened: false,
        searchText: '',
      }
    }
    case 'setSearchText': {
      return {
        ...state,
        searchText: action.value,
      }
    }
    case 'setNavigation': {
      return {
        ...state,
        navigation: action.value,
      }
    }
    case 'updateSuggestions': {
      const suggestions = getSuggestions(action.value, state.navigation)
      const isInputBlank = action.value.trim() === ''
      const noSuggestions = !isInputBlank && suggestions.length === 0

      return {
        ...state,
        suggestions,
        noSuggestions,
      }
    }
    case 'clearSuggestions': {
      return {
        ...state,
        suggestions: [],
        noSuggestions: false,
      }
    }
    case 'decrement': {
      return { count: state.count - 1 }
    }
    default: {
      throw new Error()
    }
  }
}

function ReddalSearch(props) {
  const { navigation } = props

  const [state, dispatch] = useReducer(reducer, initialState)

  const suggestionsNode = useRef(null)
  const popperNode = useRef(null)
  const buttonNode = useRef(null)

  useEffect(() => {
    dispatch({
      type: 'setNavigation',
      value: navigation,
    })
  }, [navigation])

  function showSearch(ev) {
    ev.stopPropagation()
    dispatch({ type: 'open' })
    document.addEventListener('keydown', escFunction, false)
  }

  function hideSearch() {
    dispatch({ type: 'close' })
    document.removeEventListener('keydown', escFunction, false)
  }

  function escFunction(event) {
    if (event.keyCode === 27) {
      hideSearch()
    }
  }

  function handleSuggestionsFetchRequested({ value }) {
    dispatch({
      type: 'updateSuggestions',
      value,
    })
  }

  function handleSuggestionSelected(event, { suggestion }) {
    event.preventDefault()
    event.stopPropagation()
    if (!suggestion.url) {
      return
    }
    props.navigate(suggestion.url)
    hideSearch()
  }

  function handleSuggestionsClearRequested() {
    dispatch({
      type: 'clearSuggestions',
    })
  }

  function handleChange(event) {
    dispatch({
      type: 'setSearchText',
      value: event.target.value,
    })
  }

  function handleClickAway(event) {
    return state.opened && (!suggestionsNode.current || !suggestionsNode.current.contains(event.target)) && hideSearch()
  }

  const autosuggestProps = {
    renderInputComponent,
    highlightFirstSuggestion: true,
    suggestions: state.suggestions,
    onSuggestionsFetchRequested: handleSuggestionsFetchRequested,
    onSuggestionsClearRequested: handleSuggestionsClearRequested,
    onSuggestionSelected: handleSuggestionSelected,
    getSuggestionValue,
    renderSuggestion,
  }

  switch (props.variant) {
    case 'basic': {
      return (
        <div className={clsx('flex w-full items-center', props.className)} ref={popperNode}>
          <Autosuggest
            {...autosuggestProps}
            inputProps={{
              variant: props.variant,
              placeholder: props.placeholder,
              value: state.searchText,
              onChange: handleChange,
              onFocus: showSearch,
              InputLabelProps: {
                shrink: true,
              },
              autoFocus: false,
            }}
            theme={{
              container: 'flex flex-1 w-full',
              suggestionsList: 'ReddalSearch-suggestionsList',
              suggestion: 'ReddalSearch-suggestion',
            }}
            renderSuggestionsContainer={(options) => (
              <Popper
                anchorEl={popperNode.current}
                open={Boolean(options.children) || state.noSuggestions}
                popperOptions={{ positionFixed: true }}
                className="z-9999"
              >
                <div ref={suggestionsNode}>
                  <Paper
                    className="overflow-hidden rounded-8 shadow-lg"
                    {...options.containerProps}
                    style={{ width: popperNode.current ? popperNode.current.clientWidth : null }}
                  >
                    {options.children}
                    {state.noSuggestions && <Typography className="py-12 px-16">{props.noResults}</Typography>}
                  </Paper>
                </div>
              </Popper>
            )}
          />
        </div>
      )
    }
    case 'full': {
      return (
        <Root className={clsx('flex', props.className)}>
          <Tooltip title="Click to search" placement="bottom">
            <div onClick={showSearch} onKeyDown={showSearch} role="button" tabIndex={0} ref={buttonNode}>
              {props.trigger}
            </div>
          </Tooltip>

          {state.opened && (
            <ClickAwayListener onClickAway={handleClickAway}>
              <Paper className="absolute inset-x-0 top-0 z-9999 h-full shadow-0" square>
                <div className="flex h-full w-full items-center" ref={popperNode}>
                  <Autosuggest
                    {...autosuggestProps}
                    inputProps={{
                      placeholder: props.placeholder,
                      value: state.searchText,
                      onChange: handleChange,
                      InputLabelProps: {
                        shrink: true,
                      },
                      autoFocus: true,
                    }}
                    theme={{
                      container: 'flex flex-1 w-full',
                      suggestionsList: 'ReddalSearch-suggestionsList',
                      suggestion: 'ReddalSearch-suggestion',
                    }}
                    renderSuggestionsContainer={(options) => (
                      <Popper
                        anchorEl={popperNode.current}
                        open={Boolean(options.children) || state.noSuggestions}
                        popperOptions={{ positionFixed: true }}
                        className="z-9999"
                      >
                        <div ref={suggestionsNode}>
                          <Paper
                            className="shadow-lg"
                            square
                            {...options.containerProps}
                            style={{
                              width: popperNode.current ? popperNode.current.clientWidth : null,
                            }}
                          >
                            {options.children}
                            {state.noSuggestions && <Typography className="py-12 px-16">{props.noResults}</Typography>}
                          </Paper>
                        </div>
                      </Popper>
                    )}
                  />
                  <IconButton onClick={hideSearch} className="mx-8" size="large">
                    <ReddalSvgIcon>heroicons-outline:x</ReddalSvgIcon>
                  </IconButton>
                </div>
              </Paper>
            </ClickAwayListener>
          )}
        </Root>
      )
    }
    default: {
      return null
    }
  }
}

ReddalSearch.propTypes = {}
ReddalSearch.defaultProps = {
  navigation: [],
  trigger: (
    <IconButton className="h-40 w-40" size="large">
      <ReddalSvgIcon>heroicons-outline:search</ReddalSvgIcon>
    </IconButton>
  ),
  variant: 'full',
  placeholder: 'Search',
  noResults: 'No results..',
}

export default withRouter(memo(ReddalSearch))
