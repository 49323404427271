import { lazy } from 'react'

import { Buttons } from './components/Buttons'

const DocumentationPageLayout = lazy(() => import('./DocumentationPageLayout'))
const Forms = lazy(() => import('./components/Forms'))

// FALLBACK TO DOCUMENTATION PAGES ON WEBAPP
const DocumentationConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    // {
    //   path: 'documentation',
    //   element: <Navigate to="/documentation/components" />,
    // },
    {
      path: 'documentation',
      // auth: authRoles.admin,
      auth: null,
      element: <DocumentationPageLayout />,
      children: [
        {
          auth: null,
          index: true,
          element: <div>documentation</div>,
        },
        {
          path: 'components',
          children: [
            {
              auth: null,
              index: true,
              element: <div>components</div>,
            },
            {
              path: 'buttons',
              element: <Buttons />,
            },
            {
              path: 'forms',
              element: <Forms />,
            },
          ],
        },
      ],
    },
  ],
}

export default DocumentationConfig
