import { Button, MainCard, SubCard } from '@components'
import { Avatar, AvatarGroup, Grid, Stack } from '@mui/material'

const Avatar1 = 'https://i.pravatar.cc/300'
const Avatar2 = 'https://i.pravatar.cc/240'
const Avatar3 = 'https://i.pravatar.cc/200'
const Avatar4 = 'https://i.pravatar.cc/120'
const Avatar5 = 'https://i.pravatar.cc/60'

export const Buttons = () => {
  return (
    <MainCard title="Buttons">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <SubCard title="Basic Button">
            <Grid container spacing={3} justifyContent="center">
              <Grid item>
                <Button variant="text">Text</Button>
              </Grid>
              <Grid item>
                <Button variant="contained">Contained</Button>
              </Grid>
              <Grid item>
                <Button variant="outlined">Outlined</Button>
              </Grid>
            </Grid>
          </SubCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <SubCard title="Image Avatar">
            <Grid container spacing={3} justifyContent="center">
              <Grid item>
                <Button variant="text">Text</Button>
              </Grid>
              <Grid item>
                <Button variant="contained">Contained</Button>
              </Grid>
              <Grid item>
                <Button variant="outlined">Outlined</Button>
              </Grid>
            </Grid>
          </SubCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <SubCard title="Letter Avatar">
            <Grid container spacing={3} justifyContent="center">
              <Grid item>
                <Avatar color="primary" sx={{ fontSize: '1rem' }}>
                  AG
                </Avatar>
              </Grid>
              <Grid item>
                <Avatar color="secondary">P</Avatar>
              </Grid>
              <Grid item>
                <Avatar color="error">C</Avatar>
              </Grid>
            </Grid>
          </SubCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <SubCard title="Icon Avatar">
            <Grid container spacing={3} justifyContent="center">
              {/*<Grid item>*/}
              {/*  <Avatar>*/}
              {/*    <PageviewIcon />*/}
              {/*  </Avatar>*/}
              {/*</Grid>*/}
              {/*<Grid item>*/}
              {/*  <Avatar color="info">*/}
              {/*    <FolderIcon />*/}
              {/*  </Avatar>*/}
              {/*</Grid>*/}
              {/*<Grid item>*/}
              {/*  <Avatar color="success">*/}
              {/*    <AssignmentIcon />*/}
              {/*  </Avatar>*/}
              {/*</Grid>*/}
            </Grid>
          </SubCard>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <SubCard title="Variant Avatar">
            <Grid container spacing={3} justifyContent="center">
              <Grid item>
                <Avatar color="primary">A</Avatar>
              </Grid>
              {/*<Grid item>*/}
              {/*  <Avatar variant="square" color="warning">*/}
              {/*    <FolderIcon />*/}
              {/*  </Avatar>*/}
              {/*</Grid>*/}
              {/*<Grid item>*/}
              {/*  <Avatar variant="rounded" color="success">*/}
              {/*    <AssignmentIcon />*/}
              {/*  </Avatar>*/}
              {/*</Grid>*/}
            </Grid>
          </SubCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <SubCard title="Outline Avatar">
            <Grid container spacing={3} justifyContent="center">
              <Grid item>
                <Avatar color="primary" outline>
                  A
                </Avatar>
              </Grid>
              {/*<Grid item>*/}
              {/*  <Avatar variant="square" color="warning" outline>*/}
              {/*    <FolderIcon />*/}
              {/*  </Avatar>*/}
              {/*</Grid>*/}
              {/*<Grid item>*/}
              {/*  <Avatar variant="rounded" color="success" outline>*/}
              {/*    <AssignmentIcon />*/}
              {/*  </Avatar>*/}
              {/*</Grid>*/}
            </Grid>
          </SubCard>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <SubCard title="Fallbacks Avatar">
            <Grid container spacing={3} justifyContent="center">
              <Grid item>
                <Avatar alt="Remy Sharp" src="/broken-image.jpg" color="primary">
                  A
                </Avatar>
              </Grid>
              <Grid item>
                <Avatar alt="Remy Sharp" src="/broken-image.jpg" color="error" />
              </Grid>
              <Grid item>
                <Avatar src="/broken-image.jpg" color="warning" />
              </Grid>
            </Grid>
          </SubCard>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <SubCard title="Grouped Avatar">
            <Grid container justifyContent="center">
              <AvatarGroup max={4}>
                <Avatar alt="User 1" src={Avatar1} />
                <Avatar alt="User 2" src={Avatar2} />
                <Avatar alt="User 3" src={Avatar3} />
                <Avatar alt="User 4" src={Avatar4} />
                <Avatar alt="User 5" src={Avatar5} />
              </AvatarGroup>
            </Grid>
          </SubCard>
        </Grid>
        <Grid item xs={12} md={5}>
          <SubCard title="Color Variation Avatar">
            <Grid container spacing={3} justifyContent="center">
              <Grid item>
                <Avatar color="primary">A</Avatar>
              </Grid>
              <Grid item>
                <Avatar color="secondary">C</Avatar>
              </Grid>
              <Grid item>
                <Avatar color="error">P</Avatar>
              </Grid>
              {/*<Grid item>*/}
              {/*  <Avatar color="warning">*/}
              {/*    <FolderIcon />*/}
              {/*  </Avatar>*/}
              {/*</Grid>*/}
              {/*<Grid item>*/}
              {/*  <Avatar color="info">*/}
              {/*    <AssignmentIcon />*/}
              {/*  </Avatar>*/}
              {/*</Grid>*/}
              {/*<Grid item>*/}
              {/*  <Avatar color="success">*/}
              {/*    <PageviewIcon />*/}
              {/*  </Avatar>*/}
              {/*</Grid>*/}
              <Grid item>
                <Avatar>R</Avatar>
              </Grid>
            </Grid>
          </SubCard>
        </Grid>
        <Grid item xs={12} md={5}>
          <SubCard title="Size Avatar">
            <Grid container spacing={3} justifyContent="center" alignItems="center">
              <Grid item>
                <Avatar alt="User 1" src={Avatar1} size="xs" />
              </Grid>
              <Grid item>
                <Avatar alt="User 2" src={Avatar2} size="sm" />
              </Grid>
              <Grid item>
                <Avatar alt="User 3" src={Avatar3} size="md" />
              </Grid>
              <Grid item>
                <Avatar alt="User 3" src={Avatar4} size="lg" />
              </Grid>
              <Grid item>
                <Avatar alt="User 3" src={Avatar5} size="xl" />
              </Grid>
            </Grid>
          </SubCard>
        </Grid>
        <Grid item xs={12} md={2}>
          <SubCard title="Avatar with badge">
            <Grid container spacing={3} justifyContent="center" alignItems="center">
              {/*<Grid item>*/}
              {/*  <Badge*/}
              {/*    overlap="circular"*/}
              {/*    badgeContent={*/}
              {/*      <Avatar color="success" size="badge">*/}
              {/*        <CheckTwoToneIcon />*/}
              {/*      </Avatar>*/}
              {/*    }*/}
              {/*  >*/}
              {/*    <Avatar alt="User 1" src={Profile} size="xl" outline color="success" />*/}
              {/*  </Badge>*/}
              {/*</Grid>*/}
            </Grid>
          </SubCard>
        </Grid>
      </Grid>
    </MainCard>
    // <Stack direction="row" display="flex-wrap" flexWrap="wrap" justifyContent="space-between">
    //   <MainCard title="Basic button">
    //     <Stack direction="row" spacing={2}>
    //       <Button variant="text">Text</Button>
    //       <Button variant="contained">Contained</Button>
    //       <Button variant="outlined">Outlined</Button>
    //     </Stack>
    //   </MainCard>
    //   <MainCard title="Text button">
    //     <Stack direction="row" spacing={2}>
    //       <Button>Primary</Button>
    //       <Button disabled>Disabled</Button>
    //       <Button href="#text-buttons">Link</Button>
    //     </Stack>
    //   </MainCard>
    //   <MainCard title="Contained button">
    //     <Stack direction="row" spacing={2}>
    //       <Button variant="contained">Contained</Button>
    //       <Button variant="contained" disabled>
    //         Disabled
    //       </Button>
    //       <Button variant="contained" href="#contained-buttons">
    //         Link
    //       </Button>
    //     </Stack>
    //   </MainCard>
    //   <MainCard title="Outlined Button">
    //     <Stack direction="row" spacing={2}>
    //       <Button variant="outlined">Primary</Button>
    //       <Button variant="outlined" disabled>
    //         Disabled
    //       </Button>
    //       <Button variant="outlined" href="#outlined-buttons">
    //         Link
    //       </Button>
    //     </Stack>
    //   </MainCard>
    //   <MainCard title="Color">
    //     <Stack direction="row" spacing={2}>
    //       <Button color="secondary">Secondary</Button>
    //       <Button variant="contained" color="success">
    //         Success
    //       </Button>
    //       <Button variant="outlined" color="error">
    //         Error
    //       </Button>
    //     </Stack>
    //   </MainCard>
    // </Stack>
  )
}
