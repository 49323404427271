import ReddalLoading from '@reddal/core/ReddalLoading'
import ReddalUtils from '@reddal/utils'
import settingsConfig from 'configs/settingsConfig'
import { Navigate } from 'react-router-dom'

import AdministrationConfig from '../app/administration/AdministrationConfig'
import authConfigs from '../app/auth/authConfigs'
import ChallengeConfig from '../app/challenge/ChallengeConfig'
import CreateTeamConfig from '../app/create-team/CreateTeamConfig'
import DocumentationConfig from '../app/documentation/DocumentationConfig'
import Error404Page from '../app/error/404/Error404Page'
import Error500Page from '../app/error/500/Error500Page'
import LaddersConfig from '../app/ladders/LaddersConfig'
import MatchConfig from '../app/match/MatchConfig'
import NewsConfig from '../app/news/NewsConfig'
import ProfileConfig from '../app/profile/ProfileConfig'
import TeamConfig from '../app/team/TeamConfig'
import TournamentsConfig from '../app/tournaments/TournamentsConfig'
import UserEditConfig from '../app/user-edit/UserEditConfig'

const routeConfigs = [
  ...authConfigs,
  AdministrationConfig,
  ChallengeConfig,
  CreateTeamConfig,
  DocumentationConfig,
  LaddersConfig,
  MatchConfig,
  NewsConfig,
  ProfileConfig,
  TeamConfig,
  TournamentsConfig,
  UserEditConfig,
]

const routes = [
  ...ReddalUtils.generateRoutesFromConfigs(routeConfigs, settingsConfig.defaultAuth),
  {
    path: '/',
    auth: null,
    element: <Navigate to="news" />,
  },
  {
    path: 'loading',
    element: <ReddalLoading />,
  },
  {
    path: '404',
    element: <Error404Page />,
  },
  {
    path: '500',
    element: <Error500Page />,
  },
  {
    path: '*',
    element: <Navigate to="404" />,
  },
]

export default routes
