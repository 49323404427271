// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* tslint:disable */
import _ from '@lodash'
import { createTheme, getContrastRatio } from '@mui/material/styles'
import {
  defaultSettings,
  defaultThemeOptions,
  extendThemeWithMixins,
  getParsedQuerySettings,
  mustHaveThemeOptions,
} from '@reddal/default-settings'
import { AsyncThunkAction, createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import settingsConfig from 'configs/settingsConfig'
import themeLayoutConfigs from 'layouts/themeLayoutConfigs'

export const changeReddalTheme =
  // eslint-disable-next-line @typescript-eslint/ban-types
  (theme: FixMe) => (dispatch: (arg0: AsyncThunkAction<FixMe, void, {}>) => void, getState: () => { reddal: FixMe }) => {
    const { reddal } = getState()
    const { settings } = reddal

    const newSettings = {
      ...settings.current,
      theme: {
        main: theme,
        navbar: theme,
        toolbar: theme,
        footer: theme,
      },
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(setDefaultSettings(newSettings))
  }

function getInitialSettings() {
  const defaultLayoutStyle =
    settingsConfig.layout && settingsConfig.layout.style ? settingsConfig.layout.style : 'layout1'
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  const layout = {
    style: defaultLayoutStyle,
    config: themeLayoutConfigs[defaultLayoutStyle].defaults,
  }
  return _.merge({}, defaultSettings, { layout }, settingsConfig, getParsedQuerySettings())
}

export function generateSettings(_defaultSettings, _newSettings) {
  const response = _.merge(
    {},
    _defaultSettings,
    { layout: { config: themeLayoutConfigs[_newSettings?.layout?.style]?.defaults } },
    _newSettings,
  )

  return response
}

const initialSettings = getInitialSettings()

const initialState = {
  initial: initialSettings,
  defaults: _.merge({}, initialSettings),
  current: _.merge({}, initialSettings),
}

export const setDefaultSettings = createAsyncThunk(
  'reddal/settings/setDefaultSettings',
  async (val, { dispatch, getState }) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { reddal } = getState()
    const { settings } = reddal
    const defaults = generateSettings(settings.defaults, val)

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // dispatch(updateUserSettings(defaults))

    return {
      ...settings,
      defaults: _.merge({}, defaults),
      current: _.merge({}, defaults),
    }
  },
)

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setSettings: (state, action) => {
      const current = generateSettings(state.defaults, action.payload)

      return {
        ...state,
        current,
      }
    },

    setInitialSettings: () => {
      return _.merge({}, initialState)
    },
    resetSettings: (state) => {
      return {
        ...state,
        defaults: _.merge({}, state.defaults),
        current: _.merge({}, state.defaults),
      }
    },
  },
  extraReducers: {
    [setDefaultSettings.fulfilled]: (state, action) => action.payload,
  },
})

const getDirection = (state) => state.reddal.settings.current.direction
const getMainTheme = (state) => state.reddal.settings.current.theme.main
const getNavbarTheme = (state) => state.reddal.settings.current.theme.navbar
const getToolbarTheme = (state) => state.reddal.settings.current.theme.toolbar
const getFooterTheme = (state) => state.reddal.settings.current.theme.footer

function generateMuiTheme(theme, direction) {
  const data = _.merge({}, defaultThemeOptions, theme, mustHaveThemeOptions)
  const response = createTheme(
    _.merge({}, data, {
      mixins: extendThemeWithMixins(data),
      direction,
    }),
  )
  return response
}

export const selectContrastMainTheme = (bgColor) => {
  function isDark(color) {
    return getContrastRatio(color, '#ffffff') >= 3
  }
  return isDark(bgColor) ? selectMainThemeDark : selectMainThemeLight
}

function changeThemeMode(theme, mode) {
  const modes = {
    dark: {
      palette: {
        mode: 'dark',
        background: {
          paper: '#1E2125',
          default: '#121212',
        },
        text: {
          primary: 'rgb(255,255,255)',
          secondary: 'rgb(229, 231, 235)',
          disabled: 'rgb(156, 163, 175)',
        },
      },
    },
    light: {
      palette: {
        mode: 'light',
        background: {
          paper: '#FFFFFF',
          default: '#F7F7F7',
        },
        text: {
          primary: 'rgb(17, 24, 39)',
          secondary: 'rgb(107, 114, 128)',
          disabled: 'rgb(149, 156, 169)',
        },
      },
    },
  }

  return _.merge({}, theme, modes[mode])
}

export const selectMainTheme = createSelector([getMainTheme, getDirection], (theme, direction) =>
  generateMuiTheme(theme, direction),
)

export const selectMainThemeDark = createSelector([getMainTheme, getDirection], (theme, direction) =>
  generateMuiTheme(changeThemeMode(theme, 'dark'), direction),
)

export const selectMainThemeLight = createSelector([getMainTheme, getDirection], (theme, direction) =>
  generateMuiTheme(changeThemeMode(theme, 'light'), direction),
)

export const selectNavbarTheme = createSelector([getNavbarTheme, getDirection], (theme, direction) =>
  generateMuiTheme(theme, direction),
)

export const selectNavbarThemeDark = createSelector([getNavbarTheme, getDirection], (theme, direction) =>
  generateMuiTheme(changeThemeMode(theme, 'dark'), direction),
)

export const selectNavbarThemeLight = createSelector([getNavbarTheme, getDirection], (theme, direction) =>
  generateMuiTheme(changeThemeMode(theme, 'light'), direction),
)

export const selectToolbarTheme = createSelector([getToolbarTheme, getDirection], (theme, direction) =>
  generateMuiTheme(theme, direction),
)

export const selectToolbarThemeDark = createSelector([getToolbarTheme, getDirection], (theme, direction) =>
  generateMuiTheme(changeThemeMode(theme, 'dark'), direction),
)

export const selectToolbarThemeLight = createSelector([getToolbarTheme, getDirection], (theme, direction) =>
  generateMuiTheme(changeThemeMode(theme, 'light'), direction),
)

export const selectFooterTheme = createSelector([getFooterTheme, getDirection], (theme, direction) =>
  generateMuiTheme(theme, direction),
)

export const selectFooterThemeDark = createSelector([getFooterTheme, getDirection], (theme, direction) =>
  generateMuiTheme(changeThemeMode(theme, 'dark'), direction),
)

export const selectFooterThemeLight = createSelector([getFooterTheme, getDirection], (theme, direction) =>
  generateMuiTheme(changeThemeMode(theme, 'light'), direction),
)

export const selectReddalCurrentSettings = ({ reddal }) => reddal.settings.current

export const selectReddalCurrentLayoutConfig = ({ reddal }) => reddal.settings.current.layout.config

export const selectReddalDefaultSettings = ({ reddal }) => reddal.settings.defaults

export const selectReddalThemesSettings = ({ reddal }) => reddal.settings.themes

export const { resetSettings, setInitialSettings, setSettings } = settingsSlice.actions

export default settingsSlice.reducer
