/**
 * Authorization Roles
 */

// Platform Roles
// TODO this should be a DB table, so OWNER can add more platform roles
// export enum PlatformRole {
//   // PLATFORM OWNER - The highest level of access to the platform - can manage users
//   OWNER = 'owner',
//
//   // PLATFORM ADMIN - The second-highest level of access to the platform - can manage users
//   ADMIN = 'admin',
//
//   // ESPORTS STAFF - Can manage all supervisors
//   HEAD_OF_SUPERVISORS = 'head_of_supervisors',
//
//   // ESPORTS STAFF - Can manage all coaches
//   HEAD_OF_LADDERS = 'head_of_ladders',
//   HEAD_OF_TOURNAMENTS = 'head_of_tournaments',
//   HEAD_OF_FIFA = 'head_of_fifa',
//   HEAD_OF_RTCW = 'head_of_rtcw',
// }

// Esports Roles

const authRoles = {
  admin: ['superadmin', 'admin'],
  staff: ['superadmin', 'admin', 'staff'],
  user: ['superadmin', 'admin', 'staff', 'user'],
  onlyGuest: [],
}

export default authRoles
//   [
//   ({
//     role: 'head_of_game',
//     relatedIds: ['gameId', 'gameId', 'gameId'],
//   },
//   { name: 'ladder_suppervisor', relatedIds: ['ladderId', 'ladderId', 'ladderId'] })
// ]
