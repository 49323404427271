// eslint-disable-next-line max-classes-per-file,@typescript-eslint/ban-ts-comment
// @ts-nocheck
/* tslint:disable */
const components = {}

export function registerComponent(name, Component) {
  components[name] = Component
}

export default function ReddalNavItem(props) {
  const C = components[props.type]
  return C ? <C {...props} /> : null
}
