// eslint-disable-next-line max-classes-per-file,@typescript-eslint/ban-ts-comment
// @ts-nocheck
/* tslint:disable */
import { useUser } from '@hooks/useUser'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import Popover from '@mui/material/Popover'
import Typography from '@mui/material/Typography'
import ReddalSvgIcon from '@reddal/core/ReddalSvgIcon'
import { useState } from 'react'
import { Link, NavLink } from 'react-router-dom'

function UserMenu(props) {
  const { user } = useUser()
  const [userMenu, setUserMenu] = useState(null)

  const userMenuClick = (event) => {
    setUserMenu(event.currentTarget)
  }

  const userMenuClose = () => {
    setUserMenu(null)
  }

  return (
    <>
      <Button className="min-h-40 min-w-40 p-0 md:px-16 md:py-6" onClick={userMenuClick} color="inherit">
        <div className="mx-4 hidden flex-col items-end md:flex">
          <Typography component="span" className="flex font-semibold">
            {user.username}
          </Typography>
          <Typography className="text-11 font-medium capitalize" color="text.secondary">
            {user.roles.toString()}
            {(!user.roles || (Array.isArray(user.roles) && user.roles.length === 0)) && 'Guest'}
          </Typography>
        </div>

        {user.avatar ? (
          <Avatar className="md:mx-4" alt="user photo" src={user.avatar} />
        ) : (
          <Avatar className="md:mx-4">{user.username[0]}</Avatar>
        )}
      </Button>

      <Popover
        open={Boolean(userMenu)}
        anchorEl={userMenu}
        onClose={userMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{
          paper: 'py-8',
        }}
      >
        {!user.roles || user.roles.length === 0 ? (
          <>
            <MenuItem component={Link} to="/sign-in" role="button">
              <ListItemIcon className="min-w-40">
                <ReddalSvgIcon>heroicons-outline:lock-closed</ReddalSvgIcon>
              </ListItemIcon>
              <ListItemText primary="Sign In" />
            </MenuItem>
            <MenuItem component={Link} to="/sign-up" role="button">
              <ListItemIcon className="min-w-40">
                <ReddalSvgIcon>heroicons-outline:user-add </ReddalSvgIcon>
              </ListItemIcon>
              <ListItemText primary="Sign up" />
            </MenuItem>
          </>
        ) : (
          <>
            <MenuItem component={Link} to={`/player/${user.refId}`} onClick={userMenuClose} role="button">
              <ListItemIcon className="min-w-40">
                <ReddalSvgIcon>heroicons-outline:user-plus</ReddalSvgIcon>
              </ListItemIcon>
              <ListItemText primary="My Profile" />
            </MenuItem>
            <MenuItem
              component={Link}
              to={`/player/${user?.refId}/administration`}
              onClick={userMenuClose}
              role="button"
            >
              <ListItemIcon className="min-w-40">
                <ReddalSvgIcon>heroicons-outline:cog</ReddalSvgIcon>
              </ListItemIcon>
              <ListItemText primary="Account settings" />
            </MenuItem>
            <MenuItem component={Link} to={`/create-team`} onClick={userMenuClose} role="button">
              <ListItemIcon className="min-w-40">
                <ReddalSvgIcon>heroicons-outline:user-plus</ReddalSvgIcon>
              </ListItemIcon>
              <ListItemText primary="Create team" />
            </MenuItem>
            <MenuItem component={Link} to="/apps/mailbox" onClick={userMenuClose} role="button">
              <ListItemIcon className="min-w-40">
                <ReddalSvgIcon>heroicons-outline:mail-open</ReddalSvgIcon>
              </ListItemIcon>
              <ListItemText primary="Inbox" />
            </MenuItem>
            <MenuItem
              component={NavLink}
              to="/sign-out"
              onClick={() => {
                userMenuClose()
              }}
            >
              <ListItemIcon className="min-w-40">
                <ReddalSvgIcon>heroicons-outline:logout</ReddalSvgIcon>
              </ListItemIcon>
              <ListItemText primary="Sign out" />
            </MenuItem>
          </>
        )}
      </Popover>
    </>
  )
}

export default UserMenu
