import { LadderModel } from '@models/ladder'
import { MatchModel } from '@models/match'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RawDraftContentState } from 'draft-js'

type LaddersState = {
  isLoading: boolean
  error: Error | null
  ladder: LadderModel | null
  upcomingMatches: MatchModel[]
  latestMatches: MatchModel[]
}
const initialState: LaddersState = {
  isLoading: false,
  error: null,
  ladder: null,
  upcomingMatches: [],
  latestMatches: [],
}

const laddersState = createSlice({
  name: 'ladders',
  initialState,
  reducers: {
    getLadder: (state, _action) => {
      state.isLoading = true
    },
    getLadderSuccess: (state, action) => {
      state.isLoading = false
      state.ladder = action.payload
    },
    getLadderFailed: (state, action) => {
      state.isLoading = false
      state.ladder = null
      state.error = action.payload
    },
    joinLadder: (state, _action: PayloadAction<{ ladderId: string; teamId: string }>) => {
      state.isLoading = true
    },
    joinLadderSuccess: (state) => {
      state.isLoading = false
    },
    joinLadderFailed: (state, action) => {
      state.isLoading = false
      state.error = action.payload
    },
    leaveLadder: (state, _action: PayloadAction<string>) => {
      state.isLoading = true
    },
    updateLadder: (state, _action: PayloadAction<{ ladder: LadderModel; rules: RawDraftContentState }>) => {
      state.isLoading = true
    },
    updateLadderSuccess: (state, action) => {
      state.isLoading = false
      state.ladder = action.payload
    },
    updateLadderFailed: (state, action) => {
      state.isLoading = false
      state.error = action.payload
    },
    leaveLadderSuccess: (state) => {
      state.isLoading = false
    },
    leaveLadderFailed: (state, action) => {
      state.isLoading = false
      state.error = action.payload
    },
    getUpcomingLatestLadderMatches: (state, _action: PayloadAction<string>) => {
      state.isLoading = true
    },
    getUpcomingLatestLadderMatchesSuccess: (state, action) => {
      state.isLoading = false
      state.upcomingMatches = action.payload.upcoming
      state.latestMatches = action.payload.latest
    },
    getUpcomingLatestLadderMatchesFailed: (state, action) => {
      state.isLoading = false
      state.error = action.payload
    },
  },
})

export const {
  getLadder,
  getLadderFailed,
  getLadderSuccess,
  getUpcomingLatestLadderMatches,
  getUpcomingLatestLadderMatchesFailed,
  getUpcomingLatestLadderMatchesSuccess,
  joinLadder,
  joinLadderFailed,
  joinLadderSuccess,
  leaveLadder,
  leaveLadderFailed,
  leaveLadderSuccess,
  updateLadder,
  updateLadderFailed,
  updateLadderSuccess,
} = laddersState.actions

export const selectLadder = ({ ladders }: { ladders: LaddersState }) => ladders.ladder
export const selectUpcomingMatches = ({ ladders }: { ladders: LaddersState }) => ladders.upcomingMatches
export const selectLatestMatches = ({ ladders }: { ladders: LaddersState }) => ladders.latestMatches

export default laddersState.reducer

export const laddersActions = laddersState.actions
