// eslint-disable-next-line max-classes-per-file,@typescript-eslint/ban-ts-comment
// @ts-nocheck
/* tslint:disable */
import {
  getSessionRedirectUrl,
  resetSessionRedirectUrl,
  setSessionRedirectUrl,
} from '@reddal/core/ReddalAuthorization/sessionRedirectUrl'
import withRouter from '@reddal/core/withRouter'
import ReddalUtils from '@reddal/utils'
import { history } from '@store'
import AppContext from 'context/AppContext'
import { Component } from 'react'
import { matchRoutes } from 'react-router-dom'

class ReddalAuthorization extends Component {
  constructor(props, context) {
    super(props)
    const { routes } = context
    this.state = {
      accessGranted: true,
      routes,
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { location, userRole } = props
    const { pathname } = location

    const matchedRoutes = matchRoutes(state.routes, pathname)

    const matched = matchedRoutes ? matchedRoutes[0] : false

    const userHasPermission = ReddalUtils.hasPermission(matched?.route.auth, userRole)

    const ignoredPaths = ['/', '/callback', '/sign-in', '/sign-out', '/logout', '/404']

    if (matched && !userHasPermission && !ignoredPaths.includes(pathname)) {
      setSessionRedirectUrl(pathname)
    }

    return {
      accessGranted: matched ? userHasPermission : true,
    }
  }

  componentDidMount() {
    if (!this.state.accessGranted) {
      this.redirectRoute()
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextState.accessGranted !== this.state.accessGranted
  }

  componentDidUpdate() {
    if (!this.state.accessGranted) {
      this.redirectRoute()
    }
  }

  redirectRoute() {
    const redirectUrl = getSessionRedirectUrl() || this.props.loginRedirectUrl
    console.warn('redirectRoute:::::::::::::::::', redirectUrl)
    const { location, userRole } = this.props
    const { pathname } = location
    /*
        User is guest
        Redirect to Login Page
        */
    const role = userRole.map((r) => r.label)
    if (!role || role.length === 0) {
      // TODO atm we can redirect to login page only if user wants to sign in
      // setTimeout(() => history.push('/sign-in'), 0)
      // loginRedirectUrl = pathname
      console.warn('User is guest:::::::::::::::::')
      setTimeout(() => history.push(redirectUrl), 0)
    } else {
      /*
        User is member
        User must be on unAuthorized page or just logged in
        Redirect to dashboard or loginRedirectUrl
        */
      console.warn('User is MEMBER:::::::::::::::::')
      setTimeout(() => history.push(redirectUrl), 0)
      resetSessionRedirectUrl()
    }
  }

  render() {
    // console.info('Reddal Authorization rendered', this.state.accessGranted);
    return this.state.accessGranted ? this.props.children : null
  }
}

ReddalAuthorization.contextType = AppContext

export default withRouter(ReddalAuthorization)
