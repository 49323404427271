import { useNotifications } from '@hooks/notifications/useNotifications'
import Badge from '@mui/material/Badge'
import IconButton from '@mui/material/IconButton'
import ReddalSvgIcon from '@reddal/core/ReddalSvgIcon'
import withReducer from '@store/withReducer'
import { useDispatch } from 'react-redux'

import reducer from './store'
import { toggleNotificationPanel } from './store/stateSlice'

function NotificationPanelToggleButton({ children }: PropsWithChildren) {
  const { notifications } = useNotifications()

  const dispatch = useDispatch()

  return (
    <IconButton className="h-40 w-40" onClick={(_) => dispatch(toggleNotificationPanel())} size="large">
      <Badge color="secondary" variant="dot" invisible={notifications?.length === 0}>
        {children}
      </Badge>
    </IconButton>
  )
}

NotificationPanelToggleButton.defaultProps = {
  children: <ReddalSvgIcon>heroicons-outline:bell</ReddalSvgIcon>,
}

export default withReducer('notificationPanel', reducer)(NotificationPanelToggleButton)
