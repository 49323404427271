import { getTournament } from '@services/api/administration/tournaments'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'

export const useTournament = () => {
  const { tournamentId } = useParams()
  const { data: tournament, isLoading: loadingTournament } = useQuery({
    queryKey: ['tournament', tournamentId],
    queryFn: () => getTournament(tournamentId!),
    enabled: !!tournamentId,
    // should be 24h
    staleTime: 1000 * 60 * 60,
  })
  // const { data: upcomingMatches, isLoading: loadingUpcomingMatches } = useQuery({
  //   queryKey: ['tournament', tourmamentId, 'upcomingMatches'],
  //   queryFn: () => getTournamentMatches(tourmamentId!),
  //   placeholderData: [],
  //   enabled: !!tourmamentId,
  //   // should be 24h
  //   staleTime: 1000 * 60 * 60,
  // })
  // const { data: latestMatches, isLoading: loadingLatestMatches } = useQuery({
  //   queryKey: ['tournament', tourmamentId, 'latestMatches'],
  //   queryFn: () => getTournamentMatches(tourmamentId!),
  //   placeholderData: [],
  //   enabled: !!tourmamentId,
  //   // should be 24h > 1000 * 60 * 60 * 24,
  //   staleTime: 1000 * 60 * 60,
  // })

  return {
    tournament,
    loadingTournament,
    isSupervisor: true,
    isAdmin: true,
  }
}
