import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import { QueryClient } from '@tanstack/react-query'
import { persistQueryClient } from '@tanstack/react-query-persist-client'
import axios, { AxiosError } from 'axios'
import { getAuth } from 'firebase/auth'

export function sleep(ms = 0): Promise<void> {
  console.log('Kindly remember to remove `sleep`')
  return new Promise((resolve) => setTimeout(resolve, ms))
}

const apiClient = axios.create({
  baseURL: import.meta.env.VITE_APP_API_URL,
})

apiClient.interceptors.response.use(
  async (response) => {
    if (import.meta.env.MODE === 'development') {
      await sleep(0)
    }
    return response
  },
  async (error) => {
    if (error.status === 401 && error.code === 'auth/id-token-expired') {
      // retry the same request with updated authorization token
      error.config.headers.Authorization =
        (await getAuth().currentUser?.getIdToken(true)) || localStorage.getItem('firebaseToken')
      return apiClient.request(error.config)
    }
    return Promise.reject(error)
    // const { response } = error
    // const { data } = response
    // if (data) {
    //   throw new Error(data.message)
    // }
    // throw new Error(error.message)
  },
)

apiClient.interceptors.request.use(
  async (request) => {
    const newFirebaseToken = await getAuth().currentUser?.getIdToken()
    const token = newFirebaseToken || localStorage.getItem('firebaseToken')
    const controller = new AbortController()
    if (request.url?.endsWith('/login') && !token) {
      // Cancel the request if there is no token and the request is a login request
      controller.abort()
      const axiosError = new AxiosError('No token')
      axiosError.status = 403
      return Promise.reject(axiosError)
    }
    if (token) {
      request.headers.Authorization = token
    }
    return {
      ...request,
      signal: controller.signal,
    }
  },
  (error) => {
    return Promise.reject(error)
  },
)
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 60 * 24, // 24 hours
      gcTime: 1000 * 60 * 60 * 24, // 24 hours
    },
  },
})
const persister = createSyncStoragePersister({
  storage: window.localStorage,
})

persistQueryClient({
  queryClient,
  persister,
})

export { apiClient, persister, queryClient }
