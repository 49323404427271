import { SnackbarContent } from 'notistack'
import { forwardRef } from 'react'

import NotificationCard from './NotificationCard'

const NotificationTemplate = forwardRef((props, ref) => {
  const { item } = props

  return (
    <SnackbarContent ref={ref} className="pointer-events-auto relative mx-auto w-full max-w-320 py-4">
      <NotificationCard item={item} onClose={props.onClose} />
    </SnackbarContent>
  )
})

export default NotificationTemplate
